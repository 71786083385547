import React, { useCallback, useEffect, useState } from 'react'
import { AppRoutes, projectsRoot } from 'AppRoutes'
import { saveProjectOnExit as saveProjectOnExitAction } from 'actions/projectData/saveProject'
import CustomDragLayerContainerProvider from 'components/CustomDragLayerContainerProvider'
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import * as Actions from '~/actions'
import { clearActionsHistoryError as clearActionsHistoryErrorAction, historyRedo as historyRedoAction, historyUndo as historyUndoAction } from '~/actions/historyActions'

import { clearActiveProjectError } from '~/actions/projectData/clearActiveProjectError'
import CustomDragLayerProvider from '~/components/CustomDragLayerContext'
import TimelineFooter from '~/components/Timeline/TimelineFooter'
import MessageBox from '~/components/base/MessageBox'
import { newProjectId, MAIN_BLOCK_HEIGHT } from '~/constant'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import { HOTKEYS } from '~/enums'
import { ERROR_TYPE } from '~/errors/ErrorType'
import { useAction, useKeyCodeListener, useWindowUnload } from '~/hooks'
import { selectActionHistoryError } from '~/selectors/historyActions'
import { isRecordingStarted } from '~/selectors/recording'
import { selectActiveProjectError, selectActiveProjectId, selectProjectDataError } from '~/selectors/projectData'
import { MainContainer } from './components/MainContainer'
import { DragTransformUIContainer } from '~/components/DragTransformUIContainer/DragTransformUIContainer'

export const Editor = () => {
  const [ isMounted, setIsMount ] = useState(false)
  const [ redirectToProjects, setRedirectToProjects ] = useState(false)

  const history = useHistory()

  const showBlankProjectInList = useAction(Actions.showBlankProjectInList.showBlankProjectInList)
  const saveProjectOnExit = useAction(saveProjectOnExitAction)
  const saveProject = useAction(Actions.saveProject.saveProject)
  const initNewProject = useAction(Actions.initNewProject.initNewProject)
  const allowSaveEmpty = useAction(Actions.allowSaveEmpty.allowSaveEmpty)
  const loadProject = useAction(Actions.loadProject.loadProject)
  const { id: paramId } = useParams()

  const hideSaveSettings = useAction(Actions.mainView.showSaveSettings, false)
  const hideImportFromUrl = useAction(Actions.mainView.showImportFromUrl, false)
  const hideAmazonImport = useAction(Actions.mainView.showAmazonImport, false)

  const clearActionHistoryError = useAction(clearActionsHistoryErrorAction)
  const clearProjectError = useAction(clearActiveProjectError)

  const activeProjectId = useSelector(state => selectActiveProjectId(state))
  const activeProjectError = useSelector(selectActiveProjectError)
  const projectDataError = useSelector(selectProjectDataError)
  const actionHistoryError = useSelector(selectActionHistoryError)
  const mediaRecordingAssetInProgress = useSelector(isRecordingStarted)

  const saveOnExitCallback = useCallback(() => {
    saveProjectOnExit()
  }, [ saveProjectOnExit ])

  useWindowUnload(saveOnExitCallback)

  useEffect(() => {
    if (isMounted) {
      if (activeProjectId && paramId === newProjectId && activeProjectId !== newProjectId) {
        history.push(`${AppRoutes.Projects}/${activeProjectId}`)
      }
    } else {
      setIsMount(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ activeProjectId ])

  useEffect(() => {
    if (__APP_PROFILE__ === 'package') {
      const latestProjectId = LatestProjectId.get()
      if (latestProjectId && latestProjectId !== newProjectId) {
        loadProject(latestProjectId)
      } else {
        initNewProject()
      }
    } else {
      // eslint-disable-next-line
      if (!window.location.pathname.includes(projectsRoot) || paramId === newProjectId) {
        initNewProject()
        if (!LatestProjectId.get() || LatestProjectId.get() === newProjectId) {
          showBlankProjectInList()
        }
      } else if (window.location.pathname.includes(projectsRoot)
        && paramId !== activeProjectId && paramId !== newProjectId) {
        loadProject(paramId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ paramId, initNewProject, loadProject, allowSaveEmpty ])

  useEffect(() => () => {
    saveProject()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPressEscape = () => {
    hideSaveSettings()
    hideImportFromUrl()
    hideAmazonImport()
  }

  useKeyCodeListener(React.useRef(document), 'Escape', onPressEscape)

  const handleActiveProjectError = error => {
    switch (error.type) {
      case ERROR_TYPE.PROJECT_LOAD_FAILED:
        setRedirectToProjects(true)
        break
      case ERROR_TYPE.PROJECT_IN_USE:
        window.location.reload()
        break
      default:
        clearProjectError()
    }
  }
  if (redirectToProjects) {
    return <Redirect to={AppRoutes.Projects} />
  }

  return (
    <>
      <If condition={__CFG__.EDITOR.UNDO_REDO}>
        <UndoRedoKeysHandlers />
      </If>
      <If condition={actionHistoryError}>
        <MessageBox
          message={actionHistoryError}
          onClose={() => clearActionHistoryError()}
        />
      </If>
      <If condition={activeProjectError}>
        <MessageBox
          message={activeProjectError.message}
          onClose={() => handleActiveProjectError(activeProjectError)}
        />
      </If>
      <If condition={projectDataError}>
        <MessageBox
          message={projectDataError}
          onClose={() => clearProjectError()}
        />
      </If>
      <CustomDragLayerProvider>
        <CustomDragLayerContainerProvider>
          <SplitPane split="horizontal" minSize={MAIN_BLOCK_HEIGHT} maxSize={MAIN_BLOCK_HEIGHT} resizerStyle={{ display: 'none' }}>
            <MainContainer />
            <TimelineFooter saveDisabled={mediaRecordingAssetInProgress} />
          </SplitPane>
          <DragTransformUIContainer />
        </CustomDragLayerContainerProvider>
      </CustomDragLayerProvider>
    </>
  )
}

function UndoRedoKeysHandlers() {
  const historyUndo = useAction(historyUndoAction)
  const historyRedo = useAction(historyRedoAction)
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)

  const onPressUndo = () => historyUndo()
  const onPressRedo = () => historyRedo()

  // const documentRef = useRef(document)

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.UNDO], onPressUndo)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.REDO], onPressRedo)
  // useKeyCodeListener(documentRef, KEY_Z, onPressUndo, { ctrlKey: true, ignoreInputHistory: false })
  // useKeyCodeListener(documentRef, KEY_Y, onPressRedo, { ctrlKey: true, ignoreInputHistory: false })

  return null
}
