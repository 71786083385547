import React, { useEffect, useCallback, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import * as Actions from 'actions'
import { HOTKEYS } from 'enums'
import { useHotkeys } from 'react-hotkeys-hook'
import { activeHotkeyProfileSelector } from 'selectors/user-profile-selector'
import { TranslationContext } from 'contexts/TranslationContext'
import { useHotkeyTooltip } from 'hooks/useHotkeyTooltip'
import { STEP_ZOOM_VALUE } from 'constant'
import './ZoomSlider.scss'

function ZoomSlider(props) {
  const { scale, setScale, minScale, maxScale } = props
  const activeHotkeyProfile = useSelector(activeHotkeyProfileSelector)
  const { t } = useContext(TranslationContext)
  const { getTooltip } = useHotkeyTooltip()
  const interval = useRef()
  const refSlider = useRef(null)

  const handleChange = useCallback((_, newValue) => {
    clearInterval(interval.current)
    interval.current = setTimeout(() => setScale(Math.exp(newValue)))
  }, [ setScale ])

  const onZoomOut = useCallback(() => {
    const newScale = Math.log(scale) - STEP_ZOOM_VALUE
    if (newScale > minScale) setScale(Math.exp(newScale))
  }, [ scale, setScale, minScale ])

  const onZoomIn = useCallback(() => {
    const newScale = Math.log(scale) + STEP_ZOOM_VALUE
    if (newScale < maxScale) setScale(Math.exp(newScale))
  }, [ scale, setScale, maxScale ])

  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ZOOM_IN], onZoomIn)
  useHotkeys(activeHotkeyProfile.hotkeys[HOTKEYS.ZOOM_OUT], onZoomOut)

  useEffect(() => {
    const slider = refSlider.current
    const onWheel = e => e.deltaY > 0 ? onZoomIn() : onZoomOut()
    slider.addEventListener('wheel', onWheel)
    return () => slider.removeEventListener('wheel', onWheel)
  }, [ onZoomIn, onZoomOut, refSlider ])

  return (
    <div className="zoom-slider">
      <div className="zoom-slider__wrap">
        <div
          className="zoom-slider__btn"
          onClick={onZoomOut}
          title={getTooltip(t('ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP'), HOTKEYS.ZOOM_OUT)}
          data-lang-id="ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP"
        >
          <RemoveIcon />
        </div>
        <div className="zoom-slider__inner" ref={refSlider}>
          <Slider
            step={STEP_ZOOM_VALUE}
            min={minScale}
            max={maxScale}
            value={Math.log(scale)}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </div>
        <div
          className="zoom-slider__btn"
          onClick={onZoomIn}
          title={getTooltip(t('ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP'), HOTKEYS.ZOOM_IN)}
          data-lang-id="ZOOM_SLIDER_BTN_INCREASE_ZOOM"
        >
          <AddIcon />
        </div>
      </div>
      <If condition={__CFG__.TIMELINE.ZOOM_MENU}>
        <div className="zoom-slider__control">
          <span className="zoom-slider__dots" />
        </div>
      </If>
    </div>
  )
}

ZoomSlider.propTypes = {
  setScale: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
  minScale: PropTypes.number.isRequired,
  maxScale: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  scale: state.timeline.scale,
  minScale: Math.log(state.timeline.minScale),
  maxScale: Math.log(state.timeline.maxScale),
})

const mapDispatchToProps = dispatch => ({
  setScale: scale => dispatch(Actions.timeline.setScale(scale)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoomSlider)
