import { switchLogEnabled } from 'ServerAPI'
import * as ActionTypes from './ActionTypes'

export const setInstallVersion = payload => ({
  type: ActionTypes.SET_INSTALL_VERSION,
  payload,
})

export const setLogPath = payload => ({
  type: ActionTypes.SET_LOG_PATH,
  payload,
})


export const setEdition = edition => ({
  type: ActionTypes.SET_EDITION,
  payload: { edition },
})

export const setLogsEnabled = () => async (dispatch, getState) => {
  const { logsEnabled } = getState().app
  await switchLogEnabled(!logsEnabled)
}
