import { Editor } from 'components/Editor/Editor'
import WebEditorHeader from 'components/Header'
import { Projects } from 'components/Projects/Projects'
import React, { useEffect, useRef } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import { useDropTargetLocation } from 'hooks/useDnd'
import * as Actions from '~/actions'
import { AppRoutes } from '~/AppRoutes'
import { OfflineModePlug } from '~/components/OfflineModePlug/OfflineModePlug'
import * as LatestProjectId from '~/cookieServices/LatestProjectId'
import { useAction } from '~/hooks'
import { ModalView } from '~/views/ModalView'
import { DROP_TARGET_TYPE } from '~/enums'

const Router = () => {
  const { pathname } = useLocation()
  const latestProjectId = LatestProjectId.get()

  return (
    <Switch>
      {/* Remove trailing slash */}
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route exact path={AppRoutes.Projects}>
        <Projects />
      </Route>
      <Route path={`${AppRoutes.Projects}/:id`}>
        <Editor />
      </Route>
      <If condition={__APP_PROFILE__ === 'package'}>
        <Route path="">
          <Editor />
        </Route>
      </If>
      <If condition={__APP_PROFILE__ !== 'package'}>
        <Redirect to={latestProjectId ? `${AppRoutes.Projects}/${latestProjectId}` : AppRoutes.NewProject} />
      </If>
    </Switch>
  )
}

function MainView() {
  const loadProject = useAction(Actions.loadProject.loadProject)

  const [ , drop ] = useDropTargetLocation({ location: DROP_TARGET_TYPE.ROOT, shallow: true })

  const location = useLocation()

  const latestProjectId = LatestProjectId.get()

  const dropRef = useRef()

  useEffect(() => {
    if (latestProjectId && location.pathname === AppRoutes.Projects) {
      loadProject(latestProjectId)
    }
    if (dropRef?.current?.splitPane) {
      drop(dropRef.current.splitPane)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ loadProject ])

  return (
    <>
      <OfflineModePlug />
      <If condition={__APP_PROFILE__ !== 'package'}>
        <SplitPane split="horizontal" defaultSize="48px" resizerStyle={{ display: 'none' }} ref={dropRef}>
          <WebEditorHeader />
          <Router />
        </SplitPane>
      </If>
      <If condition={__APP_PROFILE__ === 'package'}>
        <Router />
      </If>
      <ModalView />
    </>
  )
}

export default MainView
