import { TIME_CODE_MODE } from 'config/constants/timecode'
import Timecode, { FRAMERATE } from 'smpte-timecode'
import { TimeCodeModeType } from 'types/timecode'
import { secondsToTimelineTime, timeToFractions } from 'Util'

type TimeCodeReturnType = { hours: number, minutes: number, seconds: number, frames: number }

export const frameNumberToSeconds = (frames: number, fps: number) => frames / fps

/*
 * These calculations are necessary because of the loss of precision
 * when multiplying floating point numbers in JavaScript.
 */
export const secondsToFrameNumber = (sec: number, fps: number) => Math.round(sec * fps)

export const isDropFrameTimeCode = (mode: TimeCodeModeType, fps: number) => (
  (mode === TIME_CODE_MODE.FRAME_DF) && ([ 29.97, 59.94 ].includes(Number(fps.toFixed(2))))
)

export const getObjectTimeCode = (progress: number, fps: number, timeMode: TimeCodeModeType) => {
  const isDF = isDropFrameTimeCode(timeMode, fps)
  const isFrameMode = timeMode === TIME_CODE_MODE.FRAME_DF || timeMode === TIME_CODE_MODE.FRAME_NDF
  const frameCount = secondsToFrameNumber(progress, fps)
  let timeCodeData = {} as TimeCodeReturnType
  let timeFractions = {} as Omit<TimeCodeReturnType, 'frames'> & { milliseconds: number }
  try {
    const { hours, minutes, seconds, frames } = new Timecode(frameCount, fps as FRAMERATE, isDF)
    timeFractions = timeToFractions(secondsToTimelineTime(progress))
    timeCodeData = { hours, minutes, seconds, frames }
  } catch {
    timeFractions = timeToFractions(secondsToTimelineTime(progress))
    timeCodeData = { ...timeFractions, frames: secondsToFrameNumber(timeFractions.milliseconds / 1000, fps) }
  }

  const { hours, minutes, seconds, frames } = timeCodeData

  return {
    hours: isFrameMode ? hours : timeFractions.hours,
    minutes: isFrameMode ? minutes : timeFractions.minutes,
    seconds: isFrameMode ? seconds : timeFractions.seconds,
    frames,
    milliseconds: timeFractions.milliseconds,
    frameCount,
    dropFrame: isDF,
  }
}
