import { TIME_CODE_MODE, TIME_CODE_MODE_VALUES } from 'config/constants/timecode'
import { SEPARATOR } from 'enums'
import Timecode, { FRAMERATE } from 'smpte-timecode'
import { TimeCodeModeType } from 'types/timecode'
import { DEFAULT_FPS, n } from 'Util'
import { frameNumberToSeconds, isDropFrameTimeCode } from 'Util/timecode'

const normalizeStrTimeCode = (strTime: string, timeMode: TimeCodeModeType) => {
  if (timeMode === TIME_CODE_MODE.FRAME_DF) {
    const newStrTime = strTime
    return newStrTime.replace(/,/g, (char, index) => index !== newStrTime.lastIndexOf(char)
      ? SEPARATOR.COLON
      : SEPARATOR.SEMICOLON)
  }
  return strTime
}

const extractFrameInString = (strTime: string, isDF: boolean) => {
  const timecodeArr = strTime.split(isDF ? SEPARATOR.SEMICOLON : SEPARATOR.COLON)
  return parseInt(timecodeArr[timecodeArr.length - 1])
}

const getStrTimeWithNextFrame = (strTime: string, isDF: boolean, nextFrame?: number) => {
  const timecodeArr = strTime.split(isDF ? SEPARATOR.SEMICOLON : SEPARATOR.COLON)
  const frameUnit = parseInt(timecodeArr[timecodeArr.length - 1])
  timecodeArr[timecodeArr.length - 1] = n(nextFrame ?? frameUnit + 1)
  return timecodeArr.join(isDF ? SEPARATOR.SEMICOLON : SEPARATOR.COLON)
}

// if frame number is greater than fps num
const checkShiftFrames = (strTime: string, fps: number, timeMode: TimeCodeModeType, isDF: boolean) => {
  const curFrameUnit = extractFrameInString(strTime, isDF)
  let normalizeStrTime = normalizeStrTimeCode(strTime, timeMode)
  let shiftFrames = 0
  let newFrame = curFrameUnit
  if (curFrameUnit >= fps) {
    if (fps !== DEFAULT_FPS) {
      shiftFrames = curFrameUnit - Math.trunc(fps)
    } else if (fps <= DEFAULT_FPS) {
      if (curFrameUnit > fps) {
        shiftFrames = (curFrameUnit - fps) + 1
      } else if (curFrameUnit === fps) {
        shiftFrames += 1
      }
      newFrame -= 1
    }
    normalizeStrTime = getStrTimeWithNextFrame(normalizeStrTime, isDF, newFrame)
  }
  return { shiftFrames, newFrame, normalizeStrTime }
}

export const timeCodeToSeconds = (strTime: string, fps: number): number => {
  let result = 0
  for (const modeValues of TIME_CODE_MODE_VALUES) {
    const currentMode = modeValues.checkRegExp.test(strTime) ? modeValues.mode : null
    switch (currentMode) {
      case TIME_CODE_MODE.FRAME_DF:
      case TIME_CODE_MODE.FRAME_NDF: {
        const isDF = isDropFrameTimeCode(currentMode, fps)
        const { shiftFrames, normalizeStrTime } = checkShiftFrames(strTime, fps, currentMode, isDF)
        try {
          const timecode = new Timecode(normalizeStrTime, fps as FRAMERATE, isDF)
          if (shiftFrames > 0) {
            timecode.add(shiftFrames)
          }
          result = frameNumberToSeconds(timecode.frameCount, fps)
        } catch { // if timecode is not valid
          return timeCodeToSeconds(getStrTimeWithNextFrame(strTime, isDF), fps)
        }
      }
        break
      case TIME_CODE_MODE.MILLISECOND: {
        const HHMMSS = strTime.split(SEPARATOR.COLON)
        const SSMS = HHMMSS[HHMMSS.length - 1].split(SEPARATOR.DOT)
        const hours = Number(HHMMSS[0]) * 60 * 60
        const minutes = Number(HHMMSS[1]) * 60
        const seconds = Number(SSMS[0])
        const milliseconds = Number(SSMS[1]) / 1000
        return hours + minutes + seconds + milliseconds
      }
    }
  }
  return result
}
