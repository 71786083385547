import * as Selectors from '~/selectors'
import * as Assets from '~/models/Asset'
import * as ActionTypes from './ActionTypes'

export const setEdition = edition => ({
  type: ActionTypes.SET_EDITION,
  payload: { edition },
})

export const cancelAssetSettingsForm = fileType => (dispatch, getState) => {
  const group = fileType ? Selectors.getSourceFilesGroup(getState(), fileType) : undefined

  dispatch({
    type: ActionTypes.ASSET_EDIT_CANCELED,
    payload: {
      type: fileType,
      assetBackup: group?.editingAssetBackup,
    },
  })
}

export const openChapter = (menuItem, force = false) => async dispatch => {
  dispatch({
    type: ActionTypes.OPEN_CHAPTER,
    payload: { menuItem, force },
  })
}

export const setLocalFileDragging = isDragging => ({
  type: ActionTypes.LOCAL_FILE_DRAGGING,
  payload: { isDragging },
})

export const setActiveAssetRotation = activeAssetRotation => ({
  type: ActionTypes.ACTIVE_ASSET_ROTATION,
  payload: { activeAssetRotation },
})

export const setPlayerVolume = (playerVolume, persist = true) => ({
  type: ActionTypes.SET_PLAYER_VOLUME,
  payload: { playerVolume, persist },
})

export const openAssetSettingForm = assetId => (dispatch, getState) => {
  const asset = Selectors.getAssets(getState()).find(x => x.id === assetId)

  if (asset === undefined) {
    throw new Error(`Asset #${assetId} does not exist`)
  }

  if (asset instanceof Assets.PlaceholderAsset) {
    return
  }

  dispatch({
    type: ActionTypes.ASSET_EDIT_STARTED,
    payload: { asset },
  })
}

export const openAssetSettingFormViaLayerItem = assetId => (dispatch, getState) => {
  const isSettingsOpened = Selectors
    .getSourceFilesGroup(getState(), getState().mainView.menuItem)
    .editingAssetBackup

  if (isSettingsOpened) { dispatch(openAssetSettingForm(assetId)) }
}

export const submitAssetSettingsForm = fileType => ({
  type: ActionTypes.ASSET_EDIT_SUBMITTED,
  payload: { type: fileType },
})

export const showSaveSettings = showSettings => ({
  type: ActionTypes.SAVE_SETTINGS_VISIBILITY_CHANGED,
  payload: { showSettings },
})

export const showNabletHldSettings = showSettings => ({
  type: ActionTypes.NABLET_HLD_SETTINGS_VISIBILITY_CHANGED,
  payload: { showSettings },
})

export const openMediaRecordingSettings = mediaType => dispatch => {
  dispatch({ type: ActionTypes.MEDIA_RECORDING_INIT, payload: mediaType })
}

export const closeMediaRecordingSettings = () => dispatch => {
  dispatch({ type: ActionTypes.MEDIA_RECORDING_CANCELED })
}

export const showCustomizeView = view => dispatch => {
  if (view) {
    dispatch(cancelAssetSettingsForm())
    dispatch(closeMediaRecordingSettings())
  }
  dispatch({
    type: ActionTypes.CUSTOMIZE_VIEW_VISIBILITY_CHANGED,
    payload: view,
  })
}

export const showEditorMainSettings = isShow => dispatch => {
  dispatch(cancelAssetSettingsForm())
  dispatch(closeMediaRecordingSettings())
  dispatch({
    type: ActionTypes.EDITOR_MAIN_SETTINGS,
    payload: isShow,
  })
}

export const showLogActions = visibility => ({
  type: ActionTypes.LOG_ACTIONS,
  payload: visibility,
})

export const showImportFromUrl = visibility => ({
  type: ActionTypes.IMPORT_FROM_URL_VISIBILITY_CHANGED,
  payload: { showImportFromUrl: visibility },
})

export const setIsOffline = ({ isOffline } = { isOffline: true }) => ({
  type: ActionTypes.SET_IS_OFFLINE,
  payload: { isOffline },
})

export const showAmazonImport = visibility => ({
  type: ActionTypes.AMAZON_IMPORT_VISIBILITY_CHANGED,
  payload: { showAmazonImport: visibility },
})

export const closeFeatures = () => ({ type: ActionTypes.CLOSE_FEATURES })

export const resetMainView = () => ({ type: ActionTypes.RESET_MAIN_VIEW })

export const startOfflineRestoration = () => ({ type: ActionTypes.OFFLINE_RESTORATION_START })
export const endOfflineRestoration = () => ({ type: ActionTypes.OFFLINE_RESTORATION_END })

export const setDndDropTarget = target => ({ type: ActionTypes.SET_DND_DROP_TARGET,
  payload: target })

export const setActiveDragElement = element => ({
  type: ActionTypes.SET_ACTIVE_DRAG_ELEMENT,
  payload: { element },
})

export const setDragElementBounds = bounds => ({
  type: ActionTypes.SET_DRAG_ELEMENT_BOUNDS,
  payload: { bounds },
})

export const setSettingsViewMode = mode => ({
  type: ActionTypes.SET_SETTINGS_VIEW_MODE,
  payload: { mode },
})

export const setEditorSettings = (chapter, params) => ({
  type: ActionTypes.SET_EDITOR_SETTINGS,
  payload: { chapter, params },
})
