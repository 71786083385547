import React from 'react'
import { MovableModalContainer } from 'components/MovableModal'
import { useSelector } from 'react-redux'
import { selectShowLogActions } from 'selectors/mainView'
import { LogActionsComponent } from './LogActionsComponent'

export const LogActionsContainer = () => {
  const visible = useSelector(selectShowLogActions)
  return (
    <MovableModalContainer
      visible={visible}
      restorePosition={false}
      width={493}
      height={307}
      background="black"
      withBackground
    >
      <LogActionsComponent visible={visible} />
    </MovableModalContainer>
  )
}
