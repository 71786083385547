import { frontendVersion } from 'helpers/versionRoutines'
import { useEffect } from 'react'
import { setInstallVersion, setLogPath } from 'actions/app'
import { setOnnxNames } from 'actions/nabletHld'
import { useAction } from '~/hooks'
import { getVersion, checkToken } from '~/ServerAPI'
import versionInfo from './version.json'
import * as AuthToken from '~/cookieServices/AuthToken'
import { NW_JS_APP_PROFILES } from '~/enums'

function formatConsoleVersion(data) {
  const { hash, date: isoDateStr } = data

  return `${frontendVersion()} | ${hash} | ${isoDateStr}`
}

const checkUrlAuthToken = async ({ externalLoginUrl }) => {
  const params = new URLSearchParams(window.location.search)
  let authToken = params.get('auth_token') || params.get('session_id') || params.get('token')
  if (!authToken) {
    authToken = AuthToken.get()
  }
  const atSmartediting = window.location.host === __CFG__.SMARTEDITING_HOST
  if (externalLoginUrl && !atSmartediting && __APP_PROFILE__ !== 'package') {
    // in case of externalLoginUrl redirect to login page if no token specified or token expired
    if (authToken) {
      try {
        await checkToken(authToken)
      } catch (e) {
        authToken = null
      }
    }
    if (!authToken) {
      window.location.href = externalLoginUrl
    }
  }
  if (!authToken && __CFG__.GENERATE_AUTH_TOKEN) {
    authToken = Date.now().toString()
  }
  if (authToken) {
    AuthToken.set(authToken)
    window.userId = authToken
  }
}

async function waitForLocalStorage() {
  return new Promise(resolve => {
    const check = () => {
      const nwRestroreComplete = localStorage.getItem('nwRestroreComplete')
      if (nwRestroreComplete) {
        resolve(true)
      } else {
        setTimeout(check, 100)
      }
    }
    check()
  })
}

export default function AppVersion({ onSuccess }) {
  const setInstallVersionAction = useAction(setInstallVersion)
  const setOnnxNamesAction = useAction(setOnnxNames)
  const onSetLogPath = useAction(setLogPath)
  useEffect(() => {
    async function showVersion() {
      try {
        const response = await getVersion()
        if (response && response.data) {
          const { installVer, version, nbOnnxNames } = response.data
          if (installVer) {
            // eslint-disable-next-line no-console
            console.log(`Installation version ${installVer}`)
          }
          // eslint-disable-next-line no-console
          console.log(`Backend version ${version}`)
          // eslint-disable-next-line no-console
          console.log(`Frontend version ${formatConsoleVersion(versionInfo)}`)
          await checkUrlAuthToken(response.data)
          setInstallVersionAction(response.data)
          if (nbOnnxNames) {
            setOnnxNamesAction(nbOnnxNames)
          }
          if (NW_JS_APP_PROFILES.includes(__APP_PROFILE__)) {
            onSetLogPath(response.data)
            await waitForLocalStorage()
          }
          onSuccess()
        }
      } catch (e) {
        console.error('Failed to fetch backend version\n', e)
      }
    }
    showVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])// Intentionally left dependencies array empty to run only once

  return null
}
