// eslint-disable-next-line no-unused-vars
import axiosCore, { AxiosPromise } from 'axios'
import { IMAGES_SOURCES, SOURCE_FILE_TYPES, TEXTS, TRANSITIONS, FILE_TYPE, MEDIA_FILE_FILTERS } from 'enums'
import { getLang } from 'helpers/getLang'
import https from 'https'
import { v4 as uuidv4 } from 'uuid'
import { translations } from 'translations/translations'
import Url from 'url'

import { handleError } from '~/errors/handleError'
import { getObjectFields } from '~/Util'

const isHTTPS = (__API_HTTPS__ && __API_HTTPS__ === 'true') || window.location.protocol === 'https:'
const getPortBasedOnProtocol = () => isHTTPS ? 443 : 80
const getPortFromUrl = () => ((window.location.port !== '') && (__APP_PROFILE__ !== 'package') ? window.location.port : getPortBasedOnProtocol())
const baseHost = __BACKEND_HOST__ || window.location.hostname
const backendHost = __APP_PROFILE__ === 'package' ? (localStorage.getItem('solveig-editor-back-url') || baseHost)
  .replace('https://', '').replace('http://', '').replace('/', '') : baseHost
const apiPort = __API_PORT__ || getPortFromUrl()
const wsPort = __WS_PORT__ || getPortFromUrl()

const HOST_API = Url.format({
  protocol: isHTTPS ? 'https' : 'http',
  hostname: backendHost,
  port: apiPort,
})
const HOST_WS = Url.format({
  protocol: isHTTPS ? 'wss' : 'ws',
  hostname: backendHost,
  port: wsPort,
  pathname: '/websocket',
})

const axios = axiosCore.create()

axios.defaults.baseURL = HOST_API
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'


const getClientId = () => {
  // this id is different in another tab persist the same on reload page in the same tab.
  // used to control edit project from different tabs
  let clientId = sessionStorage.getItem('clientId')
  if (!clientId) {
    clientId = uuidv4()
    sessionStorage.setItem('clientId', clientId)
  }
  return clientId
}
/**
 * @namespace API
 */

/**
 * @typedef {object} API.AxiosResponse
 * @template T
 * @property {number} status
 * @property {string} statusText
 * @property {T} data
 */

/**
 * @typedef {object} API.FilesResourceResponse
 * @template T
 * @property {number} total_count
 * @property {T[]} entries
 */

// TODO: describe shapes for each files type

export function setAuthToken(authToken) {
  axios.defaults.headers.common.Authorization = authToken
}

export function getVersion() {
  return axios.get('/api/version', { crossDomain: true })
}

export function checkToken(authToken) {
  return axios.get(`/api/tokens/${authToken}`, { crossDomain: true })
}

async function getPermanentUserFolders() {
  const params = {
    start_number: 0,
    count: __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER,
    filter: MEDIA_FILE_FILTERS.Folder,
  }
  const config = {
    params,
    crossDomain: true,
  }
  const resp = await axios.get('/api/mediafiles', config)
  return resp.data.entries
}

function getBackendMedia(params) {
  const config = {
    params,
    crossDomain: true,
  }
  return axios.get('/api/mediafiles', config)
}

/**
 * @param {object} [options]
 * TODO: options list
 * @returns {Promise<AxiosResponse<FilesResourceResponse<Object>>}
 */
export async function getMedia(options = {}) {
  const {
    start = 0,
    count = __CFG__.SOURCE_FILES_MANAGEMENT.LOADING_NUMBER,
    filter = undefined,
    order,
    sort,
    search = undefined,
    folder = undefined,
  } = options

  const optionalParams = { filter, search }

  const params = {
    start_number: start,
    count,
    folder,
    sort_order: order,
    sort_field: sort,
    ...Object.keys(optionalParams).filter(k => !!optionalParams[k])
      .reduce((acc, k) => ({ ...acc, [k]: optionalParams[k] }), {}),
  }
  let resp
  const { customApiUrls, customApiAuthToken, permanentFoldersIds } = axios.defaults
  if (customApiUrls?.mediaTabContentUrl) {
    if (folder && permanentFoldersIds?.filter(id => id === folder)) {
      // permanent folder content requested
      resp = await getBackendMedia(params)
    } else {
      const config = {
        params,
        headers: {
          Authorization: customApiAuthToken,
        },
        crossDomain: true,
      }
      resp = await axios.get(customApiUrls.mediaTabContentUrl, config)
      const { data } = resp
      data.entries = data.entries.map(entry => ({
        ...entry,
        type: FILE_TYPE.DEVMEDIA,
      }))
      if (customApiUrls?.mediaTabContentUrl && start === 0 && !folder) {
        // fetch default folders for user into which import, voiceover and output will be placed
        const permanentUserFolders = await getPermanentUserFolders()
        if (permanentUserFolders.length) {
          data.entries.unshift(...permanentUserFolders)
          data.total_count += permanentUserFolders.length
        }
      }
    }
  } else {
    resp = await getBackendMedia(params)
  }
  return resp
}

// TODO: not implemented
/**
 * @returns {Promise<API.AxiosResponse<API.FilesResourceResponse<object>>>}
 */
export function fetchTextFiles() {
  return {
    data: {
      entries: [
        {
          text: 'TEXT',
          id: 'example_text_uniqueid',
          type: TEXTS.DEFAULT_TEXT,
          description: 'Text',
        },
      ],
    },
  }
}

// TODO: not implemented
/**
 * @returns {Promise<API.AxiosResponse<API.FilesResourceResponse<object>>>}
 */
export function fetchTransition() {
  const transitionTemplatesBaseUrl = `${HOST_API}/transition_templates`
  return {
    data: {
      entries: [
        {
          name: 'Dissolve',
          type: TRANSITIONS.DISSOLVE,
          sourceFileType: SOURCE_FILE_TYPES.TRANSITIONS,
          id: 'example_transition_uniqueid_dissolve.mp4',
          previewUrl: `${transitionTemplatesBaseUrl}/dissolve.mp4`,
        },
        {
          name: 'Fade In',
          type: TRANSITIONS.FADEIN,
          sourceFileType: SOURCE_FILE_TYPES.TRANSITIONS,
          id: 'example_transition_uniqueid_fade_in.mp4',
          previewUrl: `${transitionTemplatesBaseUrl}/fade_in.mp4`,
        },
        {
          name: 'Fade Out',
          type: TRANSITIONS.FADEOUT,
          sourceFileType: SOURCE_FILE_TYPES.TRANSITIONS,
          id: 'example_transition_uniqueid_fade_out.mp4',
          previewUrl: `${transitionTemplatesBaseUrl}/fade_out.mp4`,
        },
      ],
    },
  }
}

export async function getMediaFile(id, type, sourceFileType) {
  let apiUrl
  const config = {
    crossDomain: true,
    headers: {
      Authorization: axios.defaults.headers.common.Authorization,
    },
  }

  switch (type) {
    case FILE_TYPE.DEVMEDIA:
    {
      const { customApiUrls, customApiAuthToken } = axios.defaults
      apiUrl = customApiUrls.fetchFileUrl
      config.headers.Authorization = customApiAuthToken
      break
    }
    default: {
      apiUrl = '/api/mediafiles'
    }
  }

  if (sourceFileType === SOURCE_FILE_TYPES.IMAGES) {
    apiUrl = '/api/imagelib_files'
    if (id.includes(IMAGES_SOURCES.Pexels)) {
      apiUrl += `/${IMAGES_SOURCES.Pexels}`
    } else if (id.includes(IMAGES_SOURCES.Unsplash)) {
      apiUrl += `/${IMAGES_SOURCES.Unsplash}`
    } else {
      apiUrl += `/${IMAGES_SOURCES.Native}`
    }
  }

  const resp = await axios.get(`${apiUrl}/${id}`, config)
  handleError(resp)
  if (type === FILE_TYPE.DEVMEDIA && resp.data) {
    resp.data.type = FILE_TYPE.DEVMEDIA
  }
  return resp
}

/**
 *
 * @param {*} id
 * @returns {AxiosPromise<{ progress:  number, status: string, error: any }>}
 */
export function getMediaFileStatus(id) {
  return axios.get(`/api/mediafile/status?id=${id}&uid=${Date.now()}`, { crossDomain: true })
}

export function getThumbnail(fname = '', width = 174, height = 113, time = 0) {
  return axios.get(`/api/testfiles/thumbnails/init?fname=${fname}&width=${width}&height=${height}&time=${time}`, { crossDomain: true })
}

export function getCachedThumbnails(fileId, count = 1, startTime = 0, step = 0) {
  return axios.get(`/api/clip_thumbnails?id=${fileId}&start_time=${startTime}&step=${step}&count=${count}&cached=true`, { crossDomain: true })
}

export function getCachedThumbnail(fileId, type, time = 0) {
  let apiUrl
  const config = {
    crossDomain: true,
    headers: {
      Authorization: axios.defaults.headers.common.Authorization,
    },
  }
  switch (type) {
    case FILE_TYPE.DEVMEDIA:
    {
      const { customApiUrls, customApiAuthToken } = axios.defaults
      apiUrl = customApiUrls.fetchThumbnailUrl
      config.headers.Authorization = customApiAuthToken
      break
    }
    default: {
      apiUrl = '/api/clip_thumbnail'
    }
  }
  return axios.get(`${apiUrl}?id=${fileId}&time=${time}&cached=true`, config)
}

export function createEditingTask(xtl) {
  return axios.post('/api/task/editing', {
    xtl_task: xtl,
  })
}

export function uploadOverlayImage(imageBlob) {
  const config = {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  }
  return axios.post('/api/overlay_img', imageBlob, config)
}

export async function getEditingTaskStatus(taskId) {
  const { data } = await axios.get(`/api/task/editing?id=${taskId}`)
  return data
}

export function deleteEditingTask(taskId) {
  return axios.delete('/api/task/editing', {
    params: {
      id: taskId,
    },
  })
}

export function createHldTask({ fileId, onnxName, targetDurationSec }) {
  return axios.post('/api/task/nb_hld', {
    fileId, onnxName, targetDurationSec,
  })
}

export async function getHldTaskStatus(taskId) {
  const { data } = await axios.get(`/api/task/nb_hld/${taskId}`)
  return data
}

export function deleteHldTask(taskId) {
  return axios.delete(`/api/task/nb_hld/${taskId}`)
}

export function createExportProjectTask({ exportType, includeMedia, projectId, outputAbsPath }) {
  return axios.post('/api/task/export_project', { projectId, includeMedia, exportType, outputAbsPath })
}


export async function getExportProjectStatus(taskId) {
  const { data } = await axios.get(`/api/task/export_project?id=${taskId}`)
  return data
}

export function deleteExportProjectTask(taskId) {
  return axios.delete(`/api/task/export_project?id=${taskId}`)
}

export function deleteMediaFile(id, force = false) {
  let params = {}

  if (force) {
    params = { ...params, force }
  }

  return axios.delete(`/api/mediafiles/${id}`, { crossDomain: true, params }).then(handleError)
}

export function createWebSocketClient() {
  return new WebSocket(HOST_WS)
}

export function createUploadSession(name, size, folderId) {
  const params = { name, size }

  if (folderId) {
    params.folder = folderId
  }
  return axios.post('/api/upload/media', params)
}

export function uploadMediaFile({ id, chunk, start, end, totalSize }) {
  return axios.put(`/api/upload/media?id=${id}`, chunk, {
    headers: {
      'Content-type': 'application/octet-stream',
      'Content-Range': `bytes ${start}-${end}/${totalSize}`,
    },
  })
}

export function importFromUrl({ url, folder }) {
  return axios.post('/api/import/media/url', { url, folder })
}

export function switchLogEnabled(logsEnabled) {
  return axios.post('/api/backdoor/logswitch', { enable: logsEnabled })
}

export function getProject(id) {
  const clientId = getClientId()
  return axios.get(`/api/projects/${id}?clientId=${clientId}`).then(res => res.data)
}

export function saveProject(id, project) {
  const httpsAgent = new https.Agent({ keepAlive: true })
  const body = {
    ...project,
    clientId: getClientId(),
  }
  return axios.patch(`/api/projects/${id}`, body, { httpsAgent })
}

export function renameProject(id, name) {
  const body = {
    name,
    clientId: getClientId(),
  }
  return axios.patch(`/api/projects/${id}`, body)
}

export function createProject(project) {
  const httpsAgent = new https.Agent({ keepAlive: true })
  const body = {
    ...project,
    clientId: getClientId(),
  }
  return axios.post('/api/projects', body, { httpsAgent }).then(res => res.data)
}

export function getProjects(search, start) {
  return axios.get('/api/projects', {
    params: { search, count: 20, start_number: start, data: 0 },
  }).then(res => ({ projects: res.data.entries, totalCount: res.data.total_count }))
}

export function deleteProject(id) {
  return axios.delete(`/api/projects/${id}`)
}

export const saveHistoryAction = ({ projectId, historyAction }) => {
  const { id, createTime, name } = historyAction
  // console.log(`saveHistoryAction  ${id}`)
  const dataObject = getObjectFields(historyAction)
  delete dataObject.payload
  return axios.post('/api/actions', {
    id,
    project: projectId,
    name,
    data: JSON.stringify(dataObject),
    createTime,
  }).then(({ data: { id: actionId } }) => actionId)
}

export const undoHistoryAction = (actionId, modifTime) => axios.patch(`/api/actions/${actionId}`, { canceled: true, modifTime }).then(() => actionId)

export const redoHistoryAction = (actionId, modifTime) => axios.patch(`/api/actions/${actionId}`, { canceled: false, modifTime }).then(() => actionId)

export function importFromAmazon({ secretKey, bucketName, accessKeyId }) {
  return axios.post('/api/import/media/awsbucket',
    {
      awsAccessKey: accessKeyId,
      awsSecreteKey: secretKey,
      awsBucketName: bucketName,
    }).then(res => (res.data))
}

export function importFromLocalFile(path, size, folderId) {
  const params = { path, size }
  if (folderId) {
    params.folder = folderId
  }
  return axios.post('/api/import/media/local_file', params)
}

export function createUser(body) {
  return axios.post('/api/users', body).then(r => r.data)
}

export function patchUser(body) {
  return axios.patch(`/api/users/${axios.defaults.headers.common.Authorization}`, body)
}

export async function getTranslations(customLanguageUrl) {
  try {
    const data = await (await fetch(customLanguageUrl)).json()
    const lang = getLang()
    if (!data[lang]) {
      if (data.en) {
        return data.en
      }
      return translations.en
    }
    return data[lang]
  } catch {
    return translations.en
  }
}

export function patchDevProfile(body) {
  return axios.patch(`/api/dev_profiles/${axios.defaults.headers.common.Authorization}`, body)
}

export const httpClient = axios
