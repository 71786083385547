import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { SEPARATOR } from 'enums'
import './SliderPointer.scss'
import cx from 'classnames'
import { n, time2Pixel, getFPSByRefVideo, timelineTimeToSeconds, secondsToTimelineTime, nnn } from '~/Util'
import { getObjectTimeCode, isDropFrameTimeCode } from '~/Util/timecode'
import { TranslationContext } from '~/contexts/TranslationContext'
import { TIME_CODE_MODE, TIME_CODE_CONTAINER_LOCATION } from '~/config/constants/timecode'
import { getReferenceVideoAsset } from '~/selectors'
import { TimelineScrollPositionContext } from './ScrollPositionContext'
import { Context as TimelineGeometryContext } from './GeometryContextProvider'

function SliderPointer(props) {
  const { scale, refRuller } = props
  const { scrollLeft, localTime: time, isSticky } = React.useContext(TimelineScrollPositionContext)
  const { timelineHeight } = React.useContext(TimelineGeometryContext)
  const { t } = React.useContext(TranslationContext)
  const refVideo = useSelector(getReferenceVideoAsset)
  const fps = getFPSByRefVideo(refVideo)
  const timeModes = useSelector(state => state.playback.timeCodeMode)
  const mode = timeModes[TIME_CODE_CONTAINER_LOCATION.TIMELINE]
  const isDF = isDropFrameTimeCode(mode, fps)
  const {
    hours, seconds, milliseconds, minutes, frames, frameCount,
  } = getObjectTimeCode(timelineTimeToSeconds(time), fps, mode)

  const x = time2Pixel(mode === TIME_CODE_MODE.MILLISECOND
    ? time
    : secondsToTimelineTime(frameCount / fps), scale) - scrollLeft

  return (
    <div
      className="slider-pointer"
      style={{
        transform: `translateX(${time2Pixel(mode === TIME_CODE_MODE.MILLISECOND
          ? time
          : secondsToTimelineTime(frameCount / fps), scale) - scrollLeft}px)`,
        display: refRuller.current?.clientWidth < x ? 'none' : 'flex',
      }}
      data-lang-id="TIMELINE_SLIDER_POINTER"
      title={t('TIMELINE_SLIDER_POINTER_TOOLTIP')}
    >
      <div className={cx('slider-pointer__head', {
        'slider-pointer__sticky': isSticky,
      })}
      />
      <input
        type="text"
        className="slider-pointer__input"
        readOnly
        value={timeModes[TIME_CODE_CONTAINER_LOCATION.TIMELINE] === TIME_CODE_MODE.MILLISECOND
          ? `${n(hours)}:${n(minutes)}:${n(seconds)}${SEPARATOR.DOT}${nnn(milliseconds)}`
          : `${n(hours)}:${n(minutes)}:${n(seconds)}${isDF ? SEPARATOR.SEMICOLON : SEPARATOR.COLON}${n(frames)}`}
      />
      <div
        className={cx('slider-pointer__body', {
          'slider-pointer__sticky': isSticky,
        })}
        style={{ height: timelineHeight }}
      />
    </div>
  )
}

SliderPointer.defaultProps = {
  refRuller: undefined,
}

SliderPointer.propTypes = {
  refRuller: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  scale: PropTypes.number.isRequired,
}

export default React.memo(SliderPointer)
