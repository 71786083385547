import React, { useCallback, useRef, useState, useContext, forwardRef, memo } from 'react'
import cx from 'classnames'
import { Box } from '@material-ui/core'

import HeadControlPanel from 'components/HeadControlPanel'
import { useSelector } from 'react-redux'
import { setSourceFilesParams as setSourceFilesParamsAction,
  setSourceFilesUiParams as setSourceFilesUiParamsAction } from 'actions/sourceFiles'

import { useAction } from 'hooks'
import { selectSourceFilesParams,
  selectSourceFilesUiParams } from 'selectors'

import ImportTool from 'components/HeadControlPanel/ImportTool'
import { SearchInput } from 'components/base/SearchInput/SearchInput'
import RawButton from 'components/base/RawButton'

import { MEDIA_FILE_FILTERS } from 'enums'
import { RadioCombobox } from 'components/base/Combobox'
import { TranslationContext, TranslationFunc } from 'contexts/TranslationContext'
import { selectElementDisplayType } from 'selectors/useInterface'
import { DISPLAY_TYPE } from 'config/constants/ui'
import MediaMenu from './MediaMenu'
import MediaFilter from './MediaFilter'

import { ReactComponent as FilterIcon } from '~/assets/media/ic_filter.svg'
import { ReactComponent as MenuIcon } from '~/assets/media/ic_menu.svg'

import styles from './header.module.scss'

type Props = {
  type: string,
}

type FilterItem = {
  label: string,
  value: typeof MEDIA_FILE_FILTERS[keyof typeof MEDIA_FILE_FILTERS],
  dataLangId: string,
  title: string
}

const enableCombobox = __APP_PROFILE__ === 'vrspot' && Object.values(__CFG__
  .SOURCE_FILES_FILTERS.MEDIA_RECORDING).some(Boolean)

const Import = () => <>{__CFG__.IMPORT_MEDIA && <ImportTool />}</>

const Filter = forwardRef<HTMLDivElement, {onSortBtnClick(): void, t: TranslationFunc}>(
  ({ t, onSortBtnClick }, ref) => (
    <>
      {__CFG__.SOURCE_FILES_MANAGEMENT.FILTER
      && (
      <div className="head-control__btn" ref={ref} title={t('MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP')}>
        <RawButton onClick={onSortBtnClick} dataLangId="MEDIA_HEAD_CONTROL_BTN_FILTERS">
          <FilterIcon />
        </RawButton>
      </div>
      )}
    </>
  ))

const OptionsMenu = forwardRef<HTMLDivElement, {handleClickViewMenu(): void, t: TranslationFunc}>(
  ({ handleClickViewMenu, t }, ref) => (
    <>
      {__CFG__.SOURCE_FILES_MANAGEMENT.VIEW_OPTIONS
      && (
      <div
        className="head-control__btn"
        ref={ref}
        title={t('MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP')}
      >
        <RawButton onClick={handleClickViewMenu} dataLangId="MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS">
          <MenuIcon />
        </RawButton>
      </div>
      )}
    </>
  ))

function HeaderContainer({ type }: Props) {
  const refFilterMenu = useRef<HTMLDivElement>(null)
  const refViewMenu = useRef<HTMLDivElement>(null)
  const [ isOpenFilter, setIsOpenFilter ] = useState(false)
  const [ isOpenViewMenu, setIsOpenViewMenu ] = useState(false)

  const { t } = useContext(TranslationContext)

  const sourceTypeFilterItems: FilterItem[] = [
    { value: MEDIA_FILE_FILTERS.All,
      label: t('MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA'),
      dataLangId: 'MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA',
      title: t('MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP') },
    { value: MEDIA_FILE_FILTERS.Voiceover,
      label: t('MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS'),
      dataLangId: 'MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS',
      title: t('MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP') },
  ]

  const setSourceFilesParams = useAction(setSourceFilesParamsAction, type)
  const setSetSourceFilesUiParams = useAction(setSourceFilesUiParamsAction, type)

  const params = useSelector((state: RootState) => selectSourceFilesParams(state,
    type))
  const uiParams = useSelector((state: RootState) => selectSourceFilesUiParams(state,
    type))
  const { sourceList: sourceListDisplayType } = useSelector(selectElementDisplayType)

  const handleSearch = useCallback(search => {
    setSourceFilesParams({ search })
  }, [ setSourceFilesParams ])

  const onSortBtnClick = useCallback(() => {
    setIsOpenFilter(true)
  }, [])

  const onFilterChange = useCallback(e => {
    const filter = e.target.value
    setSourceFilesParams({ filter })
  }, [ setSourceFilesParams ])

  const onCloseMediaFilter = useCallback(() => {
    setIsOpenFilter(false)
  }, [])

  const handleCloseMediaViewMenu = useCallback(() => {
    setIsOpenViewMenu(false)
  }, [])

  const handleChangeOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSourceFilesParams({ order: e.target.value })
  }

  const handleChangeSort = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSourceFilesParams({ sort: e.target.value })
  }

  const handleChangeView = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSetSourceFilesUiParams({ view: e.target.value })
  }

  const handleClickViewMenu = () => {
    setIsOpenViewMenu(true)
  }

  const { search, filter, sort, order } = params
  const { view } = uiParams

  const currentFilterValue = sourceTypeFilterItems.find(item => item.value === filter)?.value

  return (
    <>
      <HeadControlPanel caption={t('MEDIA_LIST_HEADER_TEXT_MEDIA')}>
        {enableCombobox
        && (
        <RadioCombobox
          items={sourceTypeFilterItems}
          value={currentFilterValue}
          onChange={onFilterChange}
          classes={{ select: styles.combobox }}
          MenuProps={{ classes: { paper: cx('media-sort', styles.menu) } }}
        />
        )}
        <Box display="flex" gridGap="2px">
          <Import />
          {sourceListDisplayType === DISPLAY_TYPE.MIN_WIDTH && (
            <>
              <Filter onSortBtnClick={onSortBtnClick} ref={refFilterMenu} t={t} />
              <OptionsMenu handleClickViewMenu={handleClickViewMenu} ref={refViewMenu} t={t} />
            </>
          )}
        </Box>
      </HeadControlPanel>
      <MediaFilter
        anchorEl={refFilterMenu.current}
        handleClose={onCloseMediaFilter}
        value={filter}
        onChange={onFilterChange}
        open={isOpenFilter}
      />
      <MediaMenu
        anchorEl={refViewMenu.current}
        handleClose={handleCloseMediaViewMenu}
        onChangeOrder={handleChangeOrder}
        onChangeSort={handleChangeSort}
        onChangeView={handleChangeView}
        open={isOpenViewMenu}
        order={order}
        sort={sort}
        view={view}
      />
      {__CFG__.SOURCE_FILES_MANAGEMENT.SEARCH && <SearchInput value={search} onSearch={handleSearch} />}
      {sourceListDisplayType !== DISPLAY_TYPE.MIN_WIDTH && (
        <>
          <Filter onSortBtnClick={onSortBtnClick} ref={refFilterMenu} t={t} />
          <OptionsMenu handleClickViewMenu={handleClickViewMenu} ref={refViewMenu} t={t} />
        </>
      )}
    </>
  )
}

export default memo(HeaderContainer)
