import * as ActionTypes from 'actions/ActionTypes'
import { BEHAVIOUR_SETTINGS_FORM_STATE, RENDER_SETTINGS_FORM_STATE } from 'config/constants/editorSettings'
import { EDITOR_MAIN_SETTINGS_MENU_ITEMS, SETTINGS_VIEW_MODE } from 'config/constants/mainView'
import { DROP_TARGET_TYPE, MENU_ITEMS } from 'enums'
import PROFILE_CONFIG from 'features/desktop-activation-feature/config'
import produce from 'immer'
import * as PlayerVolume from '~/cookieServices/PlayerVolume'

import * as Assets from '~/models/Asset'

const setChapterAtStorage = chapter => {
  localStorage.setItem('currentChapter', chapter)
  localStorage.setItem('SOURCE_FILES_TYPES', JSON.stringify(__CFG__.SOURCE_FILES_TYPES))
}

export const getChapterFromStorage = () => {
  let chapter = localStorage.getItem('currentChapter')
  const sourceFilesTypes = localStorage.getItem('SOURCE_FILES_TYPES')

  if (!chapter || sourceFilesTypes !== JSON.stringify(__CFG__.SOURCE_FILES_TYPES)) {
    chapter = MENU_ITEMS.MEDIA
    setChapterAtStorage(chapter)
  }

  return chapter
}

/**
 * @typedef {object} RenderSettings
 * @property {number} skipEmptySpace
 */

/**
 * @typedef {object} EditorSettings
 * @property {RenderSettings} render_settings
 */


/**
 * @typedef {object} MainView
 * @property {MenuItems} menuItem
 * @property {boolean} showSaveSettings
 * @property {boolean} showAssetSettings
 * @property { 'default' | 'new_publish' } settingsViewMode
 * @property {boolean} showNabletHldSettings
 * @property {boolean | string} showCustomizeView
 * @property {boolean} showImportFromUrl
 * @property {boolean} showAmazonImport
 * @property {boolean} showEditorSettings
 * @property {boolean} showLogActions
 * @property {boolean} isLocalFileDragging
 * @property {number} playerVolume
 * @property {{ x: number, y: number }} activeAssetRotation
 * @property {boolean} isLoading
 * @property {boolean} isOffline
 * @property {{ mediaRecordingSettings: 'audio' | 'video' | null }} showFeatures
 * @property {boolean} offlineRestoration
 * @property {EditorSettings} editorSettings
 * @property {string} edition
 * @property {string | null} activeDragElement
 * @property {DOMRect | object} dragElementBounds
 * @property {'root' | 'timeline' | 'sourceMedia'} dndDropTarget
 */

/**
 * @type {MainView}
 */
const initialState = {
  menuItem: getChapterFromStorage(),
  showSaveSettings: false,
  settingsViewMode: SETTINGS_VIEW_MODE.DEFAULT,
  showNabletHldSettings: false,
  showAssetSettings: false,
  showCustomizeView: false,
  showImportFromUrl: false,
  showAmazonImport: false,
  showEditorSettings: false,
  showLogActions: false,
  isLocalFileDragging: false,
  playerVolume: PlayerVolume.get(),
  // TODO: new rotation expect correct x and y coords, we can get them only from canvas
  // refactor this to another state slice or use explicit action listening instead of state
  activeAssetRotation: undefined,
  isLoading: false,
  isOffline: false,
  offlineRestoration: false,
  showFeatures: {
    mediaRecordingSettings: null,
  },
  activeDragElement: null,
  dragElementBounds: {},
  dndDropTarget: DROP_TARGET_TYPE.ROOT,
  edition: localStorage.getItem(PROFILE_CONFIG.LOCAL_STORAGE_EDITION_VAR), // desktop only
  editorSettings: {
    [EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS]: {
      ...RENDER_SETTINGS_FORM_STATE,
      ...JSON.parse(localStorage.getItem(EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS) || '{}'),
    },
    [EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS]: {
      ...BEHAVIOUR_SETTINGS_FORM_STATE,
      ...JSON.parse(localStorage.getItem(EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS) || '{}'),
    },
  },
}

const mainView = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case ActionTypes.OPEN_CHAPTER: {
      state.showAssetSettings = false
      const { menuItem, force } = action.payload
      const chapter = (action.payload.menuItem === state.menuItem && !force) ? '' : menuItem
      openChapter(state, chapter)
      setChapterAtStorage(chapter)
      break
    }

    case ActionTypes.SET_IS_OFFLINE: {
      const { isOffline } = action.payload
      state.isOffline = isOffline
      break
    }

    case ActionTypes.ASSET_EDIT_STARTED: {
      const { asset } = action.payload
      openChapter(state, getMenuChapterByAsset(asset))
      state.showAssetSettings = true
      break
    }

    case ActionTypes.ASSET_EDIT_CANCELED: {
      openChapter(state, getChapterFromStorage())
      state.showAssetSettings = false
      break
    }

    case ActionTypes.SAVE_SETTINGS_VISIBILITY_CHANGED:
      state.showSaveSettings = action.payload.showSettings
      break

    case ActionTypes.NABLET_HLD_SETTINGS_VISIBILITY_CHANGED:
      state.showNabletHldSettings = action.payload.showSettings
      break

    case ActionTypes.CUSTOMIZE_VIEW_VISIBILITY_CHANGED:
      state.showCustomizeView = action.payload
      break

    case ActionTypes.EDITOR_MAIN_SETTINGS:
      state.showEditorSettings = action.payload
      break
    case ActionTypes.LOG_ACTIONS:
      state.showLogActions = action.payload
      break
    case ActionTypes.IMPORT_FROM_URL_VISIBILITY_CHANGED:
      state.showImportFromUrl = action.payload.showImportFromUrl
      break

    case ActionTypes.AMAZON_IMPORT_VISIBILITY_CHANGED:
      state.showAmazonImport = action.payload.showAmazonImport
      break

    case ActionTypes.LOCAL_FILE_DRAGGING:
      state.isLocalFileDragging = action.payload.isDragging
      break
    case ActionTypes.ACTIVE_ASSET_ROTATION:
      state.activeAssetRotation = action.payload.activeAssetRotation
      break
    case ActionTypes.SET_PLAYER_VOLUME: {
      const { playerVolume, persist } = action.payload
      state.playerVolume = playerVolume
      if (persist) {
        PlayerVolume.set(playerVolume)
      }
      break
    }
    case ActionTypes.SET_IS_LOADING: {
      const { isLoading } = action.payload
      state.isLoading = isLoading
      break
    }
    case ActionTypes.MEDIA_RECORDING_INIT: {
      state.showFeatures.mediaRecordingSettings = action.payload
      break
    }
    case ActionTypes.MEDIA_RECORDING_CANCELED: {
      state.showFeatures.mediaRecordingSettings = null
      break
    }
    case ActionTypes.CLOSE_FEATURES: {
      state.showCustomizeView = false
      Object.keys(state.showFeatures).forEach(key => { state.showFeatures[key] = null })
      break
    }
    case ActionTypes.RESET_MAIN_VIEW: {
      const editorSettings = {
        [EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS]: {
          ...RENDER_SETTINGS_FORM_STATE,
          ...JSON.parse(localStorage.getItem(EDITOR_MAIN_SETTINGS_MENU_ITEMS.RENDER_SETTINGS) || '{}'),
        },
        [EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS]: {
          ...BEHAVIOUR_SETTINGS_FORM_STATE,
          ...JSON.parse(localStorage.getItem(EDITOR_MAIN_SETTINGS_MENU_ITEMS.BEHAVIOUR_SETTINGS) || '{}'),
        },
      }
      const correctInitialState = {
        ...initialState,
        editorSettings,
      }
      Object.assign(state, correctInitialState)
      break
    }
    case ActionTypes.OFFLINE_RESTORATION_START: {
      state.offlineRestoration = true
      break
    }
    case ActionTypes.OFFLINE_RESTORATION_END: {
      state.offlineRestoration = false
      break
    }
    case ActionTypes.SET_DND_DROP_TARGET:
      state.dndDropTarget = action.payload
      break
    case ActionTypes.SET_ACTIVE_DRAG_ELEMENT: {
      const { element } = action.payload
      state.activeDragElement = element
    }
      break
    case ActionTypes.SET_DRAG_ELEMENT_BOUNDS: {
      const { bounds } = action.payload
      state.dragElementBounds = bounds ?? {}
    }
      break
    case ActionTypes.SET_SETTINGS_VIEW_MODE: {
      const { mode } = action.payload
      state.settingsViewMode = mode
    }
      break
    case ActionTypes.SET_EDITOR_SETTINGS: {
      const { chapter, params } = action.payload
      state.editorSettings[chapter] = params
      localStorage.setItem(chapter, JSON.stringify(params))
    }
      break
  }
}

export default produce(mainView, initialState)

// ---

function openChapter(state, chapter) {
  state.menuItem = chapter
}

// ---

function getMenuChapterByAsset(asset) {
  // TODO: filters/design
  switch (asset.constructor) {
    case Assets.VideoAsset:
    case Assets.ImageAsset:
    case Assets.AudioAsset:
      return MENU_ITEMS.MEDIA
    case Assets.TextAsset:
      return MENU_ITEMS.TEXT
    // http://18.184.210.86/issues/1975
    // case Assets.AudioAsset:
    //   return MENU_ITEMS.AUDIO
    case Assets.TransitionAsset:
      return MENU_ITEMS.TRANSITIONS
    default:
      throw new Error(`Unsupported asset type: ${asset.constructor.name}`)
  }
}
