import PropTypes from 'prop-types'
import React from 'react'
import Scrollbars from '~/components/base/Scrollbars'
import * as PT from '~/PropTypes'
import CustomDragLayer from './DragAndDrop/CustomDragLayer'
import Layer from './Layer'
import './Timeline.scss'
import { useTimelineDropTarget } from './useTimelineDropTarget'
import { useAllocationAria } from '~/hooks/timeline/useAllocationAria'
import { LAYERS_ARIA_ID } from '~/config/constants/layer'

const TimelineWorkspace = React.forwardRef((props, ref) => {
  const {
    scrollbarRef,
    onScroll,
    layers,
    onLayerAssetsLoaded,
    onDeniedDrop,
    isEmpty,
    scale,
  } = props

  useTimelineDropTarget(ref, { scale, onDeniedDrop, layers })

  const { allocationAriaRef } = useAllocationAria(ref, scrollbarRef, scale)

  return (
    <div draggable={false} ref={ref} id={LAYERS_ARIA_ID} className="layers">
      <CustomDragLayer />
      <Scrollbars
        id="allocation_area"
        trackSize={8}
        onScroll={onScroll}
        ref={scrollbarRef}
      >
        <div ref={allocationAriaRef} id="allocation_area-container" />
        {layers.map((item, index) => (
          <Layer
            onLayerAssetsLoaded={onLayerAssetsLoaded}
            layerIndex={index}
            key={item.id}
            layer={item}
            showDropAreaPlaceholder={isEmpty && index === 0}
            onDeniedDrop={onDeniedDrop}
          />
        ))}
      </Scrollbars>
    </div>
  )
})

TimelineWorkspace.propTypes = {
  scrollbarRef: PT.RefObject.isRequired,
  layers: PropTypes.arrayOf(PT.Layer),
  isEmpty: PropTypes.bool,

  onScroll: PropTypes.func,
  onLayerAssetsLoaded: PropTypes.func,
  onDeniedDrop: PropTypes.func,
  scale: PropTypes.number,
}

const NOP = () => {}

TimelineWorkspace.defaultProps = {
  layers: [],
  isEmpty: false,
  scale: 1,

  onScroll: NOP,
  onLayerAssetsLoaded: NOP,
  onDeniedDrop: NOP,
}
export default React.memo(TimelineWorkspace)
