/* eslint-disable require-yield */
import { clearAssetsSelection, updateAssetInPreview } from 'actions/layer'
import { cancelAssetSettingsForm } from 'actions/mainView'
import { setTimeCodeMode } from 'actions/playback'
import { setShowInOutPoints } from 'actions/preview'
import { setReference } from 'actions/project'
import { MARK_TYPE } from 'config/constants/preview'
import { TIME_CODE_CONTAINER_LOCATION } from 'config/constants/timecode'
import { PLAYER_TYPE } from 'enums'
import { isEmpty, isNil, isNumber } from 'lodash'
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as ActionTypes from '~/actions/ActionTypes'
import {
  setIsSliderOnBufferingPos,
  setTimelineAssetIds,
  deleteLayer,
  forceDeleteEmptyLayer,
  clearCopiedAssets,
  showSettings,
  setSliderTimeCenter
} from '~/actions/timeline'
import { getBackgroundAssets } from '~/helpers/getBackgroundAssets'
import { getComposedAssets } from '~/helpers/getComposedAssets'
import { getPlayingAssets } from '~/helpers/getPlayingAssets'
import { getTransitionAssets } from '~/helpers/getTransitionAssets'
import {
  getAssets,
  getAssetsByLayers,
  getTransitionsByAssetId,
  selectBackgroundAssetIds,
  selectComposedAssetIds,
  selectFlattenedVideoAssets,
  selectPlayingAssetIds,
  selectTransitionAssetIds,
  selectAssetById,
  getSelectedAssets,
  getReferenceVideoAsset
} from '~/selectors'
import { selectIsIgnoreLatestActionInHistory } from '~/selectors/historyActions'
import { secondsToTimelineTime, DEFAULT_FPS } from '~/Util'

const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)
const toId = x => x.id

function* handleSliderUpdateOrRewind() {
  const sliderTime = yield select(state => state.timeline.sliderTime)
  const allAssets = yield select(getAssets)
  const flattenedVideoAssets = yield select(selectFlattenedVideoAssets)
  const assetsByLayers = yield select(getAssetsByLayers)
  const backgroundAssetIds = yield select(selectBackgroundAssetIds)
  const transitionAssetIds = yield select(selectTransitionAssetIds)
  const composedAssetIds = yield select(selectComposedAssetIds)
  const playingAssetIds = yield select(selectPlayingAssetIds)

  const newPlayingAssets = getPlayingAssets({ sliderTime, assetsByLayers })
  const newPlayingAssetIds = newPlayingAssets.map(toId)

  const transitions = yield select(state => (
    newPlayingAssetIds.slice().reverse().reduce((acc, id) => {
      const assetTransitions = getTransitionsByAssetId(state, id)
      return assetTransitions.length ? [ ...acc, ...assetTransitions ] : acc
    }, [])
  ))

  const newTransitions = getTransitionAssets({
    sliderTime,
    transitions,
  })

  const newTransitionAssetIds = newTransitions.map(toId)

  const newComposedAssetIds = getComposedAssets({
    allAssets,
    transitions: newTransitions,
    playingMediaAssetIds: newPlayingAssetIds,
  }).map(toId)

  const newBackgroundAssetIds = getBackgroundAssets({
    sliderTime,
    playingMediaAssetIds: newPlayingAssetIds,
    flattenedVideoAssets,
  }).map(toId)

  if (!isEqual(backgroundAssetIds, newBackgroundAssetIds)
  || !isEqual(playingAssetIds, newPlayingAssetIds)
  || !isEqual(transitionAssetIds, newTransitionAssetIds)
  || !isEqual(composedAssetIds, newComposedAssetIds)) {
    yield put(setTimelineAssetIds({
      playingAssetIds: newPlayingAssetIds,
      backgroundAssetIds: newBackgroundAssetIds,
      composedAssetIds: newComposedAssetIds,
      transitionAssetIds: newTransitionAssetIds,
    }))
  }
}

function* handleSliderUpdate() {
  const isSliderOnBufferingPos = yield select(state => state.timeline.isSliderOnBufferingPos)
  if (isSliderOnBufferingPos) {
    yield put(setIsSliderOnBufferingPos(false))
  }
}

function* deleteEmptyLayers({ payload }) {
  const { recordInHistory } = payload ?? {}
  const layers = yield select(state => state.timeline.layers)
  const ignoreLatestActionInHistory = yield select(selectIsIgnoreLatestActionInHistory)
  const assetsByLayers = yield select(getAssetsByLayers)

  if (ignoreLatestActionInHistory) return

  for (const [ index, layer ] of layers.entries()) {
    const assets = assetsByLayers.get(layer.id)
    if (assets.length === 0 && index !== layers.length - 1) {
      yield put(deleteLayer(layer.id, false, true, recordInHistory))
    }
  }
}

function* deleteEmptyLayersWithoutHistory() {
  const layers = yield select(state => state.timeline.layers)
  const assetsByLayers = yield select(getAssetsByLayers)

  const ids = []
  for (const layer of layers.values()) {
    const assets = assetsByLayers.get(layer.id)
    if (assets.length === 0) {
      ids.push(layer.id)
    }
  }
  yield put(forceDeleteEmptyLayer(ids))
}

function* handleUpdateTimelineState() {
  yield put(clearCopiedAssets())
}

function* patchAddedAsset({ payload }) {
  const { asset } = payload
  const { markIn, markOut } = yield select(state => state.preview.clipCreator.media)
  const playbackFileID = yield select(state => state.playback.selectedClipId)
  if (playbackFileID !== asset.fileId) {
    return
  }
  const progress = yield select(state => state.preview.playerProgress.source)
  const initialStartTime = asset.startTime
  const mediaStart = !isNil(markIn) ? secondsToTimelineTime(markIn) : asset.mediaStart
  const duration = !isNil(markOut) ? secondsToTimelineTime(markOut) : asset.duration
  const patch = {
    startTime: initialStartTime,
    mediaStart,
    duration,
    endTime: initialStartTime + duration - mediaStart,
    playerProgress: secondsToTimelineTime(progress),
  }
  yield put(updateAssetInPreview(asset.id, patch))
}

function* patchSelectedAsset({ type, payload }) {
  const { showIOPoints, preview, updateAsset } = payload
  yield put(setShowInOutPoints(!!showIOPoints, preview))

  if (preview === PLAYER_TYPE.MEDIA && (isNil(updateAsset) || updateAsset)) {
    const inOutPointsTimeLineAssetId = yield select(state => state.preview.inOutPointsTimeLineAsset)
    const activePreview = yield select(state => state.preview.activePreview)
    const asset = yield select(state => selectAssetById(state, inOutPointsTimeLineAssetId))
    if (asset) {
      const { markIn, markOut } = yield select(state => state.preview.clipCreator.media)
      const selectedClipId = yield select(state => state.playback.selectedClipId)
      if (asset.fileId === selectedClipId) {
        let patch = {}
        if (type === ActionTypes.SET_PREVIEW_MARKER) {
          if (payload.markType === MARK_TYPE.OUT) {
            patch = {
              duration: isNumber(markOut)
                ? secondsToTimelineTime(markOut) - asset.mediaStart
                : asset.duration,
            }
          }
          if (payload.markType === MARK_TYPE.IN) {
            patch = {
              startTime: isNumber(markIn)
                ? asset.startTime - asset.mediaStart + secondsToTimelineTime(markIn)
                : asset.startTime,
              mediaStart: isNumber(markIn) ? secondsToTimelineTime(markIn) : asset.mediaStart,
              endTime: asset.endTime,
            }
          }
        } else if (type === ActionTypes.SET_PREVIEW_MARKERS) {
          const { markOut, markIn } = payload?.data[activePreview] ?? {}
          const markOutTimeLineUnitSec = secondsToTimelineTime(markOut)
          const markInTimeLineUnitSec = secondsToTimelineTime(markIn)
          const duration = markOutTimeLineUnitSec - markInTimeLineUnitSec
          patch = {
            duration,
            mediaStart: markInTimeLineUnitSec,
            startTime: asset.startTime,
            endTime: asset.startTime + duration,
          }
        }
        yield put(updateAssetInPreview(asset.id, patch, { historyAction: false }))
      }
    }
  }
}

function* handleShowAssetSettings() {
  const isShowAssetSettings = yield select(state => state.mainView.showAssetSettings)
  yield put(cancelAssetSettingsForm())
  if (isShowAssetSettings) {
    yield put(showSettings())
  }
}

function* clearTimelineAssetsSelection() {
  const selectedAsset = yield select(getSelectedAssets)
  if (!isEmpty(selectedAsset)) {
    yield put(clearAssetsSelection())
  }
}

function* onChangeScrollLeft() {
  const { sliderTime } = yield select(state => state.timeline)
  yield put(setSliderTimeCenter(sliderTime))
}

function* resetFPS() {
  const assets = yield select(state => state.layer.assets)
  const { width, height } = yield select(getReferenceVideoAsset)
  if (isEmpty(assets)) {
    yield put(setReference({
      id: null,
      width,
      height,
      fpsNum: DEFAULT_FPS,
      fpsDenum: 1,
    }))
    yield put(setTimeCodeMode({
      mode: __CFG__.TIME_CODE_MODE.TIMELINE_TIME_CODE,
      containerLocation: TIME_CODE_CONTAINER_LOCATION.TIMELINE,
    }))
  }
}

function* watchAll() {
  yield all([
    takeEvery([
      ActionTypes.TIMELINE_REWINDED,
      ActionTypes.UPDATE_SLIDER,
      ActionTypes.TRANSITION_ATTACHED_TO_ASSET,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
      ActionTypes.RIPPLE_TRIM,
      ActionTypes.UNDO_RIPPLE_TRIM,
      ActionTypes.ASSET_MOVED_ON_TIMELINE,
      ActionTypes.ASSET_ADDED_TO_TIMELINE,
      ActionTypes.COPIED_ASSET_ADDED_TO_TIMELINE,
      ActionTypes.ASSET_PREVIEW_UPDATED,
      ActionTypes.LAYER_TOGGLE_VISIBLE ], handleSliderUpdateOrRewind),
    takeEvery(ActionTypes.SET_COPIED_TIME_CODE, handleUpdateTimelineState),
    takeEvery([ ActionTypes.UPDATE_SLIDER ], handleSliderUpdate),
    takeLatest([
      ActionTypes.ASSET_ADDED_TO_TIMELINE,
    ], patchAddedAsset),
    takeEvery([
      ActionTypes.SET_PREVIEW_MARKER,
      ActionTypes.SET_PREVIEW_MARKERS,
    ], patchSelectedAsset),
    takeEvery([
      ActionTypes.ASSET_MOVED_ON_TIMELINE,
      ActionTypes.DELETE_ASSETS,
      ActionTypes.RIPPLE_DELETE_ASSETS,
      ActionTypes.RIPPLE_TRIM,
    ], deleteEmptyLayers),
    takeEvery([
      ActionTypes.MULTIPLE_ASSETS_MOVED_ON_TIMELINE,
      ActionTypes.UNDO_MULTIPLE_MOVE_ASSETS,
      ActionTypes.LOAD_PROJECT,
    ], deleteEmptyLayersWithoutHistory),
    takeEvery([
      ActionTypes.ASSET_SELECTED,
    ], handleShowAssetSettings),
    takeLatest([
      ActionTypes.SET_PREVIEW_MARKER,
      ActionTypes.SET_PREVIEW_MARKERS,
      ActionTypes.RESET_MARK,
    ], clearTimelineAssetsSelection),
    takeLatest([
      ActionTypes.SET_RESIZABLE_PARAMS,
      ActionTypes.SET_TIMELINE_SCALE,
    ], onChangeScrollLeft),
    takeLatest([
      ActionTypes.DELETE_ASSETS,
    ], resetFPS),
  ])
}

export default watchAll
