import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setTimeCodeMode, setTimeCodeState } from 'actions/playback'
import {
  ActiveTimeUnits,
  TimeCodeDataSetType,
  TimeCodeContainerLocationType,
  TimeCodeModeType,
  TimeCodeStateType,
  TimeCodeUnitsType,
  UnitMeasurementType,
  TimeCodeDigitsType
} from 'types/timecode'
import {
  INITIAL_TIME_CODE_DATA_BY_MODE,
  TIME_CODE_DATASET_VALUES,
  TIME_CODE_DATASET_ID,
  TIME_CODE_TIME_UNITS,
  TIME_CODE_STATE,
  UNIT_MEASUREMENT,
  TIME_CODE_CONTAINER_LOCATION
} from 'config/constants/timecode'
import { selectActiveTimeCode } from 'selectors/playback'
import {
  n,
  nnn,
  secondsToTimelineTime
} from 'Util'
import { getObjectTimeCode } from 'Util/timecode'
import { setSliderTimeCenter } from 'actions/timeline'
import { useTimeCodeKeyDown } from './useTimeCodeKeyDown'


type UseTimeCodeState = {
  containerLocation: TimeCodeContainerLocationType
  progress: number
  onRewind?(sec: number): void
  onMoveSlider?(unitSec: number): void
  fps: number,
  readonly?: boolean,
  timeMode: TimeCodeModeType
  isTimelineTime?: boolean
  startTime: number
}

export const useTimeCodeState = ({
  containerLocation,
  progress,
  onRewind,
  onMoveSlider,
  fps,
  readonly,
  timeMode,
  isTimelineTime,
  startTime,
}: UseTimeCodeState) => {
  const dispatch = useDispatch()
  const activeTimeCodeContainer = useSelector(selectActiveTimeCode)
  const [ timeUnits, setTimeUnits ] = useState<ActiveTimeUnits>([
    ...INITIAL_TIME_CODE_DATA_BY_MODE[
      localStorage.getItem(containerLocation) as TimeCodeModeType
      || __CFG__.TIME_CODE_MODE[containerLocation]
    ],
  ])
  const [ isActiveContainer, setActiveContaner ] = useState<boolean>(false)


  const onChangeMode = useCallback((mode: TimeCodeModeType) => {
    dispatch(setTimeCodeMode({ mode, containerLocation }))
  }, [ dispatch, containerLocation ])

  const resetActiveUnit = useCallback(() => {
    setTimeUnits(units => units.map(unit => ({ ...unit, active: false, editableDigit: null, nextDigit: null })))
  }, [ setTimeUnits ])

  const onActiveUnit = useCallback((e: globalThis.MouseEvent) => {
    if (readonly) return
    const dataSet = (e.target as HTMLElement)?.dataset
    const timeUnit = dataSet?.unit as UnitMeasurementType
    const timeDigit = dataSet?.digit as TimeCodeDigitsType
    const timeUnitDataSetID = dataSet?.id as TimeCodeUnitsType[number]

    if (dataSet?.id === TIME_CODE_DATASET_ID.MEASUREMENT) return
    if (TIME_CODE_TIME_UNITS.includes(timeUnitDataSetID)) {
      if (!isActiveContainer) {
        setActiveContaner(true)
        dispatch(setTimeCodeState({ timeCodeState: TIME_CODE_STATE.EDIT, activeTimeCodeContainer: containerLocation }))
      }
      setTimeUnits(units => units.map(unit => {
        const editableDigit = (unit.id === timeUnit) && unit.active ? timeDigit : null
        return {
          ...unit,
          editableDigit,
          active: unit.id === timeUnit,
        }
      }))
    }
  }, [ setActiveContaner, isActiveContainer, setTimeUnits, dispatch, containerLocation, readonly ])

  const toggleContainer = useCallback((
    timeCodeState?: TimeCodeStateType,
    activeTimeCodeLocation?: TimeCodeContainerLocationType
  ) => {
    setActiveContaner(false)
    resetActiveUnit()
    dispatch(setTimeCodeState({
      timeCodeState: timeCodeState ?? TIME_CODE_STATE.VIEW,
      activeTimeCodeContainer: activeTimeCodeLocation ?? null,
    }))
  }, [ setActiveContaner, resetActiveUnit, dispatch ])

  const onDeactivateContainer = useCallback((e: globalThis.MouseEvent) => {
    const dataId = (e.target as HTMLElement)?.dataset?.id ?? ''
    if (isActiveContainer
      && !TIME_CODE_DATASET_VALUES.includes(dataId as TimeCodeDataSetType)
    ) {
      e.stopPropagation()
      toggleContainer()
    }
  }, [ toggleContainer, isActiveContainer ])

  const setPlayerProgress = useCallback((newProgress: number) => {
    switch (containerLocation) {
      case TIME_CODE_CONTAINER_LOCATION.TIMELINE:
        isTimelineTime
          ? onMoveSlider?.(secondsToTimelineTime(newProgress))
          : onRewind?.(newProgress)
        dispatch(setSliderTimeCenter(secondsToTimelineTime(newProgress)))
        break
      case TIME_CODE_CONTAINER_LOCATION.SOURCE_MEDIA: {
        const curProgress = newProgress < startTime ? 0 : newProgress - startTime
        onRewind?.(curProgress)
        break
      }
    }
  }, [ isTimelineTime, containerLocation, onMoveSlider, onRewind, startTime, dispatch ])

  const { onKeyDown, onContainerArrowsIncrementControl, onChangeUnit } = useTimeCodeKeyDown({
    setActiveContaner,
    resetActiveUnit,
    timeUnits,
    isActiveContainer,
    setTimeUnits,
    progress,
    containerLocation,
    activeTimeCodeContainer,
    fps,
    toggleContainer,
    readonly,
    setPlayerProgress,
    timeMode,
  })

  useEffect(() => {
    const { hours, milliseconds, minutes, seconds, frames } = getObjectTimeCode(progress, fps, timeMode)
    setTimeUnits(() => (
      [ ...INITIAL_TIME_CODE_DATA_BY_MODE[timeMode] ].map(initialUnit => {
        const unit = { ...initialUnit }
        switch (unit.id) {
          case UNIT_MEASUREMENT.HOUR: unit.value = n(hours); break
          case UNIT_MEASUREMENT.MINUTE: unit.value = n(minutes); break
          case UNIT_MEASUREMENT.SECOND: unit.value = n(seconds); break
          case UNIT_MEASUREMENT.MILLISECOND: unit.value = nnn(milliseconds); break
          case UNIT_MEASUREMENT.FRAME: unit.value = n(frames); break
        }
        return unit
      })
    ))
  }, [ progress, fps, timeMode ])

  return {
    timeUnits,
    isActiveContainer,
    resetActiveUnit,
    onKeyDown,
    onActiveUnit,
    onDeactivateContainer,
    onChangeMode,
    onContainerArrowsIncrementControl,
    onChangeUnit,
    activeTimeCodeContainer,
  }
}
