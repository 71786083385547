/* eslint-disable quote-props */
/* eslint-disable quotes */
export const translations = {
  en: {
    "ADD_FOLDER_BTN": "Add folder",
    "ADD_FOLDER_BTN_TOOLTIP": "Add folder",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Bucket Name",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "Access Key ID",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "Secret Key",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "Importing",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "Import error",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "Imported sucessfully",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "Import media from AWS Bucket",
    "AMAZON_IMPORT_BTN_IMPORT": "Import",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "Import",
    "AUDIO_CAPTION_AUDIO": "Audio",
    "AUDIO_TEXT_FOUND_ITEMS": "Found items",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "No results found",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "It seems we can’t find any results based on your search.",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "Audio settings",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "Sound",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "Transition",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "Fade in",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "Fade out",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "Search genre",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "Search music",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "Search sound effects",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "Search in",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "Music by Genre",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "Sound Effects",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "Music by Mood",
    "AUDIO_MENU_ITEM_ALL": "All",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "Remove asset from timeline?",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "Set timecode format based on media",
    "COLOR_PICKER_BTN_COLOR": "Select color",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "Select color",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "Background color",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "Background color",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "Downloading media files",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "Uploading saved media",
    "COMMON_STATUS_TEXT_ABORTED": "Aborted",
    "COMMON_STATUS_TEXT_LOADING": "Loading",
    "COMMON_STATUS_TEXT_RENDERING": "Rendering",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "Cancel",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "Cancel",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "User avatar",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "You can upload a file of default user avatar.",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "Avatar",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "Color theme",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "Custom JS script",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "Custom text",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "You can customize the editor's text",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "This script runs on editor's loading. Can be used to integrate analytics etc.",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "Edit JS script",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "Second accent color",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "Select the color of the main background.",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "The main accent color for the controls. Select your brand color here.",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "Additional color of controls in pair with the main color of buttons.",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "The audio file on the timeline has its own style.",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "You can upload a file of your Logo.",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "The voiceover file has its own style.",
    "CUSTOMIZATION_LABEL_DISPLAYED": "displayed",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "Choose one of the predefined themes or create your own. You can edit the CSS file to customize your theme.",
    "CUSTOMIZATION_TITLE_BASIC_BG": "Basic background",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "Primary buttons, links",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "Edit CSS file for Theme",
    "CUSTOMIZATION_TITLE_AUDIO": "Audio",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "Voice over",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "Edit text values",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "Edit text values",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "Edit text values",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "Format description",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "Format description",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "Reset all changes",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "Reset all changes",
    "CUSTOMIZATION_BTN_CANCEL": "Cancel",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "Cancel",
    "CUSTOMIZATION_BTN_SAVE": "Save",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "Save",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "Edit JS script",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "Edit JS script",
    "CUSTOMIZATION_BTN_EDIT_CSS": "Edit CSS",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "Edit CSS",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "Upload logo",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "Upload logo",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "Delete logo",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "Delete logo",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": 'Dark theme',
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": 'Light theme',
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": 'Custom theme',
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "Logo",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "Logo",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "Customize editor",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "Logo and name",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "Company name",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "Editor name",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "Accepted files:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "No uploaded logo",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "View Settings",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "View Settings",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "Filters",
    "DUMMY_HEADER_BTN_FILTERS": "Filters",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Save project as..",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "New project",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "Publish",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "Customize editor",
    "EDITOR_MENU_MAIN_LOGS": "Logs",
    "EDITOR_MENU_MAIN_SETTINGS": "Settings",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "Unable to publish",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "Track is required",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "Render settings",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "Behaviour",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "Save",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "Cancel",
    "FILE_INPUT_BTN": "File input",
    "FILE_INPUT_BTN_TOOLTIP": "File input",
    "FORM_FIELD_LABEL_VOLUME": "Volume",
    "folder_not_empty": "The folder is not empty. The files can be used in projects. If you continue the file will not be available on timeline of the corresponding projects.",
    "HEAD_CONTROL_IMPORT_TEXT": "Import",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "Import media",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "Import folder with media",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL input",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Save project as..",
    "HEADER_MENU_LIST_ITEM_DELETE": "Delete",
    "HEADER_MENU_LIST_ITEM_RENAME": "Rename",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "Add license key",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "Log out",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "Do you want to leave the editor?",
    "HEADER_BTN_BACK_TO_MY_ACC": "Back to My Account",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "Back to My Account",
    "HEADER_BTN_HELP": "Help",
    "HEADER_BTN_HELP_TOOLTIP": "Help",
    "IMAGES_TEXT_NATIVE_IMAGES": "Native image",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "Images",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "Search in",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "Select collection",
    "IMAGES_TEXT_ALL_CATEGORIES": "All collections",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "No results found",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "It seems we can’t find any results based on your search.",
    "IMAGES_TAB_AI_GENERATED": "Ai-Generated",
    "IMAGES_TAB_SEARCH": "Search",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "Import media from URL",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "Click here to link to a file",
    "IMPORT_FROM_URL_BTN_IMPORT": "Import",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "Import",
    "LAYER_CONTROL_BTN_MOVE_UP": "Move Up",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "Move Up",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "Drag Layer",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "Drag Layer",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "Move Down",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "Move Down",
    "LAYER_CONTROL_BTN_DELETE": "Delete",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "Delete",
    "LAYER_CONTROL_BTN_HIDE": "Hide",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "Hide",
    "LAYER_CONTROL_BTN_SHOW": "Show",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "Show",
    "LAYER_CONTROL_BTN_MUTE": "Mute",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "Mute",
    "LAYER_CONTROL_BTN_UNMUTE": "Unmute",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "Unmute",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "My Media",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "My Media",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "Transitions",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "Transitions",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "Images",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "Images",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "Audio",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "Audio",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "Text",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "Text",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "Design",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "Design",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "Filters",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "Filters",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "Filters",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "Image settings",
    "MEDIA_FORM_CAPTION_POSITION": "Position",
    "MEDIA_FORM_CAPTION_ROTATION": "Rotation",
    "MEDIA_FORM_CAPTION_SETTINGS": "Settings",
    "MEDIA_FORM_CAPTION_OUTLINE": "Outline",
    "MEDIA_FORM_CAPTION_SHADOW": "Shadow",
    "MEDIA_FORM_CAPTION_SIZE": "Size",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "Opacity",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "Filter",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "Filter",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "All",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "Video",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "Image",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "Audio",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "Folder",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "Voiceover",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "View settings",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "View settings",
    "MEDIA_UPLOADER_TEXT_OR": "or",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "Drag and drop your media here",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "import media",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "Importing",
    "MEDIA_PREVIEW_TEXT_RENDERING": "Rendering",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "Resolution",
    "MEDIA_INFO_LIST_ITEM_CODEC": "Codecs",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "Frame rate",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
    "MEDIA_INFO_LIST_ITEM_DURATION": "Duration",
    "MEDIA_INFO_LIST_ITEM_TYPE": "Type",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "Audio",
    "MEDIA_INFO_LIST_ITEM_SIZE": "Size",
    "MEDIA_INFO_LIST_TASK_STATUS": "Task status",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "Start timecode",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "Media",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "Load more Media",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "Load more Media",
    "MEDIA_BTN_LOADING": "Loading",
    "MEDIA_BTN_LOADING_TOOLTIP": "Loading",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "Chroma Key",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "Position",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "Sound",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "SIZE",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "Media settings",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "Reset all settings",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "Reset all settings",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "Similarity",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "Smoothness",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "Key Color Spill Reduction",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "Pick color from the preview",
    "MEDIA_SORT_OPTION_DURATION": "Duration",
    "MEDIA_SORT_OPTION_DATE": "Date",
    "MEDIA_SORT_OPTION_SIZE": "Size",
    "MEDIA_SORT_OPTION_NAME": "Name",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "Sort By",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "Order",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "View",
    "MEDIA_SORT_OPTION_ASCENDING": "Ascending",
    "MEDIA_SORT_OPTION_DESCENDING": "Descending",
    "MEDIA_SORT_OPTION_LIST": "List",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "Large icons",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "My Recordings",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "My Recordings",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "My Media",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "My Media",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "Are you sure you want to remove selected item(s)?",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "Browse for folder",
    "MEDIA_CONFIRM_BTN_DELETE": "Delete",
    "MEDIA_CANCEL_BTN_DELETE": "Cancel",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "Delete",
    "MEDIA_CONFIRM_TITLE_DELETE": "Delete",
    "MEDIA_TEXT_LOADING": "Loading",
    "MEDIA_BTN_ADD": "Add",
    "MEDIA_BTN_ADD_TOOLTIP": "Add",
    "MEDIA_PROGRESS_TEXT_LOADING": "Loading",
    "MEDIA_PROGRESS_TEXT_SAVING": "Saving",
    "MEDIA_PROGRESS_TEXT_CONVERT": "Convert",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "Transfer",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "Analysis",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "Select Folder",
    "MEDIA_MENU_ITEM_MEDIA": "Media",
    "MEDIA_MENU_ITEM_MOVE_TO": "Move to",
    "MEDIA_MENU_ITEM_SETTINGS": "Settings",
    "MEDIA_MENU_ITEM_NABLET_HLD": "Detect highlights",
    "MEDIA_MENU_ITEM_DELETE": "Delete",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "Browse media",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "Copy media address",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "Add to timeline using start timecode",
    "MEDIA_MENU_LABEL_MOVE_TO": "Move to",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5 Video Editor",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "Tablets and mobile are not supported yet :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "Video Editor works well on your computer",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "Go back to website",
    "MODAL_WINDOW_LOGS_TITLE": "To switch logs on/off you need to restart the application",
    "MODAL_WINDOW_BUTTON_RESTART": "Restart",
    "NAVIGATION_TAB_EDITOR": "Editor",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "Editor",
    "NAVIGATION_TAB_PROJECTS": "Projects",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "Projects",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "Editing is unavailable until the connection is restored.",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "Last saved",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "min ago",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "just now",
    "PROJECTS_BTN_NEW_PROJECTS": "New project",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "New project",
    "PROJECTS_BTN_NEW_PROJECT": "New project",
    "PROJECTS_BTN_IMPORT": "Import",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "Import",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "Yes",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "Cancel",
    "PROJECTS_TITLE_TEXT_COPY": "Copy",
    "PROJECTS_BTN_SAVE": "Save",
    "PROJECTS_BTN_SAVE_TOOLTIP": "Save",
    "PROJECTS_TITLE_TEXT_RENAME": "Rename",
    "PROJECTS_TITLE_TEXT_DELETE": "Delete",
    "PROJECTS_TITLE_TEXT_EXPORT": "Export",
    "PROJECTS_CONFIRM_BTN_DELETE": "Delete",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "Edited %d hours ago",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "Edited just now",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "Edited %d minutes ago",
    "PROJECTS_PROGRESS_TEXT_LOADING": "Loading",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "Searching",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "Do you want to stop importing?",
    "PROJECTS_TEXT_NOTHING_FOUND": "Nothing found",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "Create a beautiful new project",
    "PROJECTS_TEXT_CAPTION": "Projects",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "Project name",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "Click to upload or, drag and drop a project file here",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "You can upload one file at a time only",
    "PROJECTS_PREFIX_TEXT_EDITED": "Edited",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "Are you sure you want to delete",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "This project will be deleted immediately. You can’t undo this action.",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "Project",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "Only one file can be uploaded",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "Invalid file extension. Should be *.edl.zip",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "Enter your value",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "Create a folder for this project in Media",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "Open Editor",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "Try again",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "Select frame rate for the project",
    "PROJECTS_EXPORT_TITLE": "Export project",
    "PROJECTS_EXPORT_INPROGRESS": "Export in progress...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "Include media",
    "PROJECTS_EXPORT_COMPLETE": "Export project complete",
    "PROJECTS_EXPORT_BTN_BROWSE": "Browse",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "Download",
    "PROJECTS_EXPORT_BTN_COPY": "Copy",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "Files from media are played back here",
    "PREVIEW_BTN_PREV_TOOLTIP": "Prev",
    "PREVIEW_BTN_PREV": "Prev",
    "PREVIEW_BTN_PLAY_TOOLTIP": "Play",
    "PREVIEW_BTN_PLAY": "Play",
    "PREVIEW_BTN_STOP_TOOLTIP": "Stop",
    "PREVIEW_BTN_STOP": "Stop",
    "PREVIEW_BTN_NEXT_TOOLTIP": "Next",
    "PREVIEW_BTN_NEXT": "Next",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "Volume",
    "PREVIEW_BTN_VOLUME": "Volume",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "Mark in",
    "PREVIEW_BTN_MARK_IN": "Mark in",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "Mark out",
    "PREVIEW_BTN_MARK_OUT": "Mark out",
    "PREVIEW_BTN_FULL_SCREEN": "Full screen",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "Full screen",
    "PREVIEW_HEADER_TITLE_SOURCE": "Source",
    "PREVIEW_HEADER_TITLE_TIMELINE": "Timeline",
    "PREVIEW_MODE_AUTO": "Auto",
    "PREVIEW_MODE_SOURCE": "Source preview",
    "PREVIEW_MODE_TIMELINE": "Timeline preview",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "output",
    "PUBLISH_SETTINGS_TITLE": "Title",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "Start rendering from the first clip",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "Duration",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "Codec",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "Frame Rate",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "Resolution",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "Audio",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "Size (approximate)",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "Link to transcoding file will be lost",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "Publish settings",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "Video Render Complete",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "Enter name",
    "SAVE_SETTINGS_BTN_RENDER_START": "Start",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "Start",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "Back to My Account",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "Back to My Account",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "Cancel",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "Cancel",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "Download",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "Download",
    "SAVE_SETTINGS_BTN_BROWSE": "Browse",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "Browse",
    "SAVE_SETTINGS_BTN_COPY": "Copy",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "Copy",
    "SAVE_SETTINGS_TEXT_TITLE": "Title",
    "SAVE_SETTINGS_TEXT_LOADING": "Loading",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "This video has been submitted to your account and should be available soon.",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "All",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "Search",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "Description",
    "SETTINGS_HEAD_BTN_DELETE": "Delete",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "Delete",
    "SETTINGS_HEAD_BTN_CLOSE": "Close",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "Close",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "Width",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "Height",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "Keep the aspect ratio",
    "SETTINGS_BTN_FIT_TO_FRAME": "Fit to frame",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "Fit to frame",
    "SETTINGS_BTN_FILL_FRAME": "Fill frame",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "Fill frame",
    "SETTINGS_TEXT_AUTO": "auto",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "Text transform none",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "Text transform none",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "Text transform uppercase",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "Text transform uppercase",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "Text transform lowercase",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "Text transform lowercase",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "Text transform capitalize",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "Text transform capitalize",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "Font family",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "Font family",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "Font siz",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "Font size",
    "SETTINGS_TEXT_BTN_BOLD": "Bold",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "Bold",
    "SETTINGS_TEXT_BTN_ITALIC": "Italic",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "Italic",
    "SETTINGS_TEXT_BTN_UNDERLINE": "Underline",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "Underline",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "Left",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "Left",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "Right",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "Right",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "Center",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "Center",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "Justify",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "Justify",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "Top",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "Top",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "Top left",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "Top left",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "Top Right",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "Top Right",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "Left",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "Left",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "Middle center",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "Middle center",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "Right",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "Right",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "Bottom left",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "Bottom left",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "Bottom",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "Bottom",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "Bottom right",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "Bottom right",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "Horizontal",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "Vertical",
    "SETTINGS_ROTATION_TEXT_ROTATE": "Rotate",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "Enter text",
    "NABLET_HLD_PROJECT_POSTFIX": "highlights",
    "NABLET_HLD_CAPTION": "Detect highlights",
    "NABLET_HLD_DETECTED_BTN_START": "Start",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "Create project(s)",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "Cancel",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "Detected intervals count:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "Are you sure you want terminate task?",
    "NABLET_HLD_DETECTED_BTN_NO": "No",
    "NABLET_HLD_DETECTED_BTN_YES": "Yes",
    "NB_HLD_STATUS_TEXT_LOADING": "Loading",
    "NB_HLD_STATUS_TEXT_DETECTING": "Detecting",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "Downloading media",
    "NB_HLD_STATUS_TEXT_ABORTED": "Aborted",
    "NABLET_HLD_TARGET_DURATION_SEC": "Target duration seconds",
    "NABLET_HLD_ONNX_MODEL": "Onnx model",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "This section is in development",
    "TEXT_FORM_CAPTION_SIZE": "Size",
    "TEXT_FORM_CAPTION_OUTLINE": "Outline",
    "TEXT_FORM_CAPTION_FONT": "Font",
    "TEXT_FORM_CAPTION_SHADOW": "Shadow",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "Text settings",
    "TEXT_FORM_TEXT_OFFSET": "Offset",
    "TEXT_FORM_TEXT_BLUR": "Blur",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "Text",
    "TIMELINE_TEXT_DRAG_N_DROP": "Drag & Drop",
    "TIMELINE_TEXT_ADD_LAYER": "+ Add Layer",
    "TIMELINE_TOOLBAR_BTN_UNDO": "Undo",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "Undo",
    "TIMELINE_TOOLBAR_BTN_REDO": "Redo",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "Redo",
    "TIMELINE_TOOLBAR_BTN_DELETE": "Delete",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "Delete",
    "TIMELINE_TOOLBAR_BTN_COPY": "Copy clip",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "Copy clip",
    "TIMELINE_TOOLBAR_BTN_CUT": "Cut clip(s)",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "Cut clip(s)",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "Add Marker",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "Add Marker",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "Paste",
    "TIMELINE_TOOLBAR_BTN_PASTE": "Paste",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "Settings",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "Settings",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "Voice Over",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "Voice Over",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "Web camera",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "Web camera",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "Click to cancel",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "Click to cancel",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "Drag & Drop",
    "TIMELINE_FOOTER_TEXT_DURATION": "Duration",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "Unable to publish project",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "Video track is required",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "Save",
    "TIMELINE_FOOTER_BTN_SAVE": "Save",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "Save",
    "TIMELINE_FOOTER_BTN_PUBLISH": "Publish",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "Publish",
    "TIMELINE_SLIDER_POINTER": "Slider pointer",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "Slider pointer",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "Transition settings",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "Apply to all transitions from the timeline",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "Transition duration:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "Transition",
    "used_in_projects": "Media file was not deleted because it is in use in one or more projects. If you continue the file will not be available on timeline in corresponding projects and deleted from your account.",
    "VOICE_OVER_TITLE_LAST_RECORDING": "Last recording",
    "VOICE_OVER_BTN_RETAKE": "Retake",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "Retake",
    "VOICE_OVER_BTN_DELETE_FILE": "Delete file",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "Delete file",
    "VOICE_OVER_BTN_START_RECORDING": "Start recording",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "Start recording",
    "VOICE_OVER_BTN_CONNECTION": "Connection...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "Connection...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "The file will be deleted from the timeline and media.",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "Are you sure?",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "Voice Narration",
    "VOICE_OVER_TEXT_MIC": "Microphone",
    "VOICE_OVER_LABEL_SELECT_MIC": "Select microphone",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "No audio",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "Mute timeline while recording",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "Rewind slider at the start after record",
    "VOICE_OVER_TEXT_COUNTDOWN": "Countdown",
    "VOICE_OVER_BTN_TEST_MIC": "Test Mic",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "Test Mic",
    "VOICE_OVER_BTN_RECORDING": "Recording...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "Recording...",
    "VOICE_OVER_BTN_PLAYING": "Playing...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "Playing...",
    "VOICE_OVER_BTN_YES": "Yes",
    "VOICE_OVER_BTN_YES_TOOLTIP": "Yes",
    "VOICE_OVER_BTN_NO": "No",
    "VOICE_OVER_BTN_NO_TOOLTIP": "No",
    "VOICE_OVER_BTN_STOP": "Stop",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "Stop",
    "VOICE_OVER_BTN_DELETE": "Delete",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "Delete",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "Auto adjusting",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "Other settings",
    "WEBCAM_TEXT_RECORD_VIDEO": "Record video",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "Decrease zoom",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "Decrease zoom",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "Increase zoom",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "Increase zoom",
    "SETTINGS_HOTKEYS": "Hotkeys settings",
    "PRESET": "Preset",
    "DEFAULT": "Default",
    "ADD_NEW": "Add New",
    "SEARCH_COMMAND": "Search command",
    "COMMAND": "Command",
    "SHORTCUTS": "Shortcuts",
    "ALL": 'All',
    "TIMELINE": "Timeline",
    "TOOLS": "Tools",
    "PREVIEW": "Preview",
    "KEYBOARD_MAPPING_PRESET": "Keyboard Mapping Preset",
    "ENTER_PRESET_NAME": "Enter preset name",
    "THIS_PRESET_WILL_BE_DELETED": "This preset will be deleted immediately. You can’t undo this action.",
    "RESET_ALL_TO_DEFAULTS": 'Reset all to defaults',
    "HOTKEY_SETTINGS_UNDO": 'Undo',
    "HOTKEY_SETTINGS_REDO": 'Redo',
    "HOTKEY_SETTINGS_ZOOM_IN": 'Zoom in',
    "HOTKEY_SETTINGS_ZOOM_OUT": 'Zoom out',
    "HOTKEY_SETTINGS_PREV_FRAME": 'Previous frame',
    "HOTKEY_SETTINGS_NEXT_FRAME": 'Next frame',
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": 'Add to timeline',
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": 'Increase clip volume',
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": 'Decrease clip volume',
    "HOTKEY_SETTINGS_RIPPLE_DELETE": 'Ripple delete',
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": 'Ripple trim previous',
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": 'Ripple trim next',
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": 'Select clips at current position',
    "HOTKEY_SETTINGS_CUT_CLIPS": 'Cut clip(s)',
    "HOTKEY_SETTINGS_PLAY_PAUSE": 'Play/Pause',
    "HOTKEY_SETTINGS_DELETE": 'Delete',
    "HOTKEY_SETTINGS_COPY": 'Copy',
    "HOTKEY_SETTINGS_PASTE": 'Paste',
    "HOTKEY_SETTINGS_PLAY": "Play",
    "HOTKEY_SETTINGS_PAUSE": "Pause",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "Play the highlighted interval",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "Clear in out points",
    "HOTKEY_SETTINGS_MARK_IN": "Mark in point",
    "HOTKEY_SETTINGS_MARK_OUT": "Mark out point",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "Seek to in point",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "Seek to out point",
    "CLIP_SETTINGS": "Clip settings",
    "MESSAGE_BOX_REMOVE_EXISTING": "The files are not compatible. Please remove existing",
    "MARKS_CONTEXT_MENU_MARK_IN": "Mark In",
    "MARKS_CONTEXT_MENU_MARK_OUT": "Mark Out",
    "MARKS_CONTEXT_MENU_CLEAR": "Clear in/out points",
    "MEDIA_ERROR_build_thumbnails_error": "Build thumbnails error",
    "MEDIA_ERROR_unsupported_media": "Unsupported media",
    "MEDIA_ERROR_exceeded_file_size": "Exceeded file size",
    "MEDIA_ERROR_exceeded_per_user_size": "Exceeded per user size",
    "MEDIA_ERROR_low_disk_space": "Low disk space",
    "MEDIA_ERROR_internal_server_error": "Internal server error",
    "MEDIA_ERROR_network_error": "Network error",
    "MEDIA_ERROR_not_found": "Media file not found",
  },
  ja: {
    "ADD_FOLDER_BTN": "フォルダの追加",
    "ADD_FOLDER_BTN_TOOLTIP": "フォルダの追加",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "バケット名",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "アクセスキーID",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "シークレットキー",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "インポート中",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "インポートエラー",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "インポートに成功しました",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "AWSバケットからメディアをインポート",
    "AMAZON_IMPORT_BTN_IMPORT": "インポート",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "インポート",
    "AUDIO_CAPTION_AUDIO": "オーディオ",
    "AUDIO_TEXT_FOUND_ITEMS": "見つかった項目",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "結果は見つかりませんでした",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "検索結果が見つかりません",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "オーディオ設定",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "サウンド",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "トランジション",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "フェードイン",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "フェードアウト",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "検索ジャンル",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "検索音楽",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "検索効果音",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "で検索",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "ジャンル別音楽",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "サウンドエフェクト",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "ムード別の音楽",
    "AUDIO_MENU_ITEM_ALL": "すべて",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "タイムラインからアセットを削除しますか？",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "メディアに応じてタイムコードフォーマットを設定",
    "COLOR_PICKER_BTN_COLOR": "色を選択",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "色を選択",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "背景色",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "背景色",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "メディアファイルをダウンロードしています",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "保存メディアをアップロード中",
    "COMMON_STATUS_TEXT_ABORTED": "中止",
    "COMMON_STATUS_TEXT_LOADING": "ロード中",
    "COMMON_STATUS_TEXT_RENDERING": "エンコード中",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "キャンセル",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "キャンセル",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "ユーザーアバター",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "デフォルトのユーザーアバターファイルをアップロードできます",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "アバター",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "カラーテーマ",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "カスタムJSスクリプト",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "カスタムテキスト",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "エディターのテキストをカスタマイズできます",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "このスクリプトはエディタのロード時に実行されます。アナリティクスなどを統合するために使用できます",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "JSスクリプトの編集",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "第二アクセントカラー",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "メイン背景の色を選択します",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "コントロールのメインアクセントカラー。ここでブランドカラーを選択してください",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "ボタンのメインカラーと対になるコントロールの追加カラー",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "タイムライン上のオーディオファイルには独自のスタイルがあります",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "ロゴのファイルをアップロードできます",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "ナレーションファイルには独自のスタイルがあります",
    "CUSTOMIZATION_LABEL_DISPLAYED": "表示",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "定義済みのテーマの一つを選択するか独自のテーマを作成してください。CSSファイルを編集してテーマをカスタマイズできます。",
    "CUSTOMIZATION_TITLE_BASIC_BG": "基本背景",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "プライマリボタン、リンク",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "テーマのCSSファイルを編集する",
    "CUSTOMIZATION_TITLE_AUDIO": "オーディオ",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "ボイスオーバー",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "テキスト値の編集",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "テキスト値の編集",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "テキスト値の編集",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "フォーマットの説明",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "フォーマットの説明",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "すべての変更をリセット",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "すべての変更をリセット",
    "CUSTOMIZATION_BTN_CANCEL": "キャンセル",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "キャンセル",
    "CUSTOMIZATION_BTN_SAVE": "保存",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "保存",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "JSスクリプトの編集",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "JSスクリプトの編集",
    "CUSTOMIZATION_BTN_EDIT_CSS": "CSSの編集",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "CSSの編集",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "ロゴのアップロード",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "ロゴのアップロード",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "ロゴの削除",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "ロゴの削除",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "ダークテーマ",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "ライトテーマ",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "カスタムテーマ",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "ロゴ",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "ロゴ",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "エディタのカスタマイズ",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "ロゴと名前",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "会社名",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "エディター名",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "受け付けるファイル:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "アップロードされたロゴはありません",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "ビュー設定",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "表示設定",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "フィルタ",
    "DUMMY_HEADER_BTN_FILTERS": "フィルタ",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "プロジェクトを別名で保存",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "新規プロジェクト",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "書き出し",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "エディタのカスタマイズ",
    "EDITOR_MENU_MAIN_LOGS": "過去ログ",
    "EDITOR_MENU_MAIN_SETTINGS": "設定",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "公開できません",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "トラックは必須です",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "レンダリング設定",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "行動",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "保存",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "キャンセル",
    "FILE_INPUT_BTN": "ファイル入力",
    "FILE_INPUT_BTN_TOOLTIP": "ファイル入力",
    "FORM_FIELD_LABEL_VOLUME": "ボリューム",
    "folder_not_empty": "フォルダは空ではありません。ファイルはプロジェクトで使用することができます。このままだと、対応するプロジェクトのタイムラインでファイルが利用できなくなります。",
    "HEAD_CONTROL_IMPORT_TEXT": "インポート",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "メディアのインポート",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "メディア付きフォルダのインポート",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3バケット",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Googleドライブ",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL入力",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "プロジェクトを別名で保存",
    "HEADER_MENU_LIST_ITEM_DELETE": "削除",
    "HEADER_MENU_LIST_ITEM_RENAME": "名前の変更",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "ライセンスキーの追加",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "ログアウト",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "エディターから離れますか？",
    "HEADER_BTN_BACK_TO_MY_ACC": "マイアカウントに戻る",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "マイアカウントに戻る",
    "HEADER_BTN_HELP": "ヘルプ",
    "HEADER_BTN_HELP_TOOLTIP": "ヘルプ",
    "IMAGES_TEXT_NATIVE_IMAGES": "ネイティブ画像",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "画像",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "で検索",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "コレクションを選択",
    "IMAGES_TEXT_ALL_CATEGORIES": "すべてのコレクション",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "結果は見つかりませんでした",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "検索結果が見つかりません",
    "IMAGES_TAB_AI_GENERATED": "AI生成",
    "IMAGES_TAB_SEARCH": "検索",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "URLからメディアをインポート",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "ファイルへのリンクはこちら",
    "IMPORT_FROM_URL_BTN_IMPORT": "インポート",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "インポート",
    "LAYER_CONTROL_BTN_MOVE_UP": "上へ移動",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "上へ移動",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "ドラッグレイヤー",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "レイヤーをドラッグ",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "下に移動",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "下に移動",
    "LAYER_CONTROL_BTN_DELETE": "削除",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "削除",
    "LAYER_CONTROL_BTN_HIDE": "隠す",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "隠す",
    "LAYER_CONTROL_BTN_SHOW": "表示",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "表示",
    "LAYER_CONTROL_BTN_MUTE": "ミュート",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "ミュート",
    "LAYER_CONTROL_BTN_UNMUTE": "ミュート解除",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "ミュート解除",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "マイメディア",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "マイメディア",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "トランジション",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "トランジション",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "画像",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "画像",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "オーディオ",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "オーディオ",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "テキスト",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "テキスト",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "デザイン",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "デザイン",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "フィルター",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "フィルター",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "フィルター",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "画像設定",
    "MEDIA_FORM_CAPTION_POSITION": "位置",
    "MEDIA_FORM_CAPTION_ROTATION": "回転",
    "MEDIA_FORM_CAPTION_SETTINGS": "設定",
    "MEDIA_FORM_CAPTION_OUTLINE": "アウトライン",
    "MEDIA_FORM_CAPTION_SHADOW": "シャドウ",
    "MEDIA_FORM_CAPTION_SIZE": "サイズ",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "不透明度",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "フィルタ",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "フィルタ",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "すべて",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "ビデオ",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "イメージ",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "オーディオ",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "フォルダ",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "ナレーション",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "表示設定",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "ビューの設定",
    "MEDIA_UPLOADER_TEXT_OR": "または",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "ここにメディアをドラッグ＆ドロップしてください",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "インポートメディア",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "インポート",
    "MEDIA_PREVIEW_TEXT_RENDERING": "エンコード中",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "解像度",
    "MEDIA_INFO_LIST_ITEM_CODEC": "コーデック",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "フレームレート",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - ステレオ",
    "MEDIA_INFO_LIST_ITEM_DURATION": "デュレーション",
    "MEDIA_INFO_LIST_ITEM_TYPE": "タイプ",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "オーディオ",
    "MEDIA_INFO_LIST_ITEM_SIZE": "サイズ",
    "MEDIA_INFO_LIST_TASK_STATUS": "タスクのステータス",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "スタート・タイムコード",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "メディア",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "より多くのメディアを読み込む",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "より多くのメディアを読み込む",
    "MEDIA_BTN_LOADING": "ロード中",
    "MEDIA_BTN_LOADING_TOOLTIP": "ロード中",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "クロマキー",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "位置",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "音",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "SIZE",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "メディア設定",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "すべての設定をリセットする",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "すべての設定をリセット",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "類似度",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "滑らかさ",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "キーカラーの流出削減",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "プレビューから色を選ぶ",
    "MEDIA_SORT_OPTION_DURATION": "デュレーション",
    "MEDIA_SORT_OPTION_DATE": "日付",
    "MEDIA_SORT_OPTION_SIZE": "サイズ",
    "MEDIA_SORT_OPTION_NAME": "名前",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "ソート順",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "順序",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "ビュー",
    "MEDIA_SORT_OPTION_ASCENDING": "昇順",
    "MEDIA_SORT_OPTION_DESCENDING": "降順",
    "MEDIA_SORT_OPTION_LIST": "リスト",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "大きなアイコン",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "私の録音",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "私の記録",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "マイメディア",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "マイメディア",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "選択された項目を本当に削除しますか？",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "フォルダを参照",
    "MEDIA_CONFIRM_BTN_DELETE": "削除",
    "MEDIA_CANCEL_BTN_DELETE": "キャンセル",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "削除",
    "MEDIA_CONFIRM_TITLE_DELETE": "削除",
    "MEDIA_TEXT_LOADING": "読み込み中",
    "MEDIA_BTN_ADD": "追加",
    "MEDIA_BTN_ADD_TOOLTIP": "追加",
    "MEDIA_PROGRESS_TEXT_LOADING": "ロード中",
    "MEDIA_PROGRESS_TEXT_SAVING": "保存",
    "MEDIA_PROGRESS_TEXT_CONVERT": "変換",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "転送",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "分析",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "フォルダの選択",
    "MEDIA_MENU_ITEM_MEDIA": "メディア",
    "MEDIA_MENU_ITEM_MOVE_TO": "移動先",
    "MEDIA_MENU_ITEM_SETTINGS": "設定",
    "MEDIA_MENU_ITEM_NABLET_HLD": "ハイライトを検出",
    "MEDIA_MENU_ITEM_DELETE": "削除",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "メディアを見る",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "コピーメディアのアドレス",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "開始タイムコードを使ってタイムラインに追加する",
    "MEDIA_MENU_LABEL_MOVE_TO": "移動先",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5動画エディター",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "タブレットとモバイルはまだサポートされていません :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "ビデオエディターはパソコンでも使えます",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "ウェブサイトに戻る",
    "MODAL_WINDOW_LOGS_TITLE": "ログをオン/オフに切り替えるには、アプリケーションを再起動する必要があります",
    "MODAL_WINDOW_BUTTON_RESTART": "リスタート",
    "NAVIGATION_TAB_EDITOR": "エディット",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "エディット",
    "NAVIGATION_TAB_PROJECTS": "プロジェクト",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "プロジェクト",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "接続が回復するまで編集はできません",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "最終保存日",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "分前",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "ちょうど今",
    "PROJECTS_BTN_NEW_PROJECTS": "新しいプロジェクト",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "新規プロジェクト",
    "PROJECTS_BTN_NEW_PROJECT": "新しいプロジェクト",
    "PROJECTS_BTN_IMPORT": "インポート",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "インポート",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "はい",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "キャンセル",
    "PROJECTS_TITLE_TEXT_COPY": "コピー",
    "PROJECTS_BTN_SAVE": "保存",
    "PROJECTS_BTN_SAVE_TOOLTIP": "保存",
    "PROJECTS_TITLE_TEXT_RENAME": "リネーム",
    "PROJECTS_TITLE_TEXT_DELETE": "削除",
    "PROJECTS_TITLE_TEXT_EXPORT": "エクスポート",
    "PROJECTS_CONFIRM_BTN_DELETE": "削除",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "%d時間前に編集",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "編集中",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "%d分前に編集",
    "PROJECTS_PROGRESS_TEXT_LOADING": "ロード中",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "検索",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "インポートを停止しますか？",
    "PROJECTS_TEXT_NOTHING_FOUND": "何も見つかりません",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "美しい新規プロジェクトを作成する",
    "PROJECTS_TEXT_CAPTION": "プロジェクト",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "プロジェクト名",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "クリックしてアップロードするか、ここにプロジェクトファイルをドラッグ＆ドロップしてください",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "一度にアップロードできるファイルは1つだけです",
    "PROJECTS_PREFIX_TEXT_EDITED": "編集済み",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "本当に削除しますか？",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "このプロジェクトは直ちに削除されます。元に戻すことはできません。",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "プロジェクト",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "アップロードできるファイルは1つだけです",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "無効なファイル拡張子です。.edl.zip でなければなりません",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "値を入力してください",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "このプロジェクトのフォルダをメディアに作成する",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "エディターを開く",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "再試行",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "プロジェクトのフレームレートを選択",
    "PROJECTS_EXPORT_TITLE": "プロジェクトのエクスポート",
    "PROJECTS_EXPORT_INPROGRESS": "エクスポート進行中...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "メディアを含む",
    "PROJECTS_EXPORT_COMPLETE": "プロジェクトのエクスポートが完了しました",
    "PROJECTS_EXPORT_BTN_BROWSE": "参照",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "ダウンロード",
    "PROJECTS_EXPORT_BTN_COPY": "コピー",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "メディアからのファイルはここで再生されます",
    "PREVIEW_BTN_PREV_TOOLTIP": "前",
    "PREVIEW_BTN_PREV": "前",
    "PREVIEW_BTN_PLAY_TOOLTIP": "再生",
    "PREVIEW_BTN_PLAY": "再生",
    "PREVIEW_BTN_STOP_TOOLTIP": "停止",
    "PREVIEW_BTN_STOP": "停止",
    "PREVIEW_BTN_NEXT_TOOLTIP": "次へ",
    "PREVIEW_BTN_NEXT": "次へ",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "ボリューム",
    "PREVIEW_BTN_VOLUME": "ボリューム",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "イン点",
    "PREVIEW_BTN_MARK_IN": "イン点",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "アウト点",
    "PREVIEW_BTN_MARK_OUT": "アウト点",
    "PREVIEW_BTN_FULL_SCREEN": "フルスクリーン",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "フルスクリーン",
    "PREVIEW_HEADER_TITLE_SOURCE": "ソース",
    "PREVIEW_HEADER_TITLE_TIMELINE": "タイムライン",
    "PREVIEW_MODE_AUTO": "オート",
    "PREVIEW_MODE_SOURCE": "ソース",
    "PREVIEW_MODE_TIMELINE": "タイムライン",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "出力",
    "PUBLISH_SETTINGS_TITLE": "タイトル",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "最初のクリップからレンダリングを開始する",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "デュレーション",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "コーデック",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "フレームレート",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - ステレオ",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "解像度",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "オーディオ",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "サイズ（概算）",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "トランスコード・ファイルへのリンクが失われます",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "ファイルインサート",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "ファイルインサート完了",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "名前を入力",
    "SAVE_SETTINGS_BTN_RENDER_START": "開始",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "開始",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "マイアカウントに戻る",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "マイアカウントに戻る",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "キャンセル",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "キャンセル",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "ダウンロード",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "ダウンロード",
    "SAVE_SETTINGS_BTN_BROWSE": "ブラウズ",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "ブラウズ",
    "SAVE_SETTINGS_BTN_COPY": "コピー",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "コピー",
    "SAVE_SETTINGS_TEXT_TITLE": "タイトル",
    "SAVE_SETTINGS_TEXT_LOADING": "読み込み中",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "このビデオはあなたのアカウントに送信されました",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "すべて",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "検索",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "説明",
    "SETTINGS_HEAD_BTN_DELETE": "削除",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "削除",
    "SETTINGS_HEAD_BTN_CLOSE": "閉じる",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "閉じる",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "幅",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "高さ",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "アスペクト比を維持",
    "SETTINGS_BTN_FIT_TO_FRAME": "フレームに合わせる",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "フレームに合わせる",
    "SETTINGS_BTN_FILL_FRAME": "塗りつぶし枠",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "塗りつぶし枠",
    "SETTINGS_TEXT_AUTO": "自動",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "テキスト変換なし",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "テキスト変換なし",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "テキストの大文字変換",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "テキスト変換大文字",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "テキスト変換小文字",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "テキスト変換小文字",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "テキストを大文字に変換する",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "テキストを大文字に変換する",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "フォントファミリー",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "フォントファミリー",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "フォントサイズ",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "フォントサイズ",
    "SETTINGS_TEXT_BTN_BOLD": "太字",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "太字",
    "SETTINGS_TEXT_BTN_ITALIC": "イタリック",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "イタリック",
    "SETTINGS_TEXT_BTN_UNDERLINE": "下線",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "下線",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "左",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "左",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "右",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "右",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "中央",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "中央",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "ジャスティファイ",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "ジャスティファイ",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "トップ",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "トップ",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "左上",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "左上",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "右上",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "右上",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "左",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "左",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "中央",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "中央",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "右",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "右",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "左下",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "左下",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "下",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "下",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "下",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "右下",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "水平",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "垂直",
    "SETTINGS_ROTATION_TEXT_ROTATE": "回転",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "テキストを入力",
    "NABLET_HLD_PROJECT_POSTFIX": "ハイライト",
    "NABLET_HLD_CAPTION": "ハイライトを検出",
    "NABLET_HLD_DETECTED_BTN_START": "開始",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "プロジェクトを作成",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "キャンセル",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "検出されたインターバルの数:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "タスクを終了してもよろしいですか？",
    "NABLET_HLD_DETECTED_BTN_NO": "いいえ",
    "NABLET_HLD_DETECTED_BTN_YES": "はい",
    "NB_HLD_STATUS_TEXT_LOADING": "読み込み中",
    "NB_HLD_STATUS_TEXT_DETECTING": "検出中",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "メディアをダウンロード中",
    "NB_HLD_STATUS_TEXT_ABORTED": "中止されました",
    "NABLET_HLD_TARGET_DURATION_SEC": "目標の秒数",
    "NABLET_HLD_ONNX_MODEL": "Onnxモデル",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "このセクションは開発中です",
    "TEXT_FORM_CAPTION_SIZE": "サイズ",
    "TEXT_FORM_CAPTION_OUTLINE": "アウトライン",
    "TEXT_FORM_CAPTION_FONT": "フォント",
    "TEXT_FORM_CAPTION_SHADOW": "影",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "テキスト設定",
    "TEXT_FORM_TEXT_OFFSET": "オフセット",
    "TEXT_FORM_TEXT_BLUR": "ぼかし",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "テキスト",
    "TIMELINE_TEXT_DRAG_N_DROP": "ドラッグ＆ドロップ",
    "TIMELINE_TEXT_ADD_LAYER": "レイヤーを追加",
    "TIMELINE_TOOLBAR_BTN_UNDO": "元に戻す",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "元に戻す",
    "TIMELINE_TOOLBAR_BTN_REDO": "やり直し",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "やり直し",
    "TIMELINE_TOOLBAR_BTN_DELETE": "削除",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "削除",
    "TIMELINE_TOOLBAR_BTN_COPY": "コピークリップ",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "クリップをコピー",
    "TIMELINE_TOOLBAR_BTN_CUT": "クリップをカット",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "クリップをカット",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "マーカーを追加",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "マーカーを追加",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "貼り付け",
    "TIMELINE_TOOLBAR_BTN_PASTE": "貼り付け",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "設定",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "設定",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "ボイスオーバー",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "ボイスオーバー",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "ウェブカメラ",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "ウェブカメラ",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "クリックしてキャンセル",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "クリックしてキャンセル",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "ドラッグ＆ドロップ",
    "TIMELINE_FOOTER_TEXT_DURATION": "デュレーション",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "プロジェクトを公開できません",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "ビデオトラックが必要です",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "保存",
    "TIMELINE_FOOTER_BTN_SAVE": "インサート",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "インサート",
    "TIMELINE_FOOTER_BTN_PUBLISH": "公開",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "公開",
    "TIMELINE_SLIDER_POINTER": "スライダーのポインター",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "スライダーのポインター",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "トランジション設定",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "タイムラインから全てのトランジションに適用する",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "移行デュレーション:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "トランジション",
    "used_in_projects": "メディアファイルが削除されませんでした。続行すると、ファイルは対応するプロジェクトのタイムラインで使用できなくなり、アカウントから削除されます。",
    "VOICE_OVER_TITLE_LAST_RECORDING": "最後の録音",
    "VOICE_OVER_BTN_RETAKE": "リテイク",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "リテイク",
    "VOICE_OVER_BTN_DELETE_FILE": "ファイルを削除",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "ファイルを削除",
    "VOICE_OVER_BTN_START_RECORDING": "録音開始",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "録音開始",
    "VOICE_OVER_BTN_CONNECTION": "接続...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "接続...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "このファイルはタイムラインとメディアから削除されます",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "よろしいですか？",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "音声ナレーション",
    "VOICE_OVER_TEXT_MIC": "マイク",
    "VOICE_OVER_LABEL_SELECT_MIC": "マイクを選択",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "音声なし",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "録音中のタイムラインのミュート",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "録音後の巻き戻しスライダー",
    "VOICE_OVER_TEXT_COUNTDOWN": "カウントダウン",
    "VOICE_OVER_BTN_TEST_MIC": "テストマイク",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "テストマイク",
    "VOICE_OVER_BTN_RECORDING": "録音...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "録音...",
    "VOICE_OVER_BTN_PLAYING": "再生中...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "再生中...",
    "VOICE_OVER_BTN_YES": "はい",
    "VOICE_OVER_BTN_YES_TOOLTIP": "はい",
    "VOICE_OVER_BTN_NO": "いいえ",
    "VOICE_OVER_BTN_NO_TOOLTIP": "いいえ",
    "VOICE_OVER_BTN_STOP": "停止",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "停止",
    "VOICE_OVER_BTN_DELETE": "削除",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "削除",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "自動調整",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "その他の設定",
    "WEBCAM_TEXT_RECORD_VIDEO": "ビデオを録画する",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "ズームを減らす",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "ズームを減らす",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "ズームを増やす",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "ズームを増やす",
    "SETTINGS_HOTKEYS": "ショートカットキー",
    "PRESET": "プリセット",
    "DEFAULT": "デフォルト",
    "ADD_NEW": "新規追加",
    "SEARCH_COMMAND": "検索コマンド",
    "COMMAND": "コマンド",
    "SHORTCUTS": "ショートカット",
    "ALL": "すべて",
    "TIMELINE": "タイムライン",
    "TOOLS": "ツール",
    "PREVIEW": "プレビュー",
    "KEYBOARD_MAPPING_PRESET": "キーボードマッピングプリセット",
    "ENTER_PRESET_NAME": "プリセット名を入力",
    "THIS_PRESET_WILL_BE_DELETED": "このプリセットは直ちに削除されます。元に戻すことはできません。",
    "RESET_ALL_TO_DEFAULTS": "すべてをデフォルトに戻す",
    "HOTKEY_SETTINGS_UNDO": "元に戻す",
    "HOTKEY_SETTINGS_REDO": "やり直し",
    "HOTKEY_SETTINGS_ZOOM_IN": "ズームイン",
    "HOTKEY_SETTINGS_ZOOM_OUT": "ズームアウト",
    "HOTKEY_SETTINGS_PREV_FRAME": "前のフレーム",
    "HOTKEY_SETTINGS_NEXT_FRAME": "次のフレーム",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "タイムラインに追加",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "クリップの音量を上げる",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "クリップの音量を下げる",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "リップル削除",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "リップル・トリムの前",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "リップルトリムネクスト",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "現在の位置でクリップを選択",
    "HOTKEY_SETTINGS_CUT_CLIPS": "クリップをカット",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "再生/一時停止",
    "HOTKEY_SETTINGS_DELETE": "削除",
    "HOTKEY_SETTINGS_COPY": "コピー",
    "HOTKEY_SETTINGS_PASTE": "貼り付け",
    "HOTKEY_SETTINGS_PLAY": "プレー",
    "HOTKEY_SETTINGS_PAUSE": "一時停止",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "ハイライトされた区間を再生する",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "明確なインアウト・ポイント",
    "HOTKEY_SETTINGS_MARK_IN": "マークインポイント",
    "HOTKEY_SETTINGS_MARK_OUT": "マークアウトポイント",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "シーク・トゥ・イン・ポイント",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "アウトポイントへ",
    "CLIP_SETTINGS": "クリップ設定",
    "MESSAGE_BOX_REMOVE_EXISTING": "ファイル形式が一致しません、互換性のあるファイルを追加してください。",
    "MARKS_CONTEXT_MENU_MARK_IN": "イン点",
    "MARKS_CONTEXT_MENU_MARK_OUT": "アウト点",
    "MARKS_CONTEXT_MENU_CLEAR": "イン点/アウト点を削除",
    "MEDIA_ERROR_build_thumbnails_error": "サムネイル作成エラー",
    "MEDIA_ERROR_unsupported_media": "サポートされていないメディア",
    "MEDIA_ERROR_exceeded_file_size": "ファイルサイズが超過",
    "MEDIA_ERROR_exceeded_per_user_size": "ユーザーあたりのサイズが超過",
    "MEDIA_ERROR_low_disk_space": "ディスク容量不足",
    "MEDIA_ERROR_internal_server_error": "内部サーバーエラー",
    "MEDIA_ERROR_network_error": "ネットワークエラー",
    "MEDIA_ERROR_not_found": "メディアファイルが見つかりません",
  },
  bz: {
    "ADD_FOLDER_BTN": "Adicionar pasta",
    "ADD_FOLDER_BTN_TOOLTIP": "Adicionar pasta",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Nome do Bucket",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "ID da chave de acesso",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "Chave secreta",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "Importando",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "Erro de importação",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "Importado com sucesso",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "Importar mídia do AWS Bucket",
    "AMAZON_IMPORT_BTN_IMPORT": "Importar",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "Importar",
    "AUDIO_CAPTION_AUDIO": "Áudio",
    "AUDIO_TEXT_FOUND_ITEMS": "Itens encontrados",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "Nenhum resultado encontrado",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "Parece que não conseguimos encontrar nenhum resultado com base em sua pesquisa.",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "Configurações de áudio",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "Som",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "Transição",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "Fade in",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "Fade out",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "Pesquisar gênero",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "Pesquisar música",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "Pesquisar efeitos sonoros",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "Pesquisar em",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "Música por gênero",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "Efeitos sonoros",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "Música por estado de espírito",
    "AUDIO_MENU_ITEM_ALL": "Todos",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "Remover ativo da linha do tempo?",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "Definir o formato de timecode com base na mídia",
    "COLOR_PICKER_BTN_COLOR": "Selecionar cor",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "Selecionar cor",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "Cor de fundo",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "Cor de fundo",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "Download de arquivos de mídia",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "Upload de mídia salva",
    "COMMON_STATUS_TEXT_ABORTED": "Abortado",
    "COMMON_STATUS_TEXT_LOADING": "Carregando",
    "COMMON_STATUS_TEXT_RENDERING": "Renderização",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "Cancelar",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "Cancelar",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "Avatar do usuário",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "Você pode carregar um arquivo do avatar padrão do usuário.",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "Avatar",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "Tema de cor",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "Script JS personalizado",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "Texto personalizado",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "Você pode personalizar o texto do editor",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "Este script é executado no carregamento do editor. Pode ser usado para integrar análises etc.",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "Editar script JS",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "Segunda cor de destaque",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "Selecione a cor do plano de fundo principal",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "A cor de destaque principal para os controles. Selecione a cor de sua marca aqui.",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "Cor adicional dos controles em conjunto com a cor principal dos botões.",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "O arquivo de áudio na linha do tempo tem seu próprio estilo.",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "Você pode carregar um arquivo de seu logotipo.",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "O arquivo de narração tem seu próprio estilo",
    "CUSTOMIZATION_LABEL_DISPLAYED": "exibido",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "Escolha um dos temas predefinidos ou crie o seu próprio. Você pode editar o arquivo CSS para personalizar seu tema.",
    "CUSTOMIZATION_TITLE_BASIC_BG": "Fundo básico",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "Botões primários, links",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "Editar arquivo CSS para o tema",
    "CUSTOMIZATION_TITLE_AUDIO": "Áudio",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "Voice over",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "Editar valores de texto",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "Editar valores de texto",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "Editar valores de texto",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "Descrição do formato",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "Descrição do formato",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "Redefinir todas as alterações",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "Redefinir todas as alterações",
    "CUSTOMIZATION_BTN_CANCEL": "Cancelar",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "Cancelar",
    "CUSTOMIZATION_BTN_SAVE": "Salvar",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "Salvar",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "Editar script JS",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "Editar script JS",
    "CUSTOMIZATION_BTN_EDIT_CSS": "Editar CSS",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "Editar CSS",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "Carregar logotipo",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "Carregar logotipo",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "Excluir logotipo",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "Excluir logotipo",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "Tema escuro",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "Tema leve",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "Tema personalizado",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "Logo",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "Logotipo",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "Personalizar editor",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "Logotipo e nome",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "Nome da empresa",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "Nome do editor",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "Arquivos aceitos:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "Nenhum logotipo carregado",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "Exibir configurações",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "Configurações de visualização",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "Filtros",
    "DUMMY_HEADER_BTN_FILTERS": "Filtros",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Salvar projeto como...",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "Novo projeto",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "Publicar",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "Personalizar editor",
    "EDITOR_MENU_MAIN_LOGS": "Registros",
    "EDITOR_MENU_MAIN_SETTINGS": "Configurações",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "Não foi possível publicar",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "A trilha é obrigatória",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "Configurações de renderização",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "Comportamento",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "Salvar",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "Cancelar",
    "FILE_INPUT_BTN": "Entrada de arquivo",
    "FILE_INPUT_BTN_TOOLTIP": "Entrada de arquivo",
    "FORM_FIELD_LABEL_VOLUME": "Volume",
    "folder_not_empty": "The folder is not empty. The files can be used in projects. If you continue the file will not be available on timeline of the corresponding projects.",
    "HEAD_CONTROL_IMPORT_TEXT": "Importar",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "Importar mídia",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "Importar pasta com mídia",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "Entrada de URL",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Salvar projeto como...",
    "HEADER_MENU_LIST_ITEM_DELETE": "Excluir",
    "HEADER_MENU_LIST_ITEM_RENAME": "Renomear",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "Adicionar chave de licença",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "Sair",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "Você quer sair do editor?",
    "HEADER_BTN_BACK_TO_MY_ACC": "Voltar para minha conta",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "Voltar para minha conta",
    "HEADER_BTN_HELP": "Ajuda",
    "HEADER_BTN_HELP_TOOLTIP": "Ajuda",
    "IMAGES_TEXT_NATIVE_IMAGES": "Imagem nativa",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "Images",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "Pesquisar em",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "Selecionar coleção",
    "IMAGES_TEXT_ALL_CATEGORIES": "Todas as coleções",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "Nenhum resultado encontrado",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "Parece que não conseguimos encontrar nenhum resultado com base em sua pesquisa.",
    "IMAGES_TAB_AI_GENERATED": "Ai-Generated",
    "IMAGES_TAB_SEARCH": "Pesquisar",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "Importar mídia do URL",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "Clique aqui para criar um link para um arquivo",
    "IMPORT_FROM_URL_BTN_IMPORT": "Importar",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "Importar",
    "LAYER_CONTROL_BTN_MOVE_UP": "Mover para cima",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "Mover para cima",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "Camada de arrasto",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "Drag Layer",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "Mover para baixo",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "Mover para baixo",
    "LAYER_CONTROL_BTN_DELETE": "Excluir",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "Excluir",
    "LAYER_CONTROL_BTN_HIDE": "Hide",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "Hide",
    "LAYER_CONTROL_BTN_SHOW": "Mostrar",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "Mostrar",
    "LAYER_CONTROL_BTN_MUTE": "Silenciar",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "Silenciar",
    "LAYER_CONTROL_BTN_UNMUTE": "Desativar o som",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "Desativar o som",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "Minha mídia",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "Minha mídia",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "Transições",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "Transições",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "Imagens",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "Imagens",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "Áudio",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "Áudio",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "Texto",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "Texto",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "Design",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "Design",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "Filtros",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "Filtros",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "Filtros",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "Configurações de imagem",
    "MEDIA_FORM_CAPTION_POSITION": "Posição",
    "MEDIA_FORM_CAPTION_ROTATION": "Rotação",
    "MEDIA_FORM_CAPTION_SETTINGS": "Configurações",
    "MEDIA_FORM_CAPTION_OUTLINE": "Esboço",
    "MEDIA_FORM_CAPTION_SHADOW": "Sombra",
    "MEDIA_FORM_CAPTION_SIZE": "Tamanho",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "Opacidade",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "Filtro",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "Filtro",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "Todos",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "Vídeo",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "Imagem",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "Áudio",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "Pasta",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "Locução",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "Configurações de visualização",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "Configurações de visualização",
    "MEDIA_UPLOADER_TEXT_OR": "ou",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "Arraste e solte sua mídia aqui",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "importar mídia",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "Importando",
    "MEDIA_PREVIEW_TEXT_RENDERING": "Renderização",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "Resolução",
    "MEDIA_INFO_LIST_ITEM_CODEC": "Codecs",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "Taxa de quadros",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - Estéreo",
    "MEDIA_INFO_LIST_ITEM_DURATION": "Duração",
    "MEDIA_INFO_LIST_ITEM_TYPE": "Tipo",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "Áudio",
    "MEDIA_INFO_LIST_ITEM_SIZE": "Tamanho",
    "MEDIA_INFO_LIST_TASK_STATUS": "Status da tarefa",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "Iniciar código de tempo",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "Mídia",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "Carregar mais mídia",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "Carregar mais mídia",
    "MEDIA_BTN_LOADING": "Carregando",
    "MEDIA_BTN_LOADING_TOOLTIP": "Carregando",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "Chroma Key",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "Posição",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "Som",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "Tamanho",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "Configurações de mídia",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "Redefinir todas as configurações",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "Redefinir todas as configurações",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "Similaridade",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "Suavidade",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "Redução de derramamento de cores-chave",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "Escolher cor da visualização",
    "MEDIA_SORT_OPTION_DURATION": "Duração",
    "MEDIA_SORT_OPTION_DATE": "Data",
    "MEDIA_SORT_OPTION_SIZE": "Tamanho",
    "MEDIA_SORT_OPTION_NAME": "Nome",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "Classificar por",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "Ordem",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "Exibir",
    "MEDIA_SORT_OPTION_ASCENDING": "Ascendente",
    "MEDIA_SORT_OPTION_DESCENDING": "Descendente",
    "MEDIA_SORT_OPTION_LIST": "List",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "Ícones grandes",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "Minhas gravações",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "Minhas gravações",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "Minha mídia",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "Minha mídia",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "Tem certeza de que deseja remover o(s) item(ns) selecionado(s)?",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "Procurar por pasta",
    "MEDIA_CONFIRM_BTN_DELETE": "Excluir",
    "MEDIA_CANCEL_BTN_DELETE": "Cancelar",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "Excluir",
    "MEDIA_CONFIRM_TITLE_DELETE": "Excluir",
    "MEDIA_TEXT_LOADING": "Carregando",
    "MEDIA_BTN_ADD": "Adicionar",
    "MEDIA_BTN_ADD_TOOLTIP": "Adicionar",
    "MEDIA_PROGRESS_TEXT_LOADING": "Carregando",
    "MEDIA_PROGRESS_TEXT_SAVING": "Salvando",
    "MEDIA_PROGRESS_TEXT_CONVERT": "Converter",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "Transferir",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "Análise",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "Selecionar pasta",
    "MEDIA_MENU_ITEM_MEDIA": "Mídia",
    "MEDIA_MENU_ITEM_MOVE_TO": "Mover para",
    "MEDIA_MENU_ITEM_SETTINGS": "Configurações",
    "MEDIA_MENU_ITEM_NABLET_HLD": "Detectar destaques",
    "MEDIA_MENU_ITEM_DELETE": "Excluir",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "Navegar na mídia",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "Copiar endereço de mídia",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "Adicionar à linha do tempo usando o timecode inicial",
    "MEDIA_MENU_LABEL_MOVE_TO": "Mover para",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "Editor de vídeo HTML5",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "Tablets e celulares ainda não são suportados :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "O editor de vídeo funciona bem em seu computador",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "Voltar ao site",
    "MODAL_WINDOW_LOGS_TITLE": "Para ligar/desligar os logs, é necessário reiniciar o aplicativo",
    "MODAL_WINDOW_BUTTON_RESTART": "Reiniciar",
    "NAVIGATION_TAB_EDITOR": "Editor",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "Editor",
    "NAVIGATION_TAB_PROJECTS": "Projetos",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "Projetos",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "A edição não está disponível até que a conexão seja restaurada.",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "Last saved",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "min ago",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "agora mesmo",
    "PROJECTS_BTN_NEW_PROJECTS": "Novo projeto",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "Novo projeto",
    "PROJECTS_BTN_NEW_PROJECT": "Novo projeto",
    "PROJECTS_BTN_IMPORT": "Importação",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "Importação",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "Sim",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "Cancelar",
    "PROJECTS_TITLE_TEXT_COPY": "Cópia",
    "PROJECTS_BTN_SAVE": "Salvar",
    "PROJECTS_BTN_SAVE_TOOLTIP": "Salvar",
    "PROJECTS_TITLE_TEXT_RENAME": "Renomear",
    "PROJECTS_TITLE_TEXT_DELETE": "Excluir",
    "PROJECTS_TITLE_TEXT_EXPORT": "Exportar",
    "PROJECTS_CONFIRM_BTN_DELETE": "Excluir",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "Editado %d horas atrás",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "Editado agora mesmo",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "Editado %d minutos atrás",
    "PROJECTS_PROGRESS_TEXT_LOADING": "Carregando",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "Pesquisa",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "Você quer parar de importar?",
    "PROJECTS_TEXT_NOTHING_FOUND": "Nada encontrado",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "Criar um novo e belo projeto",
    "PROJECTS_TEXT_CAPTION": "Projetos",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "Nome do projeto",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "Clique para fazer upload ou arraste e solte um arquivo de projeto aqui",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "Você pode carregar apenas um arquivo por vez",
    "PROJECTS_PREFIX_TEXT_EDITED": "Editado",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "Tem certeza de que deseja excluir",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "Este projeto será excluído imediatamente. Você não pode desfazer essa ação.",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "Projeto",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "Somente um arquivo pode ser carregado",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "Extensão de arquivo inválida. Deve ser *.edl.zip",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "Digite seu valor",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "Criar uma pasta para este projeto em Mídia",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "Abrir Editor",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "Tente novamente",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "Selecione a taxa de quadros para o projeto",
    "PROJECTS_EXPORT_TITLE": "Exportar projeto",
    "PROJECTS_EXPORT_INPROGRESS": "Exportação em andamento...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "Incluir mídia",
    "PROJECTS_EXPORT_COMPLETE": "Exportação do projeto concluída",
    "PROJECTS_EXPORT_BTN_BROWSE": "Navegar",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "Baixar",
    "PROJECTS_EXPORT_BTN_COPY": "Copiar",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "Os arquivos de mídia são reproduzidos aqui",
    "PREVIEW_BTN_PREV_TOOLTIP": "Anterior",
    "PREVIEW_BTN_PREV": "Anterior",
    "PREVIEW_BTN_PLAY_TOOLTIP": "Reproduzir",
    "PREVIEW_BTN_PLAY": "Reproduzir",
    "PREVIEW_BTN_STOP_TOOLTIP": "Parar",
    "PREVIEW_BTN_STOP": "Parar",
    "PREVIEW_BTN_NEXT_TOOLTIP": "Próximo",
    "PREVIEW_BTN_NEXT": "Próximo",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "Volume",
    "PREVIEW_BTN_VOLUME": "Volume",
    "PREVIEW_BTN_FULL_SCREEN": "Tela cheia",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "Tela cheia",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "Marcar em",
    "PREVIEW_BTN_MARK_IN": "Marcar em",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "Marcar",
    "PREVIEW_BTN_MARK_OUT": "Marcar",
    "PREVIEW_HEADER_TITLE_SOURCE": "Fonte",
    "PREVIEW_HEADER_TITLE_TIMELINE": "Linha do tempo",
    "PREVIEW_MODE_AUTO": "Automotivo",
    "PREVIEW_MODE_SOURCE": "Fonte",
    "PREVIEW_MODE_TIMELINE": "Linha do tempo",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "saída",
    "PUBLISH_SETTINGS_TITLE": "Título",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "Iniciar a renderização a partir do primeiro clipe",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "Duração",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "Codec",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "Frame Rate",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - Estéreo",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "Resolução",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "Áudio",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "Tamanho (aproximado)",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "O link para o arquivo de transcodificação será perdido",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "Configurações de publicação",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "Renderização de vídeo concluída",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "Digite o nome",
    "SAVE_SETTINGS_BTN_RENDER_START": "Iniciar",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "Iniciar",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "Voltar para minha conta",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "Voltar para minha conta",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "Cancelar",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "Cancelar",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "Baixar",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "Baixar",
    "SAVE_SETTINGS_BTN_BROWSE": "Navegar",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "Navegar",
    "SAVE_SETTINGS_BTN_COPY": "Cópia",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "Cópia",
    "SAVE_SETTINGS_TEXT_TITLE": "Título",
    "SAVE_SETTINGS_TEXT_LOADING": "Carregando",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "Este vídeo foi enviado para sua conta e deverá estar disponível em breve",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "Todos",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "Pesquisar",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "Descrição",
    "SETTINGS_HEAD_BTN_DELETE": "Excluir",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "Excluir",
    "SETTINGS_HEAD_BTN_CLOSE": "Fechar",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "Fechar",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "Largura",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "Altura",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "Manter a proporção de aspecto",
    "SETTINGS_BTN_FIT_TO_FRAME": "Ajustar ao quadro",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "Ajustar ao quadro",
    "SETTINGS_BTN_FILL_FRAME": "Preencher quadro",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "Preencher quadro",
    "SETTINGS_TEXT_AUTO": "Automotivo",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "Transformação de texto nenhuma",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "Transformação de texto nenhuma",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "Transformação de texto em maiúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "Transformação de texto em maiúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "Transformação de texto em minúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "Transformação de texto em minúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "Transformação de texto em maiúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "Transformação de texto em maiúsculas",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "Família de fontes",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "Família de fontes",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "Tamanho da fonte",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "Tamanho da fonte",
    "SETTINGS_TEXT_BTN_BOLD": "Negrito",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "Negrito",
    "SETTINGS_TEXT_BTN_ITALIC": "Itálico",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "Itálico",
    "SETTINGS_TEXT_BTN_UNDERLINE": "Sublinhado",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "Sublinhado",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "Esquerda",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "Esquerda",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "Direita",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "Direita",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "Centro",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "Centro",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "Justificar",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "Justificar",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "Superior",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "Superior",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "Superior esquerdo",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "Superior esquerdo",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "Superior direito",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "Superior direito",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "Esquerda",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "Esquerda",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "Centro do meio",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "Centro do meio",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "Direita",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "Direita",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "Esquerda  inferior",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "Esquerda inferior",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "Inferior",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "Inferior",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "Direito inferior",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "Direito inferior",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "Horizontal",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "Vertical",
    "SETTINGS_ROTATION_TEXT_ROTATE": "Girar",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "Inserir texto",
    "NABLET_HLD_PROJECT_POSTFIX": "destaques",
    "NABLET_HLD_CAPTION": "Detectar destaques",
    "NABLET_HLD_DETECTED_BTN_START": "Iniciar",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "Criar projeto(s)",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "Cancelar",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "Contagem de intervalos detectados:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "Tem certeza de que deseja encerrar a tarefa?",
    "NABLET_HLD_DETECTED_BTN_NO": "Não",
    "NABLET_HLD_DETECTED_BTN_YES": "Sim",
    "NB_HLD_STATUS_TEXT_LOADING": "Carregando",
    "NB_HLD_STATUS_TEXT_DETECTING": "Detectando",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "Baixando mídia",
    "NB_HLD_STATUS_TEXT_ABORTED": "Abortado",
    "NABLET_HLD_TARGET_DURATION_SEC": "Duração alvo em segundos",
    "NABLET_HLD_ONNX_MODEL": "Modelo Onnx",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "Esta seção está em desenvolvimento",
    "TEXT_FORM_CAPTION_SIZE": "Tamanho",
    "TEXT_FORM_CAPTION_OUTLINE": "Esboço",
    "TEXT_FORM_CAPTION_FONT": "Fonte",
    "TEXT_FORM_CAPTION_SHADOW": "Sombra",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "Configurações de texto",
    "TEXT_FORM_TEXT_OFFSET": "Deslocamento",
    "TEXT_FORM_TEXT_BLUR": "Desfoque",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "Texto",
    "TIMELINE_TEXT_DRAG_N_DROP": "Arrastar e soltar",
    "TIMELINE_TEXT_ADD_LAYER": "#NAME?",
    "TIMELINE_TOOLBAR_BTN_UNDO": "Desfazer",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "Desfazer",
    "TIMELINE_TOOLBAR_BTN_REDO": "Refazer",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "Refazer",
    "TIMELINE_TOOLBAR_BTN_DELETE": "Excluir",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "Excluir",
    "TIMELINE_TOOLBAR_BTN_COPY": "Copiar clipe",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "Copiar clipe",
    "TIMELINE_TOOLBAR_BTN_CUT": "Cortar clipe(s)",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "Cortar clipe(s)",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "Adicionar marcador",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "Adicionar marcador",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "Colar",
    "TIMELINE_TOOLBAR_BTN_PASTE": "Colar",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "Configurações",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "Configurações",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "Locução",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "Locução",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "Câmera da Web",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "Câmera da Web",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "Clique para cancelar",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "Clique para cancelar",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "Arrastar e soltar",
    "TIMELINE_FOOTER_TEXT_DURATION": "Duração",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "Não foi possível publicar o projeto",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "A trilha de vídeo é necessária",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "Salvar",
    "TIMELINE_FOOTER_BTN_SAVE": "Salvar",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "Salvar",
    "TIMELINE_FOOTER_BTN_PUBLISH": "Publicar",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "Publicar",
    "TIMELINE_SLIDER_POINTER": "Ponteiro do controle deslizante",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "Ponteiro do controle deslizante",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "Configurações de transição",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "Aplicar a todas as transições da linha do tempo",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "Duração da transição:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "Transição",
    "used_in_projects": "O arquivo de mídia não foi excluído porque está em uso em um ou mais projetos. Se você continuar, o arquivo não estará disponível na linha do tempo nos projetos correspondentes e será excluído de sua conta.",
    "VOICE_OVER_TITLE_LAST_RECORDING": "Última gravação",
    "VOICE_OVER_BTN_RETAKE": "Reto",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "Retomar",
    "VOICE_OVER_BTN_DELETE_FILE": "Excluir arquivo",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "Excluir arquivo",
    "VOICE_OVER_BTN_START_RECORDING": "Iniciar gravação",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "Iniciar gravação",
    "VOICE_OVER_BTN_CONNECTION": "Conexão...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "Conexão...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "O arquivo será excluído da linha do tempo e da mídia.",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "Tem certeza?",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "Narração de voz",
    "VOICE_OVER_TEXT_MIC": "Microfone",
    "VOICE_OVER_LABEL_SELECT_MIC": "Selecione o microfone",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "Sem áudio",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "Silenciar a linha do tempo durante a gravação",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "Controle deslizante de retrocesso no início após o registro",
    "VOICE_OVER_TEXT_COUNTDOWN": "Contagem regressiva",
    "VOICE_OVER_BTN_TEST_MIC": "Microfone de teste",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "Testar microfone",
    "VOICE_OVER_BTN_RECORDING": "Gravação...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "Gravando...",
    "VOICE_OVER_BTN_PLAYING": "Reproduzindo...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "Reproduzindo...",
    "VOICE_OVER_BTN_YES": "Sim",
    "VOICE_OVER_BTN_YES_TOOLTIP": "Sim",
    "VOICE_OVER_BTN_NO": "Não",
    "VOICE_OVER_BTN_NO_TOOLTIP": "Não",
    "VOICE_OVER_BTN_STOP": "Parar",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "Parar",
    "VOICE_OVER_BTN_DELETE": "Excluir",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "Excluir",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "Ajuste automático",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "Outras configurações",
    "WEBCAM_TEXT_RECORD_VIDEO": "Gravar vídeo",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "Diminuir o zoom",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "Diminuir o zoom",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "Aumentar o zoom",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "Aumentar o zoom",
    "SETTINGS_HOTKEYS": "Configurações de teclas de atalho",
    "PRESET": "Predefinição",
    "DEFAULT": "Padrão",
    "ADD_NEW": "Adicionar novo",
    "SEARCH_COMMAND": "Comando de pesquisa",
    "COMMAND": "Comando",
    "SHORTCUTS": "Shortcuts",
    "ALL": "Todos",
    "TIMELINE": "Linha do tempo",
    "TOOLS": "Ferramentas",
    "PREVIEW": "Pré-visualização",
    "KEYBOARD_MAPPING_PRESET": "Predefinição de mapeamento de teclado",
    "ENTER_PRESET_NAME": "Digite o nome da predefinição",
    "THIS_PRESET_WILL_BE_DELETED": "Essa predefinição será excluída imediatamente. Você não pode desfazer essa ação.",
    "RESET_ALL_TO_DEFAULTS": "Redefinir tudo para os padrões",
    "HOTKEY_SETTINGS_UNDO": "Desfazer",
    "HOTKEY_SETTINGS_REDO": "Redo",
    "HOTKEY_SETTINGS_ZOOM_IN": "Zoom in",
    "HOTKEY_SETTINGS_ZOOM_OUT": "Zoom out",
    "HOTKEY_SETTINGS_PREV_FRAME": "Quadro anterior",
    "HOTKEY_SETTINGS_NEXT_FRAME": "Próximo quadro",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "Adicionar à linha do tempo",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "Aumentar o volume do clipe",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "Diminuir o volume do clipe",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "Ripple delete",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "Ripple trim anterior",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "Ripple trim next",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "Selecionar clipes na posição atual",
    "HOTKEY_SETTINGS_CUT_CLIPS": "Cortar clipe(s)",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "Reproduzir/Pausar",
    "HOTKEY_SETTINGS_DELETE": "Excluir",
    "HOTKEY_SETTINGS_COPY": "Copiar",
    "HOTKEY_SETTINGS_PASTE": "Colar",
    "HOTKEY_SETTINGS_PLAY": "Reproduzir",
    "HOTKEY_SETTINGS_PAUSE": "Pausar",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "Reproduzir o intervalo destacado",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "Limpar pontos de entrada e saída",
    "HOTKEY_SETTINGS_MARK_IN": "Marcar ponto de entrada",
    "HOTKEY_SETTINGS_MARK_OUT": "Marcar ponto de saída",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "Procurar entrar no ponto",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "Buscar o ponto de saída",
    "CLIP_SETTINGS": "Configurações do clipe",
    "MESSAGE_BOX_REMOVE_EXISTING": "Os arquivos não são compatíveis. Por favor, remova os existentes",
    "MARKS_CONTEXT_MENU_MARK_IN": "Marcar entrada",
    "MARKS_CONTEXT_MENU_MARK_OUT": "Marcar saída",
    "MARKS_CONTEXT_MENU_CLEAR": "Limpar pontos de entrada/saída",
    "MEDIA_ERROR_build_thumbnails_error": "Erro de miniaturas de construção",
    "MEDIA_ERROR_unsupported_media": "Mídia não suportada",
    "MEDIA_ERROR_exceeded_file_size": "Tamanho de arquivo excedido",
    "MEDIA_ERROR_exceeded_per_user_size": "Tamanho por usuário excedido",
    "MEDIA_ERROR_low_disk_space": "Pouco espaço em disco",
    "MEDIA_ERROR_internal_server_error": "Erro interno do servidor",
    "MEDIA_ERROR_network_error": "Erro de rede",
    "MEDIA_ERROR_not_found": "Arquivo de mídia não encontrado",
  },
  de: {
    "ADD_FOLDER_BTN": "Ordner hinzufügen",
    "ADD_FOLDER_BTN_TOOLTIP": "Ordner hinzufügen",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Bucket Name",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "Zugriffsschlüssel-ID",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "Geheimer Schlüssel",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "Importieren",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "Importfehler",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "Erfolgreich importiert",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "Medien aus AWS Bucket importieren",
    "AMAZON_IMPORT_BTN_IMPORT": "Importieren",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "Importieren",
    "AUDIO_CAPTION_AUDIO": "Audio",
    "AUDIO_TEXT_FOUND_ITEMS": "Gefundene Elemente",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "Keine Ergebnisse gefunden",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "Es scheint, dass wir keine Ergebnisse zu Ihrer Suche finden konnten.",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "Audio-Einstellungen",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "Ton",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "Transition",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "Einblendung",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "Ausblenden",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "Genre suchen",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "Musik suchen",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "Klangeffekte suchen",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "Suche in",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "Musik nach Genre",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "Toneffekte",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "Musik nach Stimmungen",
    "AUDIO_MENU_ITEM_ALL": "Alle",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "Asset aus der Zeitleiste entfernen?",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "Timecode-Format je nach Medium einstellen",
    "COLOR_PICKER_BTN_COLOR": "Farbe auswählen",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "Farbe auswählen",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "Hintergrundfarbe",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "Hintergrundfarbe",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "Mediendateien werden heruntergeladen",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "Gespeicherte Medien hochladen",
    "COMMON_STATUS_TEXT_ABORTED": "Abgebrochen",
    "COMMON_STATUS_TEXT_LOADING": "Laden",
    "COMMON_STATUS_TEXT_RENDERING": "Rendering",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "Abbrechen",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "Abbrechen",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "Benutzer-Avatar",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "Sie können eine Datei mit dem Standard-Benutzeravatar hochladen.",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "Avatar",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "Farbthema",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "Benutzerdefiniertes JS-Skript",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "Benutzerdefinierter Text",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "Sie können den Text des Editors anpassen",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "Dieses Skript wird beim Laden des Editors ausgeführt. Kann für die Integration von Analysen usw. verwendet werden",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "JS-Skript bearbeiten",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "Zweite Akzentfarbe",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "Wählen Sie die Farbe des Haupthintergrunds aus.",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "Die Haupt-Akzentfarbe für die Steuerelemente. Wählen Sie hier Ihre Markenfarbe aus.",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "Zusätzliche Farbe der Steuerelemente im Paar mit der Hauptfarbe der Schaltflächen",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "Die Audiodatei auf der Zeitachse hat ihren eigenen Stil.",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "Sie können eine Datei mit Ihrem Logo hochladen.",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "Die Voiceover-Datei hat ihren eigenen Stil.",
    "CUSTOMIZATION_LABEL_DISPLAYED": "angezeigt",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "Wählen Sie eines der vordefinierten Themes oder erstellen Sie Ihr eigenes. Sie können die CSS-Datei bearbeiten, um Ihr Thema anzupassen.",
    "CUSTOMIZATION_TITLE_BASIC_BG": "Grundlegender Hintergrund",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "Primäre Schaltflächen, Links",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "CSS-Datei für Theme bearbeiten",
    "CUSTOMIZATION_TITLE_AUDIO": "Audio",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "Voice over",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "Textwerte bearbeiten",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "Textwerte bearbeiten",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "Textwerte bearbeiten",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "Formatbeschreibung",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "Formatbeschreibung",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "Alle Änderungen zurücksetzen",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "Alle Änderungen zurücksetzen",
    "CUSTOMIZATION_BTN_CANCEL": "Abbrechen",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "Abbrechen",
    "CUSTOMIZATION_BTN_SAVE": "Speichern",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "Speichern",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "JS-Skript bearbeiten",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "JS-Skript bearbeiten",
    "CUSTOMIZATION_BTN_EDIT_CSS": "CSS bearbeiten",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "CSS bearbeiten",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "Logo hochladen",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "Logo hochladen",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "Logo löschen",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "Logo löschen",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "Dunkles Thema",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "Helles Thema",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "Benutzerdefiniertes Thema",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "Logo",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "Logo",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "Editor anpassen",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "Logo und Name",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "Firmenname",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "Name des Editors",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "Akzeptierte Dateien:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "Kein hochgeladenes Logo",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "Einstellungen anzeigen",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "Ansichtseinstellungen",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "Filter",
    "DUMMY_HEADER_BTN_FILTERS": "Filter",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Projekt speichern unter...",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "Neues Projekt",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "Veröffentlichen",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "Editor anpassen",
    "EDITOR_MENU_MAIN_LOGS": "Protokolle",
    "EDITOR_MENU_MAIN_SETTINGS": "Einstellungen",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "Kann nicht veröffentlicht werden",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "Titel ist erforderlich",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "Render-Einstellungen",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "Verhalten",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "Speichern",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "Abbrechen",
    "FILE_INPUT_BTN": "Dateieingabe",
    "FILE_INPUT_BTN_TOOLTIP": "Dateieingabe",
    "FORM_FIELD_LABEL_VOLUME": "Volumen",
    "folder_not_empty": "Der Ordner ist nicht leer. Die Dateien können in Projekten verwendet werden. Wenn Sie fortfahren, wird die Datei nicht auf der Zeitachse der entsprechenden Projekte verfügbar sein.",
    "HEAD_CONTROL_IMPORT_TEXT": "Importieren",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "Medien importieren",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "Ordner mit Medien importieren",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL-Eingabe",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Projekt speichern unter...",
    "HEADER_MENU_LIST_ITEM_DELETE": "Löschen",
    "HEADER_MENU_LIST_ITEM_RENAME": "Umbenennen",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "Lizenzschlüssel hinzufügen",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "Abmelden",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "Wollen Sie den Editor verlassen?",
    "HEADER_BTN_BACK_TO_MY_ACC": "Zurück zu meinem Konto",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "Zurück zu meinem Konto",
    "HEADER_BTN_HELP": "Hilfe",
    "HEADER_BTN_HELP_TOOLTIP": "Hilfe",
    "IMAGES_TEXT_NATIVE_IMAGES": "Natives Bild",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "Bilder",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "Suchen in",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "Sammlung auswählen",
    "IMAGES_TEXT_ALL_CATEGORIES": "Alle Sammlungen",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "Keine Ergebnisse gefunden",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "Es scheint, dass wir keine Ergebnisse zu Ihrer Suche finden konnten.",
    "IMAGES_TAB_AI_GENERATED": "Ai-Generiert",
    "IMAGES_TAB_SEARCH": "Suche",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "Medien von URL importieren",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "Klicken Sie hier, um auf eine Datei zu verlinken",
    "IMPORT_FROM_URL_BTN_IMPORT": "Importieren",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "Importieren",
    "LAYER_CONTROL_BTN_MOVE_UP": "Nach oben bewegen",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "Nach oben bewegen",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "Ebene ziehen",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "Ebene ziehen",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "Nach unten bewegen",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "Nach unten bewegen",
    "LAYER_CONTROL_BTN_DELETE": "Löschen",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "Löschen",
    "LAYER_CONTROL_BTN_HIDE": "Ausblenden",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "Ausblenden",
    "LAYER_CONTROL_BTN_SHOW": "Anzeigen",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "Anzeigen",
    "LAYER_CONTROL_BTN_MUTE": "Stumm",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "Stumm",
    "LAYER_CONTROL_BTN_UNMUTE": "Stummschaltung aufheben",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "Stummschaltung aufheben",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "Meine Medien",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "Meine Medien",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "Übergänge",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "Übergänge",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "Bilder",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "Bilder",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "Audio",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "Audio",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "Text",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "Text",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "Design",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "Design",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "Filter",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "Filter",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "Filter",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "Bildeinstellungen",
    "MEDIA_FORM_CAPTION_POSITION": "Position",
    "MEDIA_FORM_CAPTION_ROTATION": "Rotation",
    "MEDIA_FORM_CAPTION_SETTINGS": "Einstellungen",
    "MEDIA_FORM_CAPTION_OUTLINE": "Gliederung",
    "MEDIA_FORM_CAPTION_SHADOW": "Schatten",
    "MEDIA_FORM_CAPTION_SIZE": "Größe",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "Opazität",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "Filter",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "Filter",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "Alle",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "Video",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "Bild",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "Audio",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "Mappe",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "Voiceover",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "Ansichtseinstellungen",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "Ansichtseinstellungen",
    "MEDIA_UPLOADER_TEXT_OR": "oder",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "Ziehen Sie Ihre Medien hierher und legen Sie sie ab",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "Medien importieren",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "Importieren",
    "MEDIA_PREVIEW_TEXT_RENDERING": "Rendering",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "Auflösung",
    "MEDIA_INFO_LIST_ITEM_CODEC": "Codecs",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "Bildrate",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
    "MEDIA_INFO_LIST_ITEM_DURATION": "Dauer",
    "MEDIA_INFO_LIST_ITEM_TYPE": "Typ",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "Audio",
    "MEDIA_INFO_LIST_ITEM_SIZE": "Größe",
    "MEDIA_INFO_LIST_TASK_STATUS": "Aufgabenstatus",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "Start-Timecode",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "Medien",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "Mehr Medien laden",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "Mehr Medien laden",
    "MEDIA_BTN_LOADING": "Laden",
    "MEDIA_BTN_LOADING_TOOLTIP": "Laden",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "Chroma-Schlüssel",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "Position",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "Ton",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "SIZE",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "Medieneinstellungen",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "Alle Einstellungen zurücksetzen",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "Alle Einstellungen zurücksetzen",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "Ähnlichkeit",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "Glattheit",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "Key Color Spill Reduction",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "Farbe aus der Vorschau auswählen",
    "MEDIA_SORT_OPTION_DURATION": "Dauer",
    "MEDIA_SORT_OPTION_DATE": "Datum",
    "MEDIA_SORT_OPTION_SIZE": "Größe",
    "MEDIA_SORT_OPTION_NAME": "Name",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "Sortieren nach",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "Reihenfolge",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "Ansicht",
    "MEDIA_SORT_OPTION_ASCENDING": "Aufsteigend",
    "MEDIA_SORT_OPTION_DESCENDING": "Absteigend",
    "MEDIA_SORT_OPTION_LIST": "Liste",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "Große Symbole",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "Meine Aufnahmen",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "Meine Aufnahmen",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "Meine Medien",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "Meine Medien",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "Sind Sie sicher, dass Sie das/die ausgewählte(n) Element(e) entfernen möchten?",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "Nach Ordner suchen",
    "MEDIA_CONFIRM_BTN_DELETE": "Löschen",
    "MEDIA_CANCEL_BTN_DELETE": "Abbrechen",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "Löschen",
    "MEDIA_CONFIRM_TITLE_DELETE": "Löschen",
    "MEDIA_TEXT_LOADING": "Laden",
    "MEDIA_BTN_ADD": "Hinzufügen",
    "MEDIA_BTN_ADD_TOOLTIP": "Hinzufügen",
    "MEDIA_PROGRESS_TEXT_LOADING": "Laden",
    "MEDIA_PROGRESS_TEXT_SAVING": "Speichern",
    "MEDIA_PROGRESS_TEXT_CONVERT": "Konvertieren",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "Übertragung",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "Analyse",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "Ordner auswählen",
    "MEDIA_MENU_ITEM_MEDIA": "Medien",
    "MEDIA_MENU_ITEM_MOVE_TO": "Verschieben nach",
    "MEDIA_MENU_ITEM_SETTINGS": "Einstellungen",
    "MEDIA_MENU_ITEM_NABLET_HLD": "Highlights erkennen",
    "MEDIA_MENU_ITEM_DELETE": "Löschen",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "Medien durchsuchen",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "Medienadresse kopieren",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "Hinzufügen zur Zeitleiste mit Start-Timecode",
    "MEDIA_MENU_LABEL_MOVE_TO": "Verschieben nach",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5 Video Editor",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "Tablets und Handys werden noch nicht unterstützt :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "Video Editor funktioniert gut auf Ihrem Computer",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "Zurück zur Website",
    "MODAL_WINDOW_LOGS_TITLE": "Um die Protokolle ein- oder auszuschalten, müssen Sie die Anwendung neu starten",
    "MODAL_WINDOW_BUTTON_RESTART": "Neustart",
    "NAVIGATION_TAB_EDITOR": "Editor",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "Editor",
    "NAVIGATION_TAB_PROJECTS": "Projekte",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "Projekte",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "Die Bearbeitung ist nicht verfügbar, bis die Verbindung wiederhergestellt ist.",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "Zuletzt gespeichert",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "min ago",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "gerade jetzt",
    "PROJECTS_BTN_NEW_PROJECTS": "Neues Projekt",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "Neues Projekt",
    "PROJECTS_BTN_NEW_PROJECT": "Neues Projekt",
    "PROJECTS_BTN_IMPORT": "Importieren",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "Importieren",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "Ja",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "Abbrechen",
    "PROJECTS_TITLE_TEXT_COPY": "Kopie",
    "PROJECTS_BTN_SAVE": "Speichern",
    "PROJECTS_BTN_SAVE_TOOLTIP": "Speichern",
    "PROJECTS_TITLE_TEXT_RENAME": "Umbenennen",
    "PROJECTS_TITLE_TEXT_DELETE": "Löschen",
    "PROJECTS_TITLE_TEXT_EXPORT": "Exportieren",
    "PROJECTS_CONFIRM_BTN_DELETE": "Löschen",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "Bearbeitet %d vor Stunden",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "Bearbeitet gerade jetzt",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "Bearbeitet %d vor Minuten",
    "PROJECTS_PROGRESS_TEXT_LOADING": "Laden",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "Suche",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "Möchten Sie den Import stoppen?",
    "PROJECTS_TEXT_NOTHING_FOUND": "Nichts gefunden",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "Erstellen Sie ein schönes neues Projekt",
    "PROJECTS_TEXT_CAPTION": "Projekte",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "Projektname",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "Klicken Sie zum Hochladen oder ziehen Sie eine Projektdatei hierher",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "Sie können jeweils nur eine Datei hochladen",
    "PROJECTS_PREFIX_TEXT_EDITED": "Bearbeitet",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "Sind Sie sicher, dass Sie löschen wollen",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "Dieses Projekt wird sofort gelöscht. Sie können diese Aktion nicht rückgängig machen.",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "Projekt",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "Es kann nur eine Datei hochgeladen werden",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "Ungültige Dateierweiterung. Sollte *.edl.zip sein",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "Geben Sie Ihren Wert ein",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "Erstellen Sie einen Ordner für dieses Projekt in Media",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "Editor öffnen",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "Erneut versuchen",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "Bildrate für das Projekt auswählen",
    "PROJECTS_EXPORT_TITLE": "Projekt exportieren",
    "PROJECTS_EXPORT_INPROGRESS": "Export wird durchgeführt...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "Medien einbeziehen",
    "PROJECTS_EXPORT_COMPLETE": "Projekt-Export abgeschlossen",
    "PROJECTS_EXPORT_BTN_BROWSE": "Durchsuchen",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "Herunterladen",
    "PROJECTS_EXPORT_BTN_COPY": "Kopieren",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "Dateien aus Medien werden hier wiedergegeben",
    "PREVIEW_BTN_PREV_TOOLTIP": "Vorherige",
    "PREVIEW_BTN_PREV": "Vorherige",
    "PREVIEW_BTN_PLAY_TOOLTIP": "Abspielen",
    "PREVIEW_BTN_PLAY": "Abspielen",
    "PREVIEW_BTN_STOP_TOOLTIP": "Stopp",
    "PREVIEW_BTN_STOP": "Stopp",
    "PREVIEW_BTN_NEXT_TOOLTIP": "Weiter",
    "PREVIEW_BTN_NEXT": "Weiter",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "Volumen",
    "PREVIEW_BTN_VOLUME": "Lautstärke",
    "PREVIEW_BTN_FULL_SCREEN": "Vollbild",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "Vollbild",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "Markieren in",
    "PREVIEW_BTN_MARK_IN": "Markieren in",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "Abgrenzung",
    "PREVIEW_BTN_MARK_OUT": "Abgrenzung",
    "PREVIEW_HEADER_TITLE_SOURCE": "Quelle",
    "PREVIEW_HEADER_TITLE_TIMELINE": "Zeitleiste",
    "PREVIEW_MODE_AUTO": "Auto",
    "PREVIEW_MODE_SOURCE": "Quelle",
    "PREVIEW_MODE_TIMELINE": "Zeitleiste",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "Ausgabe",
    "PUBLISH_SETTINGS_TITLE": "Titel",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "Beginnen Sie das Rendering mit dem ersten Clip",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "Dauer",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "Codec",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "Bildrate",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "Auflösung",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "Audio",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "Größe (ungefähr)",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "Link zur Transkodierungsdatei wird verloren gehen",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "Einstellungen veröffentlichen",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "Video-Rendering abgeschlossen",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "Name eingeben",
    "SAVE_SETTINGS_BTN_RENDER_START": "Start",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "Start",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "Zurück zu meinem Konto",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "Zurück zu meinem Konto",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "Abbrechen",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "Abbrechen",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "Herunterladen",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "Herunterladen",
    "SAVE_SETTINGS_BTN_BROWSE": "Durchsuchen",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "Durchsuchen",
    "SAVE_SETTINGS_BTN_COPY": "Kopieren",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "Kopieren",
    "SAVE_SETTINGS_TEXT_TITLE": "Titel",
    "SAVE_SETTINGS_TEXT_LOADING": "Laden",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "Dieses Video wurde an Ihr Konto übermittelt und sollte bald verfügbar sein.",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "Alle",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "Suche",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "Beschreibung",
    "SETTINGS_HEAD_BTN_DELETE": "Löschen",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "Löschen",
    "SETTINGS_HEAD_BTN_CLOSE": "Schließen",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "Schließen",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "Breite",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "Höhe",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "Das Seitenverhältnis beibehalten",
    "SETTINGS_BTN_FIT_TO_FRAME": "An Rahmen anpassen",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "An Rahmen anpassen",
    "SETTINGS_BTN_FILL_FRAME": "Rahmen ausfüllen",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "Rahmen ausfüllen",
    "SETTINGS_TEXT_AUTO": "auto",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "Texttransformation keine",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "Texttransformation keine",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "Texttransformation in Großbuchstaben",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "Texttransformation in Großbuchstaben",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "Texttransformation Kleinbuchstaben",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "Texttransformation Kleinbuchstaben",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "Texttransformation Großbuchstaben",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "Texttransformation groß schreiben",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "Schriftfamilie",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "Schriftfamilie",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "Schriftgröße",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "Schriftgröße",
    "SETTINGS_TEXT_BTN_BOLD": "Fett",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "Fett",
    "SETTINGS_TEXT_BTN_ITALIC": "Kursiv",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "Kursiv",
    "SETTINGS_TEXT_BTN_UNDERLINE": "Unterstreichen",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "Unterstreichen",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "Links",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "Links",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "Rechts",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "Rechts",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "Mitte",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "Mitte",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "Ausrichten",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "Ausrichten",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "Oben",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "Oben",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "Oben links",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "Oben links",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "Oben rechts",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "Oben rechts",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "Links",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "Links",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "Mittlere Mitte",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "Mittlere Mitte",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "Rechts",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "Rechts",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "Unten links",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "Unten links",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "Unten",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "Unten",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "Unten",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "Unten rechts",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "Horizontal",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "Vertikal",
    "SETTINGS_ROTATION_TEXT_ROTATE": "Drehen",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "Text eingeben",
    "NABLET_HLD_PROJECT_POSTFIX": "Highlights",
    "NABLET_HLD_CAPTION": "Highlights erkennen",
    "NABLET_HLD_DETECTED_BTN_START": "Starten",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "Projekt(e) erstellen",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "Abbrechen",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "Erkannte Intervallanzahl:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "Möchten Sie die Aufgabe wirklich beenden?",
    "NABLET_HLD_DETECTED_BTN_NO": "Nein",
    "NABLET_HLD_DETECTED_BTN_YES": "Ja",
    "NB_HLD_STATUS_TEXT_LOADING": "Wird geladen",
    "NB_HLD_STATUS_TEXT_DETECTING": "Erkennung läuft",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "Medien werden heruntergeladen",
    "NB_HLD_STATUS_TEXT_ABORTED": "Abgebrochen",
    "NABLET_HLD_TARGET_DURATION_SEC": "Zielzeit in Sekunden",
    "NABLET_HLD_ONNX_MODEL": "Onnx-Modell",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "Dieser Abschnitt befindet sich in der Entwicklung",
    "TEXT_FORM_CAPTION_SIZE": "Größe",
    "TEXT_FORM_CAPTION_OUTLINE": "Gliederung",
    "TEXT_FORM_CAPTION_FONT": "Schriftart",
    "TEXT_FORM_CAPTION_SHADOW": "Schatten",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "Texteinstellungen",
    "TEXT_FORM_TEXT_OFFSET": "Versatz",
    "TEXT_FORM_TEXT_BLUR": "Unschärfe",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "Text",
    "TIMELINE_TEXT_DRAG_N_DROP": "Ziehen und Ablegen",
    "TIMELINE_TEXT_ADD_LAYER": "+ Ebene hinzufügen",
    "TIMELINE_TOOLBAR_BTN_UNDO": "Rückgängig",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "Rückgängig",
    "TIMELINE_TOOLBAR_BTN_REDO": "Wiederholen",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "Wiederholen",
    "TIMELINE_TOOLBAR_BTN_DELETE": "Löschen",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "Löschen",
    "TIMELINE_TOOLBAR_BTN_COPY": "Clip kopieren",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "Clip kopieren",
    "TIMELINE_TOOLBAR_BTN_CUT": "Clip(s) ausschneiden",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "Clip(s) ausschneiden",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "Markierung hinzufügen",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "Markierung hinzufügen",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "Einfügen",
    "TIMELINE_TOOLBAR_BTN_PASTE": "Einfügen",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "Einstellungen",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "Einstellungen",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "Voice Over",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "Voice Over",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "Webkamera",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "Webkamera",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "Zum Abbrechen klicken",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "Zum Abbrechen klicken",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "Ziehen und Ablegen",
    "TIMELINE_FOOTER_TEXT_DURATION": "Dauer",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "Projekt kann nicht veröffentlicht werden",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "Videospur ist erforderlich",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "Speichern",
    "TIMELINE_FOOTER_BTN_SAVE": "Speichern",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "Speichern",
    "TIMELINE_FOOTER_BTN_PUBLISH": "Veröffentlichen",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "Veröffentlichen",
    "TIMELINE_SLIDER_POINTER": "Schieberegler-Zeiger",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "Schieberegler-Zeiger",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "Einstellungen für den Übergang",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "Auf alle Übergänge in der Zeitleiste anwenden",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "Übergangsdauer:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "Übergang",
    "used_in_projects": "Die Mediendatei wurde nicht gelöscht, da sie in einem oder mehreren Projekten verwendet wird. Wenn Sie fortfahren, ist die Datei in den entsprechenden Projekten auf der Zeitachse nicht mehr verfügbar und wird aus Ihrem Konto gelöscht.",
    "VOICE_OVER_TITLE_LAST_RECORDING": "Letzte Aufnahme",
    "VOICE_OVER_BTN_RETAKE": "Wiederholung",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "Wiederholung",
    "VOICE_OVER_BTN_DELETE_FILE": "Datei löschen",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "Datei löschen",
    "VOICE_OVER_BTN_START_RECORDING": "Aufnahme starten",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "Aufnahme starten",
    "VOICE_OVER_BTN_CONNECTION": "Connection...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "Verbindung...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "Die Datei wird aus der Zeitleiste und den Medien gelöscht.",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "Sind Sie sicher?",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "Sprachnotiz",
    "VOICE_OVER_TEXT_MIC": "Mikrofon",
    "VOICE_OVER_LABEL_SELECT_MIC": "Mikrofon auswählen",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "Kein Ton",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "Stummschalten der Zeitleiste während der Aufnahme",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "Schieberegler für das Zurückspulen am Anfang nach der Aufnahme",
    "VOICE_OVER_TEXT_COUNTDOWN": "Countdown",
    "VOICE_OVER_BTN_TEST_MIC": "Test Mic",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "Test Mic",
    "VOICE_OVER_BTN_RECORDING": "Recording...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "Recording...",
    "VOICE_OVER_BTN_PLAYING": "Playing...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "Playing...",
    "VOICE_OVER_BTN_YES": "Ja",
    "VOICE_OVER_BTN_YES_TOOLTIP": "Ja",
    "VOICE_OVER_BTN_NO": "Nein",
    "VOICE_OVER_BTN_NO_TOOLTIP": "Nein",
    "VOICE_OVER_BTN_STOP": "Stop",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "Stopp",
    "VOICE_OVER_BTN_DELETE": "Löschen",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "Löschen",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "Automatische Anpassung",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "Andere Einstellungen",
    "WEBCAM_TEXT_RECORD_VIDEO": "Video aufzeichnen",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "Zoom verkleinern",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "Zoom verkleinern",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "Zoom erhöhen",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "Zoom vergrößern",
    "SETTINGS_HOTKEYS": "Einstellungen für Hotkeys",
    "PRESET": "Voreinstellung",
    "DEFAULT": "Standard",
    "ADD_NEW": "Neu hinzufügen",
    "SEARCH_COMMAND": "Suchbefehl",
    "COMMAND": "Befehl",
    "SHORTCUTS": "Abkürzungen",
    "ALL": "Alle",
    "TIMELINE": "Zeitleiste",
    "TOOLS": "Werkzeuge",
    "PREVIEW": "Vorschau",
    "KEYBOARD_MAPPING_PRESET": "Voreinstellung für Tastaturbelegung",
    "ENTER_PRESET_NAME": "Voreinstellungsname eingeben",
    "THIS_PRESET_WILL_BE_DELETED": "Diese Voreinstellung wird sofort gelöscht. Sie können diese Aktion nicht rückgängig machen.",
    "RESET_ALL_TO_DEFAULTS": "Alles auf Standardwerte zurücksetzen",
    "HOTKEY_SETTINGS_UNDO": "Rückgängig",
    "HOTKEY_SETTINGS_REDO": "Wiederholen",
    "HOTKEY_SETTINGS_ZOOM_IN": "Vergrößern",
    "HOTKEY_SETTINGS_ZOOM_OUT": "Herauszoomen",
    "HOTKEY_SETTINGS_PREV_FRAME": "Vorheriges Bild",
    "HOTKEY_SETTINGS_NEXT_FRAME": "Nächstes Bild",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "Zur Zeitleiste hinzufügen",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "Clip-Lautstärke erhöhen",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "Clip-Lautstärke verringern",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "Ripple löschen",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "Ripple schneiden vorheriges Element",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "Ripple schneiden nächstes Element",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "Clips an aktueller Position auswählen",
    "HOTKEY_SETTINGS_CUT_CLIPS": "Clip(s) ausschneiden",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "Abspielen/Pause",
    "HOTKEY_SETTINGS_DELETE": "Löschen",
    "HOTKEY_SETTINGS_COPY": "Kopieren",
    "HOTKEY_SETTINGS_PASTE": "Einfügen",
    "HOTKEY_SETTINGS_PLAY": "Abspielen",
    "HOTKEY_SETTINGS_PAUSE": "Pause",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "Abspielen des markierten Intervalls",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "Klare In-Out-Punkte",
    "HOTKEY_SETTINGS_MARK_IN": "Markieren Sie den Eingangspunkt",
    "HOTKEY_SETTINGS_MARK_OUT": "Markierung des Out-Points",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "In-Punkt anstreben",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "Suche nach Out-Point",
    "CLIP_SETTINGS": "Clip-Einstellungen",
    "MESSAGE_BOX_REMOVE_EXISTING": "Die Dateien sind nicht kompatibel. Bitte entfernen Sie vorhandene",
    "MARKS_CONTEXT_MENU_MARK_IN": "Markierung In",
    "MARKS_CONTEXT_MENU_MARK_OUT": "Markierung Out",
    "MARKS_CONTEXT_MENU_CLEAR": "In-/Out-Punkte löschen",
    "MEDIA_ERROR_build_thumbnails_error": "Fehler beim Erstellen der Miniaturansichten",
    "MEDIA_ERROR_unsupported_media": "Nicht unterstützte Medien",
    "MEDIA_ERROR_exceeded_file_size": "Dateigröße überschritten",
    "MEDIA_ERROR_exceeded_per_user_size": "Größe pro Benutzer überschritten",
    "MEDIA_ERROR_low_disk_space": "Wenig Speicherplatz",
    "MEDIA_ERROR_internal_server_error": "Interner Serverfehler",
    "MEDIA_ERROR_network_error": "Netzwerkfehler",
    "MEDIA_ERROR_not_found": "Mediendatei nicht gefunden",
  },
  es: {
    "ADD_FOLDER_BTN": "Añadir carpeta",
    "ADD_FOLDER_BTN_TOOLTIP": "Añadir carpeta",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Bucket Name",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "ID de clave de acceso",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "Clave secreta",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "Importando",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "Error de importación",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "Importado con éxito",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "Importar medios desde AWS Bucket",
    "AMAZON_IMPORT_BTN_IMPORT": "Importar",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "Importar",
    "AUDIO_CAPTION_AUDIO": "Audio",
    "AUDIO_TEXT_FOUND_ITEMS": "Elementos encontrados",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "No se han encontrado resultados",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "Parece que no podemos encontrar ningún resultado basado en tu búsqueda",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "Ajustes de audio",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "Sonido",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "Transición",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "Fundido de entrada",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "Fundido de salida",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "Género de búsqueda",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "Buscar música",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "Buscar efectos de sonido",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "Buscar en",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "Música por género",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "Efectos de sonido",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "Música por estado de ánimo",
    "AUDIO_MENU_ITEM_ALL": "Todos",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "¿Quitar activo de la línea de tiempo?",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "Establece el formato del código de tiempo en función del soporte",
    "COLOR_PICKER_BTN_COLOR": "Seleccionar color",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "Seleccionar color",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "Color de fondo",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "Color de fondo",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "Descargando archivos multimedia",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "Cargando medios guardados",
    "COMMON_STATUS_TEXT_ABORTED": "Abortado",
    "COMMON_STATUS_TEXT_LOADING": "Loading",
    "COMMON_STATUS_TEXT_RENDERING": "Rendering",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "Cancelar",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "Cancelar",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "Avatar de usuario",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "Puedes subir un archivo de avatar de usuario predeterminado",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "Avatar",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "Tema de color",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "Script JS personalizado",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "Texto personalizado",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "Puedes personalizar el texto del editor",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "Este script se ejecuta al cargar el editor. Puede utilizarse para integrar análisis, etc.",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "Editar script JS",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "Segundo color de acento",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "Selecciona el color del fondo principal",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "El color de acento principal para los controles. Seleccione aquí el color de su marca",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "Color adicional de los controles en pareja con el color principal de los botones",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "El archivo de audio en la línea de tiempo tiene su propio estilo",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "Puedes subir un archivo con tu Logotipo",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "El archivo de voz en off tiene su propio estilo",
    "CUSTOMIZATION_LABEL_DISPLAYED": "mostrado",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "Elige uno de los temas predefinidos o crea el tuyo propio. Puedes editar el archivo CSS para personalizar tu tema",
    "CUSTOMIZATION_TITLE_BASIC_BG": "Fondo básico",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "Botones primarios, enlaces",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "Editar archivo CSS para tema",
    "CUSTOMIZATION_TITLE_AUDIO": "Audio",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "Voz en off",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "Editar valores de texto",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "Editar valores de texto",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "Editar valores de texto",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "Descripción del formato",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "Descripción del formato",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "Restablecer todos los cambios",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "Restablecer todos los cambios",
    "CUSTOMIZATION_BTN_CANCEL": "Cancelar",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "Cancelar",
    "CUSTOMIZATION_BTN_SAVE": "Guardar",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "Guardar",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "Editar script JS",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "Editar script JS",
    "CUSTOMIZATION_BTN_EDIT_CSS": "Editar CSS",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "Editar CSS",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "Subir logotipo",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "Subir logotipo",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "Eliminar logotipo",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "Eliminar logotipo",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "Tema oscuro",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "Tema luminoso",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "Tema personalizado",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "Logotipo",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "Logotipo",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "Personalizar editor",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "Logotipo y nombre",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "Nombre de la empresa",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "Nombre del editor",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "Archivos aceptados:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "Sin logotipo cargado",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "Ver ajustes",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "View Settings",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "Filtros",
    "DUMMY_HEADER_BTN_FILTERS": "Filtros",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Guardar proyecto como...",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "Nuevo proyecto",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "Publicar",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "Personalizar editor",
    "EDITOR_MENU_MAIN_LOGS": "Registros",
    "EDITOR_MENU_MAIN_SETTINGS": "Ajustes",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "Imposible publicar",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "Track is required",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "Ajustes de renderizado",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "Comportamiento",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "Guardar",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "Cancelar",
    "FILE_INPUT_BTN": "Entrada de archivo",
    "FILE_INPUT_BTN_TOOLTIP": "Entrada de archivo",
    "FORM_FIELD_LABEL_VOLUME": "Volumen",
    "folder_not_empty": "La carpeta no está vacía. Los archivos pueden utilizarse en los proyectos. Si continúa, el archivo no estará disponible en la línea de tiempo de los proyectos correspondientes.",
    "HEAD_CONTROL_IMPORT_TEXT": "Importar",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "Importar medios",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "Importar carpeta con medios",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL input",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Guardar proyecto como...",
    "HEADER_MENU_LIST_ITEM_DELETE": "Borrar",
    "HEADER_MENU_LIST_ITEM_RENAME": "Renombrar",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "Añadir clave de licencia",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "Cerrar sesión",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "¿Quieres dejar el editor?",
    "HEADER_BTN_BACK_TO_MY_ACC": "Volver a mi cuenta",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "Volver a mi cuenta",
    "HEADER_BTN_HELP": "Ayuda",
    "HEADER_BTN_HELP_TOOLTIP": "Ayuda",
    "IMAGES_TEXT_NATIVE_IMAGES": "Imagen nativa",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "Images",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "Buscar en",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "Seleccionar colección",
    "IMAGES_TEXT_ALL_CATEGORIES": "Todas las colecciones",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "No se han encontrado resultados",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "Parece que no podemos encontrar ningún resultado basado en tu búsqueda",
    "IMAGES_TAB_AI_GENERATED": "Ai-Generated",
    "IMAGES_TAB_SEARCH": "Buscar",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "Importar medios desde URL",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "Haga clic aquí para enlazar a un archivo",
    "IMPORT_FROM_URL_BTN_IMPORT": "Importar",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "Importar",
    "LAYER_CONTROL_BTN_MOVE_UP": "Mover hacia arriba",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "Mover hacia arriba",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "Arrastrar capa",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "Arrastrar capa",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "Mover hacia abajo",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "Mover hacia abajo",
    "LAYER_CONTROL_BTN_DELETE": "Borrar",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "Borrar",
    "LAYER_CONTROL_BTN_HIDE": "Ocultar",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "Ocultar",
    "LAYER_CONTROL_BTN_SHOW": "Mostrar",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "Mostrar",
    "LAYER_CONTROL_BTN_MUTE": "Silenciar",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "Silenciar",
    "LAYER_CONTROL_BTN_UNMUTE": "Unmute",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "Unmute",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "Mis medios",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "Mis medios",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "Transiciones",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "Transiciones",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "Imágenes",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "Imágenes",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "Audio",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "Audio",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "Texto",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "Texto",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "Diseño",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "Diseño",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "Filtros",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "Filtros",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "Filtros",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "Ajustes de imagen",
    "MEDIA_FORM_CAPTION_POSITION": "Posición",
    "MEDIA_FORM_CAPTION_ROTATION": "Rotación",
    "MEDIA_FORM_CAPTION_SETTINGS": "Ajustes",
    "MEDIA_FORM_CAPTION_OUTLINE": "Esquema",
    "MEDIA_FORM_CAPTION_SHADOW": "Sombra",
    "MEDIA_FORM_CAPTION_SIZE": "Tamaño",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "Opacidad",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "Filtros",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "Filtros",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "Todos",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "Vídeo",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "Imagen",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "Audio",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "Carpeta",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "Locución",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "Ver ajustes",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "Ver ajustes",
    "MEDIA_UPLOADER_TEXT_OR": "o",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "Arrastra y suelta tus medios aquí",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "importar medios",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "Importar",
    "MEDIA_PREVIEW_TEXT_RENDERING": "Rendering",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "Resolución",
    "MEDIA_INFO_LIST_ITEM_CODEC": "Codecs",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "Frecuencia de imagen",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - Estéreo",
    "MEDIA_INFO_LIST_ITEM_DURATION": "Duración",
    "MEDIA_INFO_LIST_ITEM_TYPE": "Tipo",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "Audio",
    "MEDIA_INFO_LIST_ITEM_SIZE": "Tamaño",
    "MEDIA_INFO_LIST_TASK_STATUS": "Estado de la tarea",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "Iniciar código de tiempo",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "Media",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "Cargar más medios",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "Cargar más medios",
    "MEDIA_BTN_LOADING": "Cargando",
    "MEDIA_BTN_LOADING_TOOLTIP": "Cargando",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "Chroma Key",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "Posición",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "Sonido",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "TAMAÑO",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "Ajustes de medios",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "Restablecer todos los ajustes",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "Restablecer todos los ajustes",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARIDAD": "Similitud",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "Suavidad",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "Reducción de derrame de colores clave",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "Elige el color de la vista previa",
    "MEDIA_SORT_OPTION_DURATION": "Duración",
    "MEDIA_SORT_OPTION_DATE": "Date",
    "MEDIA_SORT_OPTION_SIZE": "Tamaño",
    "MEDIA_SORT_OPTION_NAME": "Nombre",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "Sort By",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "Order",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "Ver",
    "MEDIA_SORT_OPTION_ASCENDING": "Ascendente",
    "MEDIA_SORT_OPTION_DESCENDING": "Descendente",
    "MEDIA_SORT_OPTION_LIST": "Lista",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "Iconos grandes",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "Mis grabaciones",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "Mis grabaciones",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "Mis medios",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "Mis medios",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "¿Está seguro de que desea eliminar los elementos seleccionados?",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "Buscar carpeta",
    "MEDIA_CONFIRM_BTN_DELETE": "Borrar",
    "MEDIA_CANCEL_BTN_DELETE": "Cancelar",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "Borrar",
    "MEDIA_CONFIRM_TITLE_DELETE": "Borrar",
    "MEDIA_TEXT_LOADING": "Cargando",
    "MEDIA_BTN_ADD": "Añadir",
    "MEDIA_BTN_ADD_TOOLTIP": "Añadir",
    "MEDIA_PROGRESS_TEXT_LOADING": "Cargando",
    "MEDIA_PROGRESS_TEXT_SAVING": "Guardando",
    "MEDIA_PROGRESS_TEXT_CONVERT": "Convertir",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "Transferencia",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "Análisis",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "Seleccionar carpeta",
    "MEDIA_MENU_ITEM_MEDIA": "Media",
    "MEDIA_MENU_ITEM_MOVE_TO": "Mover a",
    "MEDIA_MENU_ITEM_SETTINGS": "Settings",
    "MEDIA_MENU_ITEM_NABLET_HLD": "Detectar momentos destacados",
    "MEDIA_MENU_ITEM_DELETE": "Borrar",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "Examinar los medios",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "Copiar endereço do suporte",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "Añadir a la línea de tiempo utilizando el código de tiempo de inicio",
    "MEDIA_MENU_LABEL_MOVE_TO": "Mover a",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "Editor de vídeo HTML5",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "Las tabletas y los móviles aún no son compatibles :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "El editor de vídeo funciona bien en el ordenador",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "Volver al sitio web",
    "MODAL_WINDOW_LOGS_TITLE": "Para activar/desactivar los registros, es necesario reiniciar la aplicación",
    "MODAL_WINDOW_BUTTON_RESTART": "Reinicie",
    "NAVIGATION_TAB_EDITOR": "Editor",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "Editor",
    "NAVIGATION_TAB_PROJECTS": "Proyectos",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "Projects",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "La edición no está disponible hasta que se restablezca la conexión",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "Último guardado",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "hace un minuto",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "ahora",
    "PROJECTS_BTN_NEW_PROJECTS": "Nuevo proyecto",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "Nuevo proyecto",
    "PROJECTS_BTN_NEW_PROJECT": "Nuevo proyecto",
    "PROJECTS_BTN_IMPORT": "Importar",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "Importar",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "Yes",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "Cancelar",
    "PROJECTS_TITLE_TEXT_COPY": "Copia",
    "PROJECTS_BTN_SAVE": "Guardar",
    "PROJECTS_BTN_SAVE_TOOLTIP": "Guardar",
    "PROJECTS_TITLE_TEXT_RENAME": "Renombrar",
    "PROJECTS_TITLE_TEXT_DELETE": "Eliminar",
    "PROJECTS_TITLE_TEXT_EXPORT": "Exportar",
    "PROJECTS_CONFIRM_BTN_DELETE": "Eliminar",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "Editado %d hace horas",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "Editado ahora mismo",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "Editado %d hace minutos",
    "PROJECTS_PROGRESS_TEXT_LOADING": "Cargando",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "Searching",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "¿Desea detener la importación?",
    "PROJECTS_TEXT_NOTHING_FOUND": "Nada encontrado",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "Crea un nuevo y bonito proyecto",
    "PROJECTS_TEXT_CAPTION": "Proyectos",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "Nombre del proyecto",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "Haga clic para cargar o, arrastre y suelte un archivo de proyecto aquí",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "Solo puedes subir un archivo a la vez",
    "PROJECTS_PREFIX_TEXT_EDITED": "Editado",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "¿Está seguro de que desea eliminar?",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "Este proyecto se eliminará inmediatamente. No se puede deshacer esta acción",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "Proyecto",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "Sólo se puede subir un archivo",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "Extensión de archivo no válida. Debería ser *.edl.zip",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "Introduce tu valor",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "Crear una carpeta para este proyecto en Media",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "Abrir Editor",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "Inténtalo de nuevo",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "Seleccionar velocidad de fotogramas para el proyecto",
    "PROJECTS_EXPORT_TITLE": "Exportar proyecto",
    "PROJECTS_EXPORT_INPROGRESS": "Exportación en progreso...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "Incluir medios",
    "PROJECTS_EXPORT_COMPLETE": "Exportación del proyecto completada",
    "PROJECTS_EXPORT_BTN_BROWSE": "Explorar",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "Descargar",
    "PROJECTS_EXPORT_BTN_COPY": "Copiar",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "Los archivos de los medios se reproducen aquí",
    "PREVIEW_BTN_PREV_TOOLTIP": "Anterior",
    "PREVIEW_BTN_PREV": "Anterior",
    "PREVIEW_BTN_PLAY_TOOLTIP": "Reproducir",
    "PREVIEW_BTN_PLAY": "Reproducir",
    "PREVIEW_BTN_STOP_TOOLTIP": "Stop",
    "PREVIEW_BTN_STOP": "Stop",
    "PREVIEW_BTN_NEXT_TOOLTIP": "Siguiente",
    "PREVIEW_BTN_NEXT": "Siguiente",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "Volumen",
    "PREVIEW_BTN_VOLUME": "Volumen",
    "PREVIEW_BTN_FULL_SCREEN": "Pantalla completa",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "Pantalla completa",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "Marcar en",
    "PREVIEW_BTN_MARK_IN": "Marcar en",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "Marcar",
    "PREVIEW_BTN_MARK_OUT": "Marcar",
    "PREVIEW_HEADER_TITLE_SOURCE": "Fuente",
    "PREVIEW_HEADER_TITLE_TIMELINE": "Cronología",
    "PREVIEW_MODE_AUTO": "Automático",
    "PREVIEW_MODE_SOURCE": "Fuente",
    "PREVIEW_MODE_TIMELINE": "Cronología",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "salida",
    "PUBLISH_SETTINGS_TITLE": "Título",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "Empezar a renderizar desde el primer clip",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "Duración",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "Codec",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "Frecuencia de imagen",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - Estéreo",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "Resolución",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "Audio",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "Tamaño (aproximado)",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "Se perderá el enlace al archivo de transcodificación",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "Ajustes de publicación",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "Renderización de vídeo completada",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "Introducir nombre",
    "SAVE_SETTINGS_BTN_RENDER_START": "Start",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "Inicio",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "Volver a mi cuenta",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "Volver a mi cuenta",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "Cancelar",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "Cancelar",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "Descargar",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "Descargar",
    "SAVE_SETTINGS_BTN_BROWSE": "Explorar",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "Explorar",
    "SAVE_SETTINGS_BTN_COPY": "Copiar",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "Copiar",
    "SAVE_SETTINGS_TEXT_TITLE": "Título",
    "SAVE_SETTINGS_TEXT_LOADING": "Cargando",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "Este vídeo se ha enviado a tu cuenta y debería estar disponible en breve",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "Todos",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "Búsqueda",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "Descripción",
    "SETTINGS_HEAD_BTN_DELETE": "Borrar",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "Borrar",
    "SETTINGS_HEAD_BTN_CLOSE": "Cerrar",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "Cerrar",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "Ancho",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "Altura",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "Mantener la relación de aspecto",
    "SETTINGS_BTN_FIT_TO_FRAME": "Ajustar al marco",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "Ajustar al marco",
    "SETTINGS_BTN_FILL_FRAME": "Rellenar marco",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "Rellenar marco",
    "SETTINGS_TEXT_AUTO": "auto",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "Transformación de texto none",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "Transformación de texto none",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "Transformación texto mayúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "Transformación de texto en mayúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "Transformación de texto en minúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "Transformación de texto en minúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "Transformación de texto mayúsculas",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "Transformación de texto mayúsculas",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "Familia de fuentes",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "Familia de fuentes",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "Tamaño de fuente",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "Tamaño de fuente",
    "SETTINGS_TEXT_BTN_BOLD": "Negrita",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "Negrita",
    "SETTINGS_TEXT_BTN_ITALIC": "Italic",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "Italic",
    "SETTINGS_TEXT_BTN_UNDERLINE": "Subrayado",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "Subrayado",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "Izquierda",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "Izquierda",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "Derecha",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "Derecha",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "Centro",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "Centro",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "Justificar",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "Justificar",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "Arriba",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "Arriba",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "Arriba a la izquierda",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "Arriba a la izquierda",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "Arriba a la derecha",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "Arriba a la derecha",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "Izquierda",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "Izquierda",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "Centro medio",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "Centro medio",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "Derecha",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "Derecha",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "Parte inferior izquierda",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "Parte inferior izquierda",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "Abajo",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "Abajo",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "Abajo derecha",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "Abajo derecha",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "Horizontal",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "Vertical",
    "SETTINGS_ROTATION_TEXT_ROTATE": "Gire",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "Introducir texto",
    "NABLET_HLD_PROJECT_POSTFIX": "momentos destacados",
    "NABLET_HLD_CAPTION": "Detectar momentos destacados",
    "NABLET_HLD_DETECTED_BTN_START": "Iniciar",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "Crear proyecto(s)",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "Cancelar",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "Conteo de intervalos detectados:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "¿Está seguro de que desea terminar la tarea?",
    "NABLET_HLD_DETECTED_BTN_NO": "No",
    "NABLET_HLD_DETECTED_BTN_YES": "Sí",
    "NB_HLD_STATUS_TEXT_LOADING": "Cargando",
    "NB_HLD_STATUS_TEXT_DETECTING": "Detectando",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "Descargando medios",
    "NB_HLD_STATUS_TEXT_ABORTED": "Abortado",
    "NABLET_HLD_TARGET_DURATION_SEC": "Duración objetivo en segundos",
    "NABLET_HLD_ONNX_MODEL": "Modelo Onnx",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "Esta sección está en desarrollo",
    "TEXT_FORM_CAPTION_SIZE": "Tamaño",
    "TEXT_FORM_CAPTION_OUTLINE": "Esquema",
    "TEXT_FORM_CAPTION_FONT": "Fuente",
    "TEXT_FORM_CAPTION_SHADOW": "Sombra",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "Ajustes de texto",
    "TEXT_FORM_TEXT_OFFSET": "Desplazamiento",
    "TEXT_FORM_TEXT_BLUR": "Desenfoque",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "Text",
    "TIMELINE_TEXT_DRAG_N_DROP": "Arrastrar y soltar",
    "TIMELINE_TEXT_ADD_LAYER": "+ Añadir capa",
    "TIMELINE_TOOLBAR_BTN_UNDO": "Deshacer",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "Deshacer",
    "TIMELINE_TOOLBAR_BTN_REDO": "Rehacer",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "Rehacer",
    "TIMELINE_TOOLBAR_BTN_DELETE": "Borrar",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "Borrar",
    "TIMELINE_TOOLBAR_BTN_COPY": "Copiar clip",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "Copiar clip",
    "TIMELINE_TOOLBAR_BTN_CUT": "Cortar clip(s)",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "Cortar clip(s)",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "Añadir marcador",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "Añadir marcador",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "Pegar",
    "TIMELINE_TOOLBAR_BTN_PASTE": "Pegar",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "Ajustes",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "Ajustes",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "Locución",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "Locución",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "Cámara web",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "Cámara web",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "Haz clic para cancelar",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "Haga clic para cancelar",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "Arrastrar y soltar",
    "TIMELINE_FOOTER_TEXT_DURATION": "Duración",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "Imposible publicar proyecto",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "Se requiere pista de vídeo",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "Guardar",
    "TIMELINE_FOOTER_BTN_SAVE": "Guardar",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "Guardar",
    "TIMELINE_FOOTER_BTN_PUBLISH": "Publicar",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "Publicar",
    "TIMELINE_SLIDER_POINTER": "Puntero deslizante",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "Puntero del deslizador",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "Ajustes de transición",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "Aplicar a todas las transiciones de la línea de tiempo",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "Duración de la transición:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "Transición",
    "used_in_projects": "El archivo multimedia no se ha eliminado porque está en uso en uno o más proyectos. Si continúa, el archivo no estará disponible en la línea de tiempo de los proyectos correspondientes y se eliminará de su cuenta.",
    "VOICE_OVER_TITLE_LAST_RECORDING": "Última grabación",
    "VOICE_OVER_BTN_RETAKE": "Retake",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "Retomar",
    "VOICE_OVER_BTN_DELETE_FILE": "Eliminar archivo",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "Eliminar archivo",
    "VOICE_OVER_BTN_START_RECORDING": "Iniciar grabación",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "Iniciar grabación",
    "VOICE_OVER_BTN_CONNECTION": "Conexión...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "Conexión...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "El archivo se eliminará de la línea de tiempo y de los medios",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "¿Estás seguro?",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "Narración de voz",
    "VOICE_OVER_TEXT_MIC": "Micrófono",
    "VOICE_OVER_LABEL_SELECT_MIC": "Seleccionar micrófono",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "Sin audio",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "Silenciar a linha de tempo durante a gravação",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "Deslizador de rebobinado al inicio tras la grabación",
    "VOICE_OVER_TEXT_COUNTDOWN": "Cuenta atrás",
    "VOICE_OVER_BTN_TEST_MIC": "Micrófono de prueba",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "Micrófono de prueba",
    "VOICE_OVER_BTN_RECORDING": "Grabando...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "Grabando...",
    "VOICE_OVER_BTN_PLAYING": "Reproduciendo...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "Reproduciendo...",
    "VOICE_OVER_BTN_YES": "Sí",
    "VOICE_OVER_BTN_YES_TOOLTIP": "Sí",
    "VOICE_OVER_BTN_NO": "No",
    "VOICE_OVER_BTN_NO_TOOLTIP": "No",
    "VOICE_OVER_BTN_STOP": "Stop",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "Stop",
    "VOICE_OVER_BTN_DELETE": "Borrar",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "Borrar",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "Ajuste automático",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "Otros ajustes",
    "WEBCAM_TEXT_RECORD_VIDEO": "Grabar vídeo",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "Disminuir zoom",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "Disminuir zoom",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "Aumentar zoom",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "Aumentar zoom",
    "SETTINGS_HOTKEYS": "Configuración de teclas de acceso rápido",
    "PRESET": "Preestablecido",
    "DEFAULT": "Por defecto",
    "ADD_NEW": "Añadir Nuevo",
    "SEARCH_COMMAND": "Comando de búsqueda",
    "COMMAND": "Mando",
    "SHORTCUTS": "Atajos",
    "ALL": "Todos",
    "TIMELINE": "Timeline",
    "TOOLS": "HERRAMIENTAS",
    "PREVIEW": "Vista previa",
    "KEYBOARD_MAPPING_PRESET": "Ajuste preestablecido de asignación de teclado",
    "ENTER_PRESET_NAME": "Introducir nombre de preselección",
    "THIS_PRESET_WILL_BE_DELETED": "Este preajuste se eliminará inmediatamente. No se puede deshacer esta acción",
    "RESET_ALL_TO_DEFAULTS": "Restablecer todo a los valores predeterminados",
    "HOTKEY_SETTINGS_UNDO": "Deshacer",
    "HOTKEY_SETTINGS_REDO": "Rehacer",
    "HOTKEY_SETTINGS_ZOOM_IN": "Acercar",
    "HOTKEY_SETTINGS_ZOOM_OUT": "Zoom out",
    "HOTKEY_SETTINGS_PREV_FRAME": "Fotograma anterior",
    "HOTKEY_SETTINGS_NEXT_FRAME": "Fotograma siguiente",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "Añadir a línea de tiempo",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "Aumentar volumen de clip",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "Disminuir volumen de clip",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "Ripple delete",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "Ripple trim previous",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "Ripple trim next",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "Seleccionar clips en la posición actual",
    "HOTKEY_SETTINGS_CUT_CLIPS": "Cortar clip(s)",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "Reproducir/Pausa",
    "HOTKEY_SETTINGS_DELETE": "Borrar",
    "HOTKEY_SETTINGS_COPY": "Copiar",
    "HOTKEY_SETTINGS_PASTE": "Pegar",
    "HOTKEY_SETTINGS_PLAY": "Reproducir",
    "HOTKEY_SETTINGS_PAUSE": "Pausa",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "Reproducir el intervalo resaltado",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "Puntos de entrada y salida claros",
    "HOTKEY_SETTINGS_MARK_IN": "Marcar punto de entrada",
    "HOTKEY_SETTINGS_MARK_OUT": "Marcar punto de salida",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "Buscar el punto de entrada",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "Buscar el punto de salida",
    "CLIP_SETTINGS": "Ajustes del clip",
    "MESSAGE_BOX_REMOVE_EXISTING": "Los archivos no son compatibles. Elimine los existentes",
    "MARKS_CONTEXT_MENU_MARK_IN": "Marcar entrada",
    "MARKS_CONTEXT_MENU_MARK_OUT": "Marcar salida",
    "MARKS_CONTEXT_MENU_CLEAR": "Borrar puntos de entrada/salida",
    "MEDIA_ERROR_build_thumbnails_error": "Error al crear miniaturas",
    "MEDIA_ERROR_unsupported_media": "Medios no compatibles",
    "MEDIA_ERROR_exceeded_file_size": "Tamaño de archivo excedido",
    "MEDIA_ERROR_exceeded_per_user_size": "Tamaño por usuario excedido",
    "MEDIA_ERROR_low_disk_space": "Poco espacio en disco",
    "MEDIA_ERROR_internal_server_error": "Error interno del servidor",
    "MEDIA_ERROR_network_error": "Error de red",
    "MEDIA_ERROR_not_found": "Archivo multimedia no encontrado",
  },
  ko: {
    "ADD_FOLDER_BTN": "폴더 추가",
    "ADD_FOLDER_BTN_TOOLTIP": "폴더 추가",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "버킷 이름",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "액세스 키 ID",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "비밀 키",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "가져오기 중",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "가져오기 오류",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "성공적으로 가져왔습니다",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "AWS 버킷에서 미디어 가져오기",
    "AMAZON_IMPORT_BTN_IMPORT": "가져오기",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "가져오기",
    "AUDIO_CAPTION_AUDIO": "오디오",
    "AUDIO_TEXT_FOUND_ITEMS": "항목을 찾았습니다",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "결과를 찾을 수 없습니다",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "검색 결과를 찾을 수 없는 것 같습니다.",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "오디오 설정",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "소리",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "전환",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "페이드인",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "페이드아웃",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "검색 장르",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "음악 검색",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "음향 효과 검색",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "검색",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "장르별 음악",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "음향 효과",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "기분별 음악",
    "AUDIO_MENU_ITEM_ALL": "모두",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "타임라인에서 자산을 제거하시겠습니까?",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "미디어에 따라 타임코드 형식 설정",
    "COLOR_PICKER_BTN_COLOR": "색상 선택",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "색상 선택",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "배경색",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "배경색",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "미디어 파일 다운로드 중",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "저장된 미디어 업로드 중",
    "COMMON_STATUS_TEXT_ABORTED": "중단됨",
    "COMMON_STATUS_TEXT_LOADING": "로드 중",
    "COMMON_STATUS_TEXT_RENDERING": "렌더링",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "취소",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "취소",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "사용자 아바타",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "기본 사용자 아바타 파일을 업로드할 수 있습니다.",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "아바타",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "색상 테마",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "사용자 지정 JS 스크립트",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "사용자 지정 텍스트",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "편집기의 텍스트를 사용자 지정할 수 있습니다",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "이 스크립트는 편집기가 로딩될 때 실행됩니다. 애널리틱스 등을 통합하는 데 사용할 수 있습니다.",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "JS 스크립트 편집",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "두 번째 강조 색상",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "기본 배경의 색상을 선택하세요.",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "컨트롤의 기본 강조 색상입니다. 여기에서 브랜드 색상을 선택하세요.",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "버튼의 기본 색상과 쌍을 이루는 컨트롤의 추가 색상.",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "타임라인의 오디오 파일에는 고유한 스타일이 있습니다.",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "로고 파일을 업로드할 수 있습니다.",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "보이스오버 파일에는 고유한 스타일이 있습니다.",
    "CUSTOMIZATION_LABEL_DISPLAYED": "표시됨",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "미리 정의된 테마 중 하나를 선택하거나 나만의 테마를 만드세요. CSS 파일을 편집하여 테마를 사용자 지정할 수 있습니다.",
    "CUSTOMIZATION_TITLE_BASIC_BG": "기본 배경",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "기본 버튼 링크",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "테마에 대한 CSS 파일 편집",
    "CUSTOMIZATION_TITLE_AUDIO": "오디오",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "보이스오버",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "텍스트 값 편집",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "텍스트 값 편집",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "텍스트 값 편집",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "형식 설명",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "형식 설명",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "모든 변경 사항 재설정",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "모든 변경 사항 재설정",
    "CUSTOMIZATION_BTN_CANCEL": "취소",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "취소",
    "CUSTOMIZATION_BTN_SAVE": "저장",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "저장",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "JS 스크립트 편집",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "JS 스크립트 편집",
    "CUSTOMIZATION_BTN_EDIT_CSS": "CSS 편집",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "CSS 편집",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "로고 업로드",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "로고 업로드",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "로고 삭제",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "로고 삭제",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "어두운 테마",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "라이트 테마",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "사용자 지정 테마",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "로고",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "로고",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "편집기 사용자 지정",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "로고 및 이름",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "회사 이름",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "편집자 이름",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "허용된 파일: ",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "업로드된 로고 없음",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "보기 설정",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "보기 설정",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "필터",
    "DUMMY_HEADER_BTN_FILTERS": "필터",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "프로젝트를 다른 이름으로 저장...",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "새 프로젝트",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "게시",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "편집기 사용자 지정",
    "EDITOR_MENU_MAIN_LOGS": "로그",
    "EDITOR_MENU_MAIN_SETTINGS": "설정",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "게시할 수 없습니다",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "트랙이 필요합니다",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "렌더링 설정",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "행동",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "저장",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "취소",
    "FILE_INPUT_BTN": "파일 입력",
    "FILE_INPUT_BTN_TOOLTIP": "파일 입력",
    "FORM_FIELD_LABEL_VOLUME": "볼륨",
    "folder_not_empty": "폴더가 비어 있지 않습니다. 파일은 프로젝트에서 사용할 수 있습니다. 계속 진행하면 해당 프로젝트의 타임라인에서 파일을 사용할 수 없습니다.",
    "HEAD_CONTROL_IMPORT_TEXT": "가져오기",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "미디어 가져오기",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "미디어가 있는 폴더 가져오기",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 버킷",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "구글 드라이브",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL 입력",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "프로젝트를 다른 이름으로 저장...",
    "HEADER_MENU_LIST_ITEM_DELETE": "삭제",
    "HEADER_MENU_LIST_ITEM_RENAME": "이름 바꾸기",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "라이선스 키 추가",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "로그아웃",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "편집기에서 나가시겠습니까?",
    "HEADER_BTN_BACK_TO_MY_ACC": "내 계정으로 돌아가기",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "내 계정으로 돌아가기",
    "HEADER_BTN_HELP": "도움말",
    "HEADER_BTN_HELP_TOOLTIP": "도움말",
    "IMAGES_TEXT_NATIVE_IMAGES": "네이티브 이미지",
    "IMAGES_TEXT_PEXELS": "펙셀",
    "IMAGES_TEXT_UNSPLASH": "언스플래시",
    "IMAGES_HEADER_CAPTION": "이미지",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "검색",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "컬렉션 선택",
    "IMAGES_TEXT_ALL_CATEGORIES": "모든 컬렉션",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "결과를 찾을 수 없습니다",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "검색 결과를 찾을 수 없는 것 같습니다.",
    "IMAGES_TAB_AI_GENERATED": "인공지능 생성",
    "IMAGES_TAB_SEARCH": "검색",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "URL에서 미디어 가져오기",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "파일로 연결하려면 여기를 클릭하세요",
    "IMPORT_FROM_URL_BTN_IMPORT": "가져오기",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "가져오기",
    "LAYER_CONTROL_BTN_MOVE_UP": "위로 이동",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "위로 이동",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "레이어 드래그",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "레이어 드래그",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "아래로 이동",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "아래로 이동",
    "LAYER_CONTROL_BTN_DELETE": "삭제",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "삭제",
    "LAYER_CONTROL_BTN_HIDE": "숨기기",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "숨기기",
    "LAYER_CONTROL_BTN_SHOW": "표시",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "표시",
    "LAYER_CONTROL_BTN_MUTE": "음소거",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "음소거",
    "LAYER_CONTROL_BTN_UNMUTE": "음소거 해제",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "음소거 해제",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "내 미디어",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "내 미디어",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "전환",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "전환",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "이미지",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "이미지",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "오디오",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "오디오",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "텍스트",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "텍스트",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "디자인",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "디자인",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "필터",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "필터",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "필터",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "이미지 설정",
    "MEDIA_FORM_CAPTION_POSITION": "위치",
    "MEDIA_FORM_CAPTION_ROTATION": "회전",
    "MEDIA_FORM_CAPTION_SETTINGS": "설정",
    "MEDIA_FORM_CAPTION_OUTLINE": "개요",
    "MEDIA_FORM_CAPTION_SHADOW": "그림자",
    "MEDIA_FORM_CAPTION_SIZE": "크기",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "불투명도",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "필터",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "필터",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "모두",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "비디오",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "이미지",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "오디오",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "폴더",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "음성 해설",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "보기 설정",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "보기 설정",
    "MEDIA_UPLOADER_TEXT_OR": "또는",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "미디어를 여기로 드래그 앤 드롭하세요",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "미디어 가져오기",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "가져오기",
    "MEDIA_PREVIEW_TEXT_RENDERING": "렌더링",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "해상도",
    "MEDIA_INFO_LIST_ITEM_CODEC": "코덱",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "프레임 속도",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - 스테레오",
    "MEDIA_INFO_LIST_ITEM_DURATION": "기간",
    "MEDIA_INFO_LIST_ITEM_TYPE": "유형",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "오디오",
    "MEDIA_INFO_LIST_ITEM_SIZE": "크기",
    "MEDIA_INFO_LIST_TASK_STATUS": "작업 상태",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "타임코드 시작",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "미디어",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "미디어 추가 로드",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "미디어 추가 로드",
    "MEDIA_BTN_LOADING": "로딩 중:",
    "MEDIA_BTN_LOADING_TOOLTIP": "로딩 중",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "크로마 키",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "위치",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "사운드",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "크기",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "미디어 설정",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "모든 설정 재설정",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "모든 설정 재설정",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "유사도",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "부드러움",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "키 색상 유출 감소",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "미리보기에서 색상 선택",
    "MEDIA_SORT_OPTION_DURATION": "기간",
    "MEDIA_SORT_OPTION_DATE": "날짜",
    "MEDIA_SORT_OPTION_SIZE": "크기",
    "MEDIA_SORT_OPTION_NAME": "이름",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "정렬 기준",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "주문",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "보기",
    "MEDIA_SORT_OPTION_ASCENDING": "오름차순",
    "MEDIA_SORT_OPTION_DESCENDING": "내림차순",
    "MEDIA_SORT_OPTION_LIST": "목록",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "큰 아이콘",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "내 녹화물",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "내 녹화물",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "내 미디어",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "내 미디어",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "선택한 항목을 제거하시겠습니까?",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "폴더 찾아보기",
    "MEDIA_CONFIRM_BTN_DELETE": "삭제",
    "MEDIA_CANCEL_BTN_DELETE": "취소",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "삭제",
    "MEDIA_CONFIRM_TITLE_DELETE": "삭제",
    "MEDIA_TEXT_LOADING": "로드 중",
    "MEDIA_BTN_ADD": "추가",
    "MEDIA_BTN_ADD_TOOLTIP": "추가",
    "MEDIA_PROGRESS_TEXT_LOADING": "로딩 중",
    "MEDIA_PROGRESS_TEXT_SAVING": "저장 중",
    "MEDIA_PROGRESS_TEXT_CONVERT": "변환",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "전송",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "분석",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "폴더 선택",
    "MEDIA_MENU_ITEM_MEDIA": "미디어",
    "MEDIA_MENU_ITEM_MOVE_TO": "이동",
    "MEDIA_MENU_ITEM_SETTINGS": "설정",
    "MEDIA_MENU_ITEM_NABLET_HLD": "하이라이트 감지",
    "MEDIA_MENU_ITEM_DELETE": "삭제",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "미디어 찾아보기",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "미디어 주소 복사",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "시작 타임코드를 사용하여 타임라인에 추가",
    "MEDIA_MENU_LABEL_MOVE_TO": "이동",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5 동영상 편집기",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "태블릿과 모바일은 아직 지원되지 않습니다 :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "동영상 편집기가 컴퓨터에서 잘 작동합니다",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "웹사이트로 돌아가기",
    "MODAL_WINDOW_LOGS_TITLE": "로그를 켜거나 끄려면 애플리케이션을 다시 시작해야 합니다",
    "MODAL_WINDOW_BUTTON_RESTART": "다시 시작",
    "NAVIGATION_TAB_EDITOR": "편집기",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "편집기",
    "NAVIGATION_TAB_PROJECTS": "프로젝트",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "프로젝트",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "연결이 복원될 때까지 편집할 수 없습니다.",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "마지막으로 저장된",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "분 전",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "바로 지금",
    "PROJECTS_BTN_NEW_PROJECTS": "새 프로젝트",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "새 프로젝트",
    "PROJECTS_BTN_NEW_PROJECT": "새 프로젝트",
    "PROJECTS_BTN_IMPORT": "가져오기",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "가져오기",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "예",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "취소",
    "PROJECTS_TITLE_TEXT_COPY": "복사",
    "PROJECTS_BTN_SAVE": "저장",
    "PROJECTS_BTN_SAVE_TOOLTIP": "저장",
    "PROJECTS_TITLE_TEXT_RENAME": "이름 바꾸기",
    "PROJECTS_TITLE_TEXT_DELETE": "삭제",
    "PROJECTS_TITLE_TEXT_EXPORT": "내보내기",
    "PROJECTS_CONFIRM_BTN_DELETE": "삭제",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "편집됨 %d 몇 시간 전",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "편집됨 방금",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "편집됨 %d 몇 분 전",
    "PROJECTS_PROGRESS_TEXT_LOADING": "로드 중",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "검색 중",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "가져오기를 중지하시겠습니까?",
    "PROJECTS_TEXT_NOTHING_FOUND": "아무것도 찾을 수 없음",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "아름다운 새 프로젝트 만들기",
    "PROJECTS_TEXT_CAPTION": "프로젝트",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "프로젝트 이름",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "업로드하려면 클릭하거나 프로젝트 파일을 여기로 끌어다 놓으세요",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "한 번에 하나의 파일만 업로드할 수 있습니다",
    "PROJECTS_PREFIX_TEXT_EDITED": "편집됨",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "정말 삭제하시겠습니까",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "이 프로젝트는 즉시 삭제됩니다. 이 작업은 취소할 수 없습니다.",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "프로젝트",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "하나의 파일만 업로드할 수 있습니다",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "잘못된 파일 확장자입니다. .edl.zip이어야 합니다.",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "값을 입력하세요",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "미디어에 이 프로젝트의 폴더를 만듭니다",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "편집기 열기",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "다시 시도",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "프로젝트의 프레임 속도 선택",
    "PROJECTS_EXPORT_TITLE": "프로젝트 내보내기",
    "PROJECTS_EXPORT_INPROGRESS": "진행 중인 내보내기...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "미디어 포함",
    "PROJECTS_EXPORT_COMPLETE": "프로젝트 내보내기가 완료되었습니다",
    "PROJECTS_EXPORT_BTN_BROWSE": "찾아보기",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "다운로드",
    "PROJECTS_EXPORT_BTN_COPY": "복사",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "미디어의 파일이 여기에서 재생됩니다",
    "PREVIEW_BTN_PREV_TOOLTIP": "이전",
    "PREVIEW_BTN_PREV": "이전",
    "PREVIEW_BTN_PLAY_TOOLTIP": "플레이",
    "PREVIEW_BTN_PLAY": "플레이",
    "PREVIEW_BTN_STOP_TOOLTIP": "중지",
    "PREVIEW_BTN_STOP": "중지",
    "PREVIEW_BTN_NEXT_TOOLTIP": "다음",
    "PREVIEW_BTN_NEXT": "다음",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "볼륨",
    "PREVIEW_BTN_VOLUME": "볼륨",
    "PREVIEW_BTN_FULL_SCREEN": "전체 화면",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "전체 화면",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "마크인",
    "PREVIEW_BTN_MARK_IN": "마크인",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "마크 아웃",
    "PREVIEW_BTN_MARK_OUT": "마크 아웃",
    "PREVIEW_HEADER_TITLE_SOURCE": "출처",
    "PREVIEW_HEADER_TITLE_TIMELINE": "타임라인",
    "PREVIEW_MODE_AUTO": "자동",
    "PREVIEW_MODE_SOURCE": "출처",
    "PREVIEW_MODE_TIMELINE": "타임라인",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "출력",
    "PUBLISH_SETTINGS_TITLE": "제목",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "첫 번째 클립부터 렌더링 시작",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "기간",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "코덱",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "프레임 속도",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - 스테레오",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "해상도",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "오디오",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "크기(대략적인)",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "트랜스코딩 파일에 대한 링크가 손실됩니다.",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "설정 게시",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "비디오 렌더링 완료",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "이름 입력",
    "SAVE_SETTINGS_BTN_RENDER_START": "시작",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "시작",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "내 계정으로 돌아가기",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "내 계정으로 돌아가기",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "취소",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "취소",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "다운로드",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "다운로드",
    "SAVE_SETTINGS_BTN_BROWSE": "찾아보기",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "찾아보기",
    "SAVE_SETTINGS_BTN_COPY": "복사",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "복사",
    "SAVE_SETTINGS_TEXT_TITLE": "제목",
    "SAVE_SETTINGS_TEXT_LOADING": "로드 중",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "이 동영상이 계정에 제출되었으며 곧 사용할 수 있습니다.",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "모두",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "검색",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "설명",
    "SETTINGS_HEAD_BTN_DELETE": "삭제",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "삭제",
    "SETTINGS_HEAD_BTN_CLOSE": "닫기",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "닫기",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "너비",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "높이",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "종횡비 유지",
    "SETTINGS_BTN_FIT_TO_FRAME": "프레임에 맞춤",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "프레임에 맞춤",
    "SETTINGS_BTN_FILL_FRAME": "프레임 채우기",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "프레임 채우기",
    "SETTINGS_TEXT_AUTO": "자동",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "텍스트 변형 없음",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "텍스트 변형 없음",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "텍스트 변환 대문자",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "텍스트 변환 대문자",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "텍스트 변환 소문자",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "텍스트 변환 소문자",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "텍스트 변환 대문자",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "텍스트 변형 대문자",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "폰트 패밀리",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "글꼴 패밀리",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "글꼴 크기 ",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "글꼴 크기",
    "SETTINGS_TEXT_BTN_BOLD": "굵게",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "굵게",
    "SETTINGS_TEXT_BTN_ITALIC": "이탤릭체",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "이탤릭체",
    "SETTINGS_TEXT_BTN_UNDERLINE": "밑줄",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "밑줄",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "왼쪽",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "왼쪽",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "오른쪽",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "오른쪽",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "가운데",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "가운데",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "맞춤",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "맞춤",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "상단",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "상단",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "왼쪽 상단",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "왼쪽 상단",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "오른쪽 상단",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "오른쪽 상단",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "왼쪽",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "왼쪽",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "가운데 가운데",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "가운데 가운데",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "오른쪽",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "오른쪽",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "왼쪽 하단",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "왼쪽 하단",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "하단",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "하단",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "하단",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "오른쪽 하단",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "가로",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "세로",
    "SETTINGS_ROTATION_TEXT_ROTATE": "회전",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "텍스트 입력",
    "NABLET_HLD_PROJECT_POSTFIX": "하이라이트",
    "NABLET_HLD_CAPTION": "하이라이트 감지",
    "NABLET_HLD_DETECTED_BTN_START": "시작",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "프로젝트 생성",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "취소",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "감지된 간격 수:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "작업을 종료하시겠습니까?",
    "NABLET_HLD_DETECTED_BTN_NO": "아니오",
    "NABLET_HLD_DETECTED_BTN_YES": "예",
    "NB_HLD_STATUS_TEXT_LOADING": "로딩 중",
    "NB_HLD_STATUS_TEXT_DETECTING": "감지 중",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "미디어 파일 다운로드 중",
    "NB_HLD_STATUS_TEXT_ABORTED": "중단됨",
    "NABLET_HLD_TARGET_DURATION_SEC": "목표 지속 시간(초)",
    "NABLET_HLD_ONNX_MODEL": "Onnx 모델",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "이 섹션은 개발 중입니다.",
    "TEXT_FORM_CAPTION_SIZE": "크기",
    "TEXT_FORM_CAPTION_OUTLINE": "개요",
    "TEXT_FORM_CAPTION_FONT": "폰트",
    "TEXT_FORM_CAPTION_SHADOW": "그림자",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "텍스트 설정",
    "TEXT_FORM_TEXT_OFFSET": "오프셋",
    "TEXT_FORM_TEXT_BLUR": "흐리게",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "텍스트",
    "TIMELINE_TEXT_DRAG_N_DROP": "드래그 앤 드롭",
    "TIMELINE_TEXT_ADD_LAYER": "+ 레이어 추가",
    "TIMELINE_TOOLBAR_BTN_UNDO": "실행 취소",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "실행 취소",
    "TIMELINE_TOOLBAR_BTN_REDO": "다시 실행",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "재실행",
    "TIMELINE_TOOLBAR_BTN_DELETE": "삭제",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "삭제",
    "TIMELINE_TOOLBAR_BTN_COPY": "클립 복사",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "클립 복사",
    "TIMELINE_TOOLBAR_BTN_CUT": "클립 잘라내기",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "클립 잘라내기",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "마커 추가",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "마커 추가",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "붙여넣기",
    "TIMELINE_TOOLBAR_BTN_PASTE": "붙여넣기",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "설정",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "설정",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "보이스 오버",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "보이스 오버",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "웹 카메라",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "웹 카메라",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "취소하려면 클릭",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "취소하려면 클릭",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "드래그 앤 드롭",
    "TIMELINE_FOOTER_TEXT_DURATION": "기간",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "프로젝트를 게시할 수 없습니다",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "비디오 트랙이 필요합니다",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "저장",
    "TIMELINE_FOOTER_BTN_SAVE": "저장",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "저장",
    "TIMELINE_FOOTER_BTN_PUBLISH": "게시",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "게시",
    "TIMELINE_SLIDER_POINTER": "슬라이더 포인터",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "슬라이더 포인터",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "전환 설정",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "타임라인의 모든 전환에 적용",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "전환 기간:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "전환",
    "used_in_projects": "미디어 파일이 하나 이상의 프로젝트에서 사용 중이기 때문에 삭제되지 않았습니다. 계속 진행하면 해당 프로젝트의 타임라인에서 파일을 사용할 수 없으며 계정에서 삭제됩니다.",
    "VOICE_OVER_TITLE_LAST_RECORDING": "마지막 녹음",
    "VOICE_OVER_BTN_RETAKE": "재촬영",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "재촬영",
    "VOICE_OVER_BTN_DELETE_FILE": "파일 삭제:",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "파일 삭제",
    "VOICE_OVER_BTN_START_RECORDING": "녹음 시작",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "녹음 시작",
    "VOICE_OVER_BTN_CONNECTION": "연결...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "연결...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "파일이 타임라인과 미디어에서 삭제됩니다.",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "확실해요?",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "음성 내레이션",
    "VOICE_OVER_TEXT_MIC": "마이크",
    "VOICE_OVER_LABEL_SELECT_MIC": "마이크 선택",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "오디오 없음",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "녹화 중 타임라인 음소거",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "녹화 후 시작 시 슬라이더 되감기",
    "VOICE_OVER_TEXT_COUNTDOWN": "카운트다운",
    "VOICE_OVER_BTN_TEST_MIC": "테스트 마이크",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "테스트 마이크",
    "VOICE_OVER_BTN_RECORDING": "녹음 중...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "녹음 중...",
    "VOICE_OVER_BTN_PLAYING": "재생 중...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "재생 중...",
    "VOICE_OVER_BTN_YES": "예",
    "VOICE_OVER_BTN_YES_TOOLTIP": "예",
    "VOICE_OVER_BTN_NO": "아니오",
    "VOICE_OVER_BTN_NO_TOOLTIP": "아니오",
    "VOICE_OVER_BTN_STOP": "중지",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "중지",
    "VOICE_OVER_BTN_DELETE": "삭제",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "삭제",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "자동 조정",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "기타 설정",
    "WEBCAM_TEXT_RECORD_VIDEO": "비디오 녹화",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "줌 감소",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "줌 감소",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "확대/축소 증가",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "확대/축소 증가",
    "SETTINGS_HOTKEYS": "단축키 설정",
    "PRESET": "프리셋",
    "DEFAULT": "기본값",
    "ADD_NEW": "새로 추가",
    "SEARCH_COMMAND": "검색 명령",
    "COMMAND": "명령",
    "SHORTCUTS": "바로 가기",
    "ALL": "모두",
    "TIMELINE": "타임라인",
    "TOOLS": "도구",
    "PREVIEW": "미리보기",
    "KEYBOARD_MAPPING_PRESET": "키보드 매핑 프리셋",
    "ENTER_PRESET_NAME": "사전 설정 이름 입력",
    "THIS_PRESET_WILL_BE_DELETED": "이 프리셋은 즉시 삭제됩니다. 이 작업은 실행 취소할 수 없습니다.",
    "RESET_ALL_TO_DEFAULTS": "모두 기본값으로 재설정",
    "HOTKEY_SETTINGS_UNDO": "실행 취소",
    "HOTKEY_SETTINGS_REDO": "재실행",
    "HOTKEY_SETTINGS_ZOOM_IN": "확대",
    "HOTKEY_SETTINGS_ZOOM_OUT": "줌아웃",
    "HOTKEY_SETTINGS_PREV_FRAME": "이전 프레임",
    "HOTKEY_SETTINGS_NEXT_FRAME": "다음 프레임",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "타임라인에 추가",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "클립 볼륨 높이기",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "클립 볼륨 줄이기",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "리플 삭제",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "리플 트림 이전",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "다음 리플 트림",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "현재 위치에서 클립 선택",
    "HOTKEY_SETTINGS_CUT_CLIPS": "클립 잘라내기",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "재생/일시정지",
    "HOTKEY_SETTINGS_DELETE": "삭제",
    "HOTKEY_SETTINGS_COPY": "복사",
    "HOTKEY_SETTINGS_PASTE": "붙여넣기",
    "HOTKEY_SETTINGS_PLAY": "플레이",
    "HOTKEY_SETTINGS_PAUSE": "일시 중지",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "강조 표시된 구간 재생",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "포인트 클리어 인 아웃",
    "HOTKEY_SETTINGS_MARK_IN": "포인트에 표시",
    "HOTKEY_SETTINGS_MARK_OUT": "마크 아웃 포인트",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "포인트 찾기",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "아웃 포인트 찾기",
    "CLIP_SETTINGS": "클립 설정",
    "MESSAGE_BOX_REMOVE_EXISTING": "파일이 호환되지 않습니다. 기존 파일을 제거하세요.",
    "MARKS_CONTEXT_MENU_MARK_IN": "마크 인",
    "MARKS_CONTEXT_MENU_MARK_OUT": "마크 아웃",
    "MARKS_CONTEXT_MENU_CLEAR": "클리어 인/아웃 포인트",
    "MEDIA_ERROR_build_thumbnails_error": "축소판 오류 빌드",
    "MEDIA_ERROR_unsupported_media": "지원되지 않는 미디어",
    "MEDIA_ERROR_exceeded_file_size": "파일 크기 초과",
    "MEDIA_ERROR_exceeded_per_user_size": "사용자당 크기 초과",
    "MEDIA_ERROR_low_disk_space": "디스크 공간 부족",
    "MEDIA_ERROR_internal_server_error": "내부 서버 오류",
    "MEDIA_ERROR_network_error": "네트워크 오류",
    "MEDIA_ERROR_not_found": "미디어 파일을 찾을 수 없음",
  },
  ru: {
    "ADD_FOLDER_BTN": "Добавить папку",
    "ADD_FOLDER_BTN_TOOLTIP": "Добавить папку",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Bucket Name",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "ID ключа доступа",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "Секретный ключ",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "Импортирование",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "Ошибка импорта",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "Импортировано успешно",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "Импортировать медиа из AWS Bucket",
    "AMAZON_IMPORT_BTN_IMPORT": "Импорт",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "Импорт",
    "AUDIO_CAPTION_AUDIO": "Аудио",
    "AUDIO_TEXT_FOUND_ITEMS": "Найденные элементы",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "Результаты не найдены",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "Ничего не найдено по вашему запросу",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "Настройки аудио",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "Звук",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "Переход",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "Проявление",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "Затухание",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "Поиск по жанру",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "Поиск музыки",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "Поиск звуковых эффектов",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "Искать в",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "Музыка по жанрам",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "Звуковые эффекты",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "Музыка по настроению",
    "AUDIO_MENU_ITEM_ALL": "Все",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "Удалить элемент с временной шкалы?",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "Установить формата таймкода в зависимости от носителя",
    "COLOR_PICKER_BTN_COLOR": "Выберите цвет",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "Выберите цвет",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "Цвет фона",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "Цвет фона",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "Загрузка медиафайлов",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "Загрузка сохраненных медиа",
    "COMMON_STATUS_TEXT_ABORTED": "Прервано",
    "COMMON_STATUS_TEXT_LOADING": "Загрузка",
    "COMMON_STATUS_TEXT_RENDERING": "Рендеринг",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "Отмена",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "Отмена",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "Аватар пользователя",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "Вы можете загрузить файл с аватаром пользователя по умолчанию.",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "Аватар",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "Цветовая тема",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "Пользовательский JS-скрипт",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "Пользовательский текст",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "Вы можете настроить текст редактора",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "Этот скрипт запускается при загрузке редактора. Его можно использовать для интеграции аналитики и т. д.",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "Редактировать JS-скрипт",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "Второй акцентный цвет",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "Выберите цвет основного фона",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "Основной акцентный цвет для элементов управления. Укажите здесь свой фирменный цвет.",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "Дополнительный цвет элементов управления в паре с основным цветом кнопок.",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "Аудиофайл на временной шкале имеет свой собственный стиль",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "Вы можете загрузить файл с вашим логотипом",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "Файл озвучки имеет свой собственный стиль",
    "CUSTOMIZATION_LABEL_DISPLAYED": "отображается",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "Выберите одну из предопределенных тем или создайте свою собственную. Вы можете отредактировать CSS-файл, чтобы настроить свою тему.",
    "CUSTOMIZATION_TITLE_BASIC_BG": "Основной фон",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "Основные кнопки, ссылки",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "Редактировать CSS-файл для темы",
    "CUSTOMIZATION_TITLE_AUDIO": "Аудио",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "Озвучка",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "Изменить текстовые значения",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "Изменить текстовые значения",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "Изменить текстовые значения",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "Описание формата",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "Описание формата",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "Сбросить все изменения",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "Сбросить все изменения",
    "CUSTOMIZATION_BTN_CANCEL": "Отмена",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "Отмена",
    "CUSTOMIZATION_BTN_SAVE": "Сохранить",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "Сохранить",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "Редактировать JS-скрипт",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "Редактировать JS-скрипт",
    "CUSTOMIZATION_BTN_EDIT_CSS": "Редактировать CSS",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "Редактировать CSS",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "Загрузить логотип",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "Загрузить логотип",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "Удалить логотип",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "Удалить логотип",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "Темная тема",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "Светлая тема",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "Пользовательская тема",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "Логотип",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "Логотип",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "Настроить редактор",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "Логотип и название",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "Название компании",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "Название редактора",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "Принятые файлы:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "Нет загруженного логотипа",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "Просмотр настроек",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "Просмотр настроек",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "Фильтры",
    "DUMMY_HEADER_BTN_FILTERS": "Фильтры",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Сохранить проект как...",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "Новый проект",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "Опубликовать",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "Настроить редактор",
    "EDITOR_MENU_MAIN_LOGS": "Логирование",
    "EDITOR_MENU_MAIN_SETTINGS": "Настройки",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "Невозможно опубликовать",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "Треки обязательны",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "Настройки рендеринга",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "Поведение",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "Сохранить",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "Отмена",
    "FILE_INPUT_BTN": "Ввод файла",
    "FILE_INPUT_BTN_TOOLTIP": "Ввод файла",
    "FORM_FIELD_LABEL_VOLUME": "Громкость",
    "folder_not_empty": "Папка не пуста. Файлы могут использоваться в проектах. Если вы продолжите, файлы на временной шкале в соответствующих проектах станут недоступны.",
    "HEAD_CONTROL_IMPORT_TEXT": "Импорт",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "Импорт медиа",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "Импортировать папку с медиа",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "Ввод URL",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "Сохранить проект как...",
    "HEADER_MENU_LIST_ITEM_DELETE": "Удалить",
    "HEADER_MENU_LIST_ITEM_RENAME": "Переименовать",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "Добавить лицензионный ключ",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "Выйти из системы",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "Вы хотите покинуть редактор?",
    "HEADER_BTN_BACK_TO_MY_ACC": "Вернуться в мой аккаунт",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "Вернуться в мой аккаунт",
    "HEADER_BTN_HELP": "Помощь",
    "HEADER_BTN_HELP_TOOLTIP": "Помощь",
    "IMAGES_TEXT_NATIVE_IMAGES": "Встроенные изображения",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "Изображения",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "Искать в",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "Выберите коллекцию",
    "IMAGES_TEXT_ALL_CATEGORIES": "Все коллекции",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "Ничего не найдено",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "Ничего не найдено по вашему запросу.",
    "IMAGES_TAB_AI_GENERATED": "Сгенерировано ИИ",
    "IMAGES_TAB_SEARCH": "Поиск",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "Импортировать медиа из URL",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "Введите ссылку на файл здесь",
    "IMPORT_FROM_URL_BTN_IMPORT": "Импорт",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "Импорт",
    "LAYER_CONTROL_BTN_MOVE_UP": "Передвинуть вверх",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "Передвинуть вверх",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "Передвинуть слой",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "Передвинуть слой",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "Передвинуть вниз",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "Передвинуть вниз",
    "LAYER_CONTROL_BTN_DELETE": "Удалить",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "Удалить",
    "LAYER_CONTROL_BTN_HIDE": "Скрыть",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "Скрыть",
    "LAYER_CONTROL_BTN_SHOW": "Показать",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "Показать",
    "LAYER_CONTROL_BTN_MUTE": "Выкл. звук",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "Выкл. звук",
    "LAYER_CONTROL_BTN_UNMUTE": "Вкл. звук",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "Вкл. звук",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "Мои медиа",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "Мои медиа",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "Переходы",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "Переходы",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "Изображения",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "Изображения",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "Аудио",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "Аудио",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "Текст",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "Текст",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "Дизайн",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "Дизайн",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "Фильтры",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "Фильтры",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "Фильтры",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "Настройки изображения",
    "MEDIA_FORM_CAPTION_POSITION": "Положение",
    "MEDIA_FORM_CAPTION_ROTATION": "Поворот",
    "MEDIA_FORM_CAPTION_SETTINGS": "Настройки",
    "MEDIA_FORM_CAPTION_OUTLINE": "Обводка",
    "MEDIA_FORM_CAPTION_SHADOW": "Тень",
    "MEDIA_FORM_CAPTION_SIZE": "Размер",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "Непрозрачность",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "Фильтр",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "Фильтр",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "Все",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "Видео",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "Изображение",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "Аудио",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "Папка",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "Озвучка",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "Настройки просмотра",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "Настройки просмотра",
    "MEDIA_UPLOADER_TEXT_OR": "или",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "Перетащите сюда ваши медиафайлы",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "импортировать медиа",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "Импортирование",
    "MEDIA_PREVIEW_TEXT_RENDERING": "Рендеринг",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "Разрешение",
    "MEDIA_INFO_LIST_ITEM_CODEC": "Кодеки",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "Частота кадров",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
    "MEDIA_INFO_LIST_ITEM_DURATION": "Длительность",
    "MEDIA_INFO_LIST_ITEM_TYPE": "Тип",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "Аудио",
    "MEDIA_INFO_LIST_ITEM_SIZE": "Размер",
    "MEDIA_INFO_LIST_TASK_STATUS": "Статус задачи",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "Старт таймкод",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "Медиа",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "Загрузить больше медиа",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "Загрузить больше медиа",
    "MEDIA_BTN_LOADING": "Загрузка",
    "MEDIA_BTN_LOADING_TOOLTIP": "Загрузка",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "Хромакей",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "Положение",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "Звук",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "РАЗМЕР",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "Настройки медиа",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "Сбросить все настройки",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "Сбросить все настройки",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "Сходство",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "Плавность",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "Уменьшение переливов ключевых цветов",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "Выберите цвет из превью",
    "MEDIA_SORT_OPTION_DURATION": "Длительность",
    "MEDIA_SORT_OPTION_DATE": "Дата",
    "MEDIA_SORT_OPTION_SIZE": "Размер",
    "MEDIA_SORT_OPTION_NAME": "Имя",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "Сортировать по",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "Порядок",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "Вид",
    "MEDIA_SORT_OPTION_ASCENDING": "По возрастанию",
    "MEDIA_SORT_OPTION_DESCENDING": "По убыванию",
    "MEDIA_SORT_OPTION_LIST": "Список",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "Большие иконки",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "Мои записи",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "Мои записи",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "Мои медиа",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "Мои медиа",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "Вы уверены, что хотите удалить выбранные элементы?",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "Просмотр папки",
    "MEDIA_CONFIRM_BTN_DELETE": "Удалить",
    "MEDIA_CANCEL_BTN_DELETE": "Отмена",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "Удалить",
    "MEDIA_CONFIRM_TITLE_DELETE": "Удалить",
    "MEDIA_TEXT_LOADING": "Загрузка",
    "MEDIA_BTN_ADD": "Добавить",
    "MEDIA_BTN_ADD_TOOLTIP": "Добавить",
    "MEDIA_PROGRESS_TEXT_LOADING": "Загрузка",
    "MEDIA_PROGRESS_TEXT_SAVING": "Сохранение",
    "MEDIA_PROGRESS_TEXT_CONVERT": "Конвертировать",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "Передача",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "Анализ",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "Выбрать папку",
    "MEDIA_MENU_ITEM_MEDIA": "Медиа",
    "MEDIA_MENU_ITEM_MOVE_TO": "Переместить в",
    "MEDIA_MENU_ITEM_SETTINGS": "Настройки",
    "MEDIA_MENU_ITEM_NABLET_HLD": "Обнаружить моменты",
    "MEDIA_MENU_ITEM_DELETE": "Удалить",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "Открыть папку медиа файла",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "Копировать адрес ссылки",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "Добавить клип на таймлайн используя тайм-код",
    "MEDIA_MENU_LABEL_MOVE_TO": "Переместить в",
    "MEDIA_CONFIRM_BTN_OK": "OK",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "OK",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5 Video Editor",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "Планшеты и мобильные устройства пока не поддерживаются :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "Видеоредактор хорошо работает на компьютере",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "Вернуться на сайт",
    "MODAL_WINDOW_LOGS_TITLE": "Чтобы включить или выключить логи, необходимо перезапустить приложение",
    "MODAL_WINDOW_BUTTON_RESTART": "Перезапустить",
    "NAVIGATION_TAB_EDITOR": "Редактор",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "Редактор",
    "NAVIGATION_TAB_PROJECTS": "Проекты",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "Проекты",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "Редактирование недоступно до восстановления соединения",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "Последнее сохранение",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "мин. назад",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "только что",
    "PROJECTS_BTN_NEW_PROJECTS": "Новый проект",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "Новый проект",
    "PROJECTS_BTN_NEW_PROJECT": "Новый проект",
    "PROJECTS_BTN_IMPORT": "Импорт",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "Импорт",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "Да",
    "PROJECTS_IMPORT_BTN_OK": "OK",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "OK",
    "PROJECTS_IMPORT_TEXT_CANCEL": "Отмена",
    "PROJECTS_TITLE_TEXT_COPY": "Копировать",
    "PROJECTS_BTN_SAVE": "Сохранить",
    "PROJECTS_BTN_SAVE_TOOLTIP": "Сохранить",
    "PROJECTS_TITLE_TEXT_RENAME": "Переименовать",
    "PROJECTS_TITLE_TEXT_DELETE": "Удалить",
    "PROJECTS_TITLE_TEXT_EXPORT": "Экспорт",
    "PROJECTS_CONFIRM_BTN_DELETE": "Удалить",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "Отредактировано %d ч. назад",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "Отредактировано сейчас",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "Отредактировано %d мин. назад",
    "PROJECTS_PROGRESS_TEXT_LOADING": "Загрузка",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "Поиск",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "Вы хотите прекратить импорт?",
    "PROJECTS_TEXT_NOTHING_FOUND": "Ничего не найдено",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "Создать новый красивый проект",
    "PROJECTS_TEXT_CAPTION": "Проекты",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "Название проекта",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "Нажмите, чтобы загрузить, или перетащите сюда файл проекта",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "Вы можете загружать только один файл за раз",
    "PROJECTS_PREFIX_TEXT_EDITED": "Отредактировано",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "Вы уверены, что хотите удалить",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "Этот проект будет удален немедленно. Вы не сможете отменить это действие.",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "Проект",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "Можно загрузить только один файл",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "Неверное расширение файла. Должно быть *.edl.zip",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "Введите ваше значение",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "Создать папку для этого проекта в Media",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "Открыть редактор",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "Попробуйте еще раз",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "Выберите частоту кадров для проекта",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "Файлы из медиа воспроизводятся здесь",
    "PROJECTS_EXPORT_TITLE": "Экспорт проекта",
    "PROJECTS_EXPORT_INPROGRESS": "Экспорт в процессе...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "Включить медиа",
    "PROJECTS_EXPORT_COMPLETE": "Экспорт проекта завершён",
    "PROJECTS_EXPORT_BTN_BROWSE": "Обзор",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "Скачать",
    "PROJECTS_EXPORT_BTN_COPY": "Копировать",
    "PREVIEW_BTN_PREV_TOOLTIP": "Предыдущий",
    "PREVIEW_BTN_PREV": "Предыдущий",
    "PREVIEW_BTN_PLAY_TOOLTIP": "Воспроизвести",
    "PREVIEW_BTN_PLAY": "Воспроизвести",
    "PREVIEW_BTN_STOP_TOOLTIP": "Стоп",
    "PREVIEW_BTN_STOP": "Стоп",
    "PREVIEW_BTN_NEXT_TOOLTIP": "Следующий",
    "PREVIEW_BTN_NEXT": "Следующий",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "Громкость",
    "PREVIEW_BTN_VOLUME": "Громкость",
    "PREVIEW_BTN_FULL_SCREEN": "Полный экран",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "Полный экран",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "Отметить вход",
    "PREVIEW_BTN_MARK_IN": "Отметить вход",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "Отметить выход",
    "PREVIEW_BTN_MARK_OUT": "Отметить выход",
    "PREVIEW_HEADER_TITLE_SOURCE": "Исходный файл",
    "PREVIEW_HEADER_TITLE_TIMELINE": "Элемент временной шкалы",
    "PREVIEW_MODE_AUTO": "Авто",
    "PREVIEW_MODE_SOURCE": "Исходный файл",
    "PREVIEW_MODE_TIMELINE": "Таймлайн",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "результат",
    "PUBLISH_SETTINGS_TITLE": "Название",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "Начать рендеринг с первого клипа",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "Длительность",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "Кодек",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "Частота кадров",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "Hz - Stereo",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "Разрешение",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "Аудио",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "Размер (приблизительно)",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "Ссылка на файл транскодирования будет потеряна",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "Настройки публикации",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "Рендеринг видео завершен",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "Введите имя",
    "SAVE_SETTINGS_BTN_RENDER_START": "Старт",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "Старт",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "Вернуться в мой аккаунт",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "Вернуться в мой аккаунт",
    "SAVE_SETTINGS_BTN_OK": "OK",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "OK",
    "SAVE_SETTINGS_BTN_CANCEL": "Отмена",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "Отмена",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "Загрузить",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "Загрузить",
    "SAVE_SETTINGS_BTN_BROWSE": "Обзор",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "Обзор",
    "SAVE_SETTINGS_BTN_COPY": "Копировать",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "Копировать",
    "SAVE_SETTINGS_TEXT_TITLE": "Название",
    "SAVE_SETTINGS_TEXT_LOADING": "Загрузка",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "Это видео было отправлено на ваш аккаунт и скоро будет доступно.",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "Все",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "Поиск",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "Описание",
    "SETTINGS_HEAD_BTN_DELETE": "Удалить",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "Удалить",
    "SETTINGS_HEAD_BTN_CLOSE": "Закрыть",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "Закрыть",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "Ширина",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "Высота",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "Сохранять соотношение сторон",
    "SETTINGS_BTN_FIT_TO_FRAME": "Подогнать под рамку",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "Подогнать под рамку",
    "SETTINGS_BTN_FILL_FRAME": "Рамка заполнения",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "Заполнить рамку",
    "SETTINGS_TEXT_AUTO": "авто",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "Нет преобразования текста",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "Нет преобразования текста",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "Преобразование текста в верхний регистр",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "Преобразование текста в верхний регистр",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "Преобразование текста в нижний регистр",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "Преобразование текста в нижний регистр",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "Каждое слово с заглавной буквы",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "Каждое слово с заглавной буквы",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "Семейство шрифтов",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "Семейство шрифтов",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "Размер шрифта",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "Размер шрифта",
    "SETTINGS_TEXT_BTN_BOLD": "Жирный",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "Жирный",
    "SETTINGS_TEXT_BTN_ITALIC": "Курсив",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "Курсив",
    "SETTINGS_TEXT_BTN_UNDERLINE": "Подчеркивание",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "Подчеркивание",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "Слева",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "Слева",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "Справа",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "Справа",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "По центру",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "По центру",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "По ширине",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "По ширине",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "Сверху",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "Сверху",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "Слева сверху",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "Слева сверху",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "Справа сверху",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "Справа сверху",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "Слева",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "Слева",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "В центре",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "в центре",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "Справа",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "Справа",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "Слева снизу",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "Слева снизу",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "Снизу",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "Снизу",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "Справа снизу",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "Справа снизу",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "Горизонтально",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "Вертикально",
    "SETTINGS_ROTATION_TEXT_ROTATE": "Поворот",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "Введите текст",
    "NABLET_HLD_PROJECT_POSTFIX": "моменты",
    "NABLET_HLD_CAPTION": "Обнаружить моменты",
    "NABLET_HLD_DETECTED_BTN_START": "Начать",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "Создать проект(ы)",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "Отмена",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "Обнаруженное количество интервалов:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "Вы уверены, что хотите завершить задачу?",
    "NABLET_HLD_DETECTED_BTN_NO": "Нет",
    "NABLET_HLD_DETECTED_BTN_YES": "Да",
    "NB_HLD_STATUS_TEXT_LOADING": "Загрузка",
    "NB_HLD_STATUS_TEXT_DETECTING": "Обнаружение",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "Загрузка медиафайлов",
    "NB_HLD_STATUS_TEXT_ABORTED": "Прервано",
    "NABLET_HLD_TARGET_DURATION_SEC": "Целевая длительность (секунды)",
    "NABLET_HLD_ONNX_MODEL": "Модель Onnx",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "Этот раздел находится в разработке",
    "TEXT_FORM_CAPTION_SIZE": "Размер",
    "TEXT_FORM_CAPTION_OUTLINE": "Обводка",
    "TEXT_FORM_CAPTION_FONT": "Шрифт",
    "TEXT_FORM_CAPTION_SHADOW": "Тень",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "Настройки текста",
    "TEXT_FORM_TEXT_OFFSET": "Отступ",
    "TEXT_FORM_TEXT_BLUR": "Размытие",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "Текст",
    "TIMELINE_TEXT_DRAG_N_DROP": "Перетащить",
    "TIMELINE_TEXT_ADD_LAYER": "+ Добавить слой",
    "TIMELINE_TOOLBAR_BTN_UNDO": "Отменить",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "Отменить",
    "TIMELINE_TOOLBAR_BTN_REDO": "Повторить",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "Повторить",
    "TIMELINE_TOOLBAR_BTN_DELETE": "Удалить",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "Удалить",
    "TIMELINE_TOOLBAR_BTN_COPY": "Копировать клип",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "Копировать клип",
    "TIMELINE_TOOLBAR_BTN_CUT": "Вырезать клип(ы)",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "Вырезать клип(ы)",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "Добавить маркер",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "Добавить маркер",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "Вставить",
    "TIMELINE_TOOLBAR_BTN_PASTE": "Вставить",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "Настройки",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "Настройки",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "Озвучка",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "Озвучка",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "Веб-камера",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "Веб-камера",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "Нажмите, чтобы отменить",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "Нажмите, чтобы отменить",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "Перетащить",
    "TIMELINE_FOOTER_TEXT_DURATION": "Длительность",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "Невозможно опубликовать проект",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "Требуется видеодорожка",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "Сохранить",
    "TIMELINE_FOOTER_BTN_SAVE": "Сохранить",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "Сохранить",
    "TIMELINE_FOOTER_BTN_PUBLISH": "Опубликовать",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "Опубликовать",
    "TIMELINE_SLIDER_POINTER": "Указатель слайдера",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "Указатель слайдера",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "Настройки перехода",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "Применить ко всем переходам на временной шкале",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "Продолжительность перехода:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "Переход",
    "used_in_projects": "Медиа файл не был удален, так как используется в проектах. Если вы продолжите файл на таймлайнах соответствующих проектов станет недоступен и будет удален с вашего аккаунта.",
    "VOICE_OVER_TITLE_LAST_RECORDING": "Последняя запись",
    "VOICE_OVER_BTN_RETAKE": "Переозвучить",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "Переозвучить",
    "VOICE_OVER_BTN_DELETE_FILE": "Удалить файл",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "Удалить файл",
    "VOICE_OVER_BTN_START_RECORDING": "Начать запись",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "Начать запись",
    "VOICE_OVER_BTN_CONNECTION": "Соединение...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "Подключение...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "Файл будет удален с временной шкалы и из медиа",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "Вы уверены?",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "Закадровый голос",
    "VOICE_OVER_TEXT_MIC": "Микрофон",
    "VOICE_OVER_LABEL_SELECT_MIC": "Выберите микрофон",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "Нет звука",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "Отключить звук на временной шкале во время записи",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "Установить ползунок в начало после записи",
    "VOICE_OVER_TEXT_COUNTDOWN": "Обратный отсчет",
    "VOICE_OVER_BTN_TEST_MIC": "Тест микрофона",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "Тест микрофона",
    "VOICE_OVER_BTN_RECORDING": "Запись...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "Запись...",
    "VOICE_OVER_BTN_PLAYING": "Воспроизводится...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "Воспроизводится...",
    "VOICE_OVER_BTN_YES": "Да",
    "VOICE_OVER_BTN_YES_TOOLTIP": "Да",
    "VOICE_OVER_BTN_NO": "Нет",
    "VOICE_OVER_BTN_NO_TOOLTIP": "Нет",
    "VOICE_OVER_BTN_STOP": "Стоп",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "Стоп",
    "VOICE_OVER_BTN_DELETE": "Удалить",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "Удалить",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "Автоматическая регулировка",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "Прочие настройки",
    "WEBCAM_TEXT_RECORD_VIDEO": "Запись видео",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "Уменьшить масштаб",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "Уменьшить масштаб",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "Увеличить масштаб",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "Увеличить масштаб",
    "SETTINGS_HOTKEYS": "Настройки горячих клавиш",
    "PRESET": "Пресет",
    "DEFAULT": "По умолчанию",
    "ADD_NEW": "Добавить новый",
    "SEARCH_COMMAND": "Команда поиска",
    "COMMAND": "Комманда",
    "SHORTCUTS": "Ярлыки",
    "ALL": "Все",
    "TIMELINE": "Временная шкала",
    "TOOLS": "Инструменты",
    "PREVIEW": "Просмотр",
    "KEYBOARD_MAPPING_PRESET": "Пресет раскладки клавиатуры",
    "ENTER_PRESET_NAME": "Введите имя пресета",
    "THIS_PRESET_WILL_BE_DELETED": "Этот пресет будет немедленно удален. Вы не сможете отменить это действие.",
    "RESET_ALL_TO_DEFAULTS": "Сбросить все настройки по умолчанию",
    "HOTKEY_SETTINGS_UNDO": "Отменить",
    "HOTKEY_SETTINGS_REDO": "Повторить",
    "HOTKEY_SETTINGS_ZOOM_IN": "Увеличить масштаб",
    "HOTKEY_SETTINGS_ZOOM_OUT": "Уменьшить масштаб",
    "HOTKEY_SETTINGS_PREV_FRAME": "Предыдущий кадр",
    "HOTKEY_SETTINGS_NEXT_FRAME": "Следующий кадр",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "Добавить на временную шкалу",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "Увеличить громкость клипа",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "Уменьшить громкость клипа",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "Удалить со сдвигом",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "Отрезать предыдущее со сдвигом",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "Отрезать следующее со сдвигом",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "Выбрать клипы в текущей позиции",
    "HOTKEY_SETTINGS_CUT_CLIPS": "Вырезать клип(ы)",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "Воспроизведение/Пауза",
    "HOTKEY_SETTINGS_DELETE": "Удалить",
    "HOTKEY_SETTINGS_COPY": "Копировать",
    "HOTKEY_SETTINGS_PASTE": "Вставить",
    "HOTKEY_SETTINGS_PLAY": "Воспроизведение",
    "HOTKEY_SETTINGS_PAUSE": "Пауза",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "Воспроизвести выделенный интервал",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "Удалить выбранные точки клипа",
    "HOTKEY_SETTINGS_MARK_IN": "Установить начальную точку клипа",
    "HOTKEY_SETTINGS_MARK_OUT": "Установить конечную точку клипа",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "Переместить слайдер в начало отрезка клипа",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "Переместить слайдер в конец отрезка клипа",
    "CLIP_SETTINGS": "Настройки клипа",
    "MESSAGE_BOX_REMOVE_EXISTING": "Файлы несовместимы. Пожалуйста, удалите существующие",
    "MARKS_CONTEXT_MENU_MARK_IN": "Отметить начало",
    "MARKS_CONTEXT_MENU_MARK_OUT": "Отметить конец",
    "MARKS_CONTEXT_MENU_CLEAR": "Очистить точки начало/конец",
    "MEDIA_ERROR_build_thumbnails_error": "Ошибка создания миниатюр",
    "MEDIA_ERROR_unsupported_media": "Неподдерживаемый файл",
    "MEDIA_ERROR_exceeded_file_size": "Превышен размер файла",
    "MEDIA_ERROR_exceeded_per_user_size": "Превышен размер на пользователя",
    "MEDIA_ERROR_low_disk_space": "Мало места на диске",
    "MEDIA_ERROR_internal_server_error": "Внутренняя ошибка сервера",
    "MEDIA_ERROR_network_error": "Ошибка сети",
    "MEDIA_ERROR_not_found": "Медиафайл не найден",
  },
  zh: {
    "ADD_FOLDER_BTN": "添加文件夹",
    "ADD_FOLDER_BTN_TOOLTIP": "添加文件夹",
    "AMAZON_IMPORT_PLACEHOLDER_BUCKET_NAME": "Bucket名称",
    "AMAZON_IMPORT_PLACEHOLDER_ACCESS_ID_KEY": "访问密钥 ID",
    "AMAZON_IMPORT_PLACEHOLDER_SECRET_KEY": "秘钥",
    "AMAZON_IMPORT_PROGRESS_TEXT_IMPORTING": "正在导入",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORT_ERROR": "导入错误",
    "AMAZON_IMPORT_CONDITION_TEXT_IMPORTED_SUCCESSFULLY": "导入成功",
    "AMAZON_IMPORT_HEAD_TEXT_IMPORT_MEDIA_FROM_AWS": "从 AWS Bucket 导入媒体",
    "AMAZON_IMPORT_BTN_IMPORT": "导入",
    "AMAZON_IMPORT_BTN_IMPORT_TOOLTIP": "导入",
    "AUDIO_CAPTION_AUDIO": "音频",
    "AUDIO_TEXT_FOUND_ITEMS": "找到的项目",
    "AUDIO_TEXT_NO_RESULTS_FOUND": "未找到结果",
    "AUDIO_TEXT_CANNOT_FIND_RESULTS": "看来我们无法根据您的搜索找到任何结果",
    "AUDIO_SETTINGS_FORM_TITLE_SETTINGS_AUDIO": "音频设置",
    "AUDIO_SETTINGS_FORM_CAPTION_SOUND": "声音",
    "AUDIO_SETTINGS_FORM_CAPTION_TRANSITION": "过渡",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_IN": "淡入",
    "AUDIO_SETTINGS_FORM_LABEL_FADE_OUT": "淡出",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_GENRE": "搜索类型",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_MUSIC": "搜索音乐",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_SOUND_EFFECTS": "搜索音效",
    "AUDIO_INPUT_PLACEHOLDER_SEARCH_IN": "搜索",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_GENRE": "音乐流派",
    "AUDIO_LIST_CATEGORIES_SOUND_EFFECTS": "音效",
    "AUDIO_LIST_CATEGORIES_MUSIC_BY_MOOD": "按情绪分类的音乐",
    "AUDIO_MENU_ITEM_ALL": "全部",
    "BASE_ASSETS_SETTINGS_TEXT_REMOVE_ASSETS_FROM_TIMELINE": "从时间轴移除资产？",
    "BEHAVIOUR_SETTINGS_SET_TIMECODE_FORMAT_BASED_ON_MEDIA": "根據媒體設定時間碼格式",
    "COLOR_PICKER_BTN_COLOR": "选择颜色",
    "COLOR_PICKER_BTN_COLOR_TOOLTIP": "选择颜色",
    "COLOR_PICKER_TEXT_TEXT_BACKGROUND_COLOR": "背景颜色",
    "COLOR_PICKER_BACKGROUND_COLOR_TOOLTIP": "背景颜色",
    "COMMON_STATUS_TEXT_DOWNLOADING_MEDIA_FILES": "正在下载媒体文件",
    "COMMON_STATUS_TEXT_UPLOADING_SAVED_MEDIA": "正在上传保存的媒体",
    "COMMON_STATUS_TEXT_ABORTED": "中止",
    "COMMON_STATUS_TEXT_LOADING": "正在加载",
    "COMMON_STATUS_TEXT_RENDERING": "渲染",
    "CONFIRMATION_DIALOG_BTN_CANCEL": "取消",
    "CONFIRMATION_DIALOG_BTN_CANCEL_TOOLTIP": "取消",
    "CUSTOMIZATION_FORM_CAPTION_USER_AVATAR": "用户头像",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_UPLOAD_AVATAR": "您可以上传默认用户头像文件",
    "CUSTOMIZATION_FORM_FIELD_NAME_AVATAR": "头像",
    "CUSTOMIZATION_FORM_CAPTION_COLOR_THEME": "颜色主题",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_JS_SCRIPT": "自定义 JS 脚本",
    "CUSTOMIZATION_FORM_CAPTION_CUSTOM_TEXT": "自定义文本",
    "CUSTOMIZATION_FORM_FIELD_DESC_YOU_CAN_CUSTOMIZE_TEXT": "您可以自定义编辑器文本",
    "CUSTOMIZATION_FORM_FIELD_DESC_THIS_SCRIPT_RUNS_ON_LOADING": "此脚本在编辑器加载时运行。可用于集成分析功能等",
    "CUSTOMIZATION_TITLE_EDIT_JS_SCRIPT": "编辑 JS 脚本",
    "CUSTOMIZATION_TITLE_SECOND_ACCENT_COLOR": "第二重色",
    "CUSTOMIZATION_DESC_SELECT_BG_COLOR": "选择主背景的颜色",
    "CUSTOMIZATION_DESC_MAIN_ACCENT_COLOR_FOR_CONTROLS": "控件的主强调色。请在此处选择您的品牌颜色。",
    "CUSTOMIZATION_DESC_ADDITIONAL_COLOR_FOR_CONTROLS": "控件的附加颜色与按钮的主颜色搭配",
    "CUSTOMIZATION_DESC_AUDIO_FILES_STYLE": "时间轴上的音频文件有自己的风格",
    "CUSTOMIZATION_DESC_YOU_CAN_UPLOAD_LOGO": "您可以上传徽标文件",
    "CUSTOMIZATION_DESC_VOICE_OVER_STYLE": "配音文件有自己的风格",
    "CUSTOMIZATION_LABEL_DISPLAYED": "已显示",
    "CUSTOMIZATION_TITLE_CHOOSE_THEME": "选择预定义主题或创建自己的主题。您可以编辑 CSS 文件来定制您的主题。",
    "CUSTOMIZATION_TITLE_BASIC_BG": "基本背景",
    "CUSTOMIZATION_TITLE_PRIMARY_BTNS": "主要按钮、链接",
    "CUSTOMIZATION_TITLE_EDIT_CSS_FOR_THEME": "编辑主题的 CSS 文件",
    "CUSTOMIZATION_TITLE_AUDIO": "音频",
    "CUSTOMIZATION_TITLE_VOICE_OVER": "配音",
    "CUSTOMIZATION_TITLE_EDIT_TEXT_VALUES": "编辑文本值",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES": "编辑文本值",
    "CUSTOMIZATION_BTN_EDIT_TEXT_VALUES_TOOLTIP": "编辑文本值",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION": "格式描述",
    "CUSTOMIZATION_BTN_FORMAT_DESCRIPTION_TOOLTIP": "格式描述",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES": "重置所有更改",
    "CUSTOMIZATION_BTN_RESET_ALL_CHANGES_TOOLTIP": "重置所有更改",
    "CUSTOMIZATION_BTN_CANCEL": "取消",
    "CUSTOMIZATION_BTN_CANCEL_TOOLTIP": "取消",
    "CUSTOMIZATION_BTN_SAVE": "保存",
    "CUSTOMIZATION_BTN_SAVE_TOOLTIP": "保存",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT": "编辑 JS 脚本",
    "CUSTOMIZATION_BTN_EDIT_JS_SCRIPT_TOOLTIP": "编辑 JS 脚本",
    "CUSTOMIZATION_BTN_EDIT_CSS": "编辑 CSS",
    "CUSTOMIZATION_BTN_EDIT_CSS_TOOLTIP": "编辑 CSS",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO": "上传徽标",
    "CUSTOMIZATION_BTN_UPLOAD_LOGO_TOOLTIP": "上传徽标",
    "CUSTOMIZATION_BTN_DELETE_LOGO": "删除徽标",
    "CUSTOMIZATION_BTN_DELETE_LOGO_TOOLTIP": "删除徽标",
    "CUSTOMIZATION_CSS_OPTION_DARK_THEME": "黑暗主题",
    "CUSTOMIZATION_CSS_OPTION_LIGHT_THEME": "灯光主题",
    "CUSTOMIZATION_CSS_OPTION_CUSTOM_THEME": "自定义主题",
    "CUSTOMIZATION_FORM_FIELD_NAME_LOGO": "标识",
    "CUSTOMIZATION_FORM_CAPTION_LOGO": "徽标",
    "CUSTOMIZATION_CAPTION_CUSTOMIZE_EDITOR": "自定义编辑器",
    "CUSTOMIZATION_CAPTION_LOGO_AND_NAME": "徽标和名称",
    "CUSTOMIZATION_CAPTION_COMPANY_NAME": "公司名称",
    "CUSTOMIZATION_CAPTION_EDITOR_NAME": "编辑器名称",
    "CUSTOMIZATION_TEXT_ACCEPTED_FILES": "接受的文件:",
    "CUSTOMIZATION_TEXT_NO_UPLOADED_LOGO": "没有上传徽标",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS_TOOLTIP": "查看设置",
    "DUMMY_HEADER_BTN_VIEW_SETTINGS": "视图设置",
    "DUMMY_HEADER_BTN_FILTERS_TOOLTIP": "过滤器",
    "DUMMY_HEADER_BTN_FILTERS": "过滤器",
    "EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS": "将项目另存为...",
    "EDITOR_MENU_LIST_ITEM_NEW_PROJECT": "新项目",
    "EDITOR_MENU_LIST_ITEM_PUBLISH": "发布",
    "EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR": "自定义编辑器",
    "EDITOR_MENU_MAIN_LOGS": "日誌",
    "EDITOR_MENU_MAIN_SETTINGS": "設定",
    "EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH": "无法发布",
    "EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED": "需要跟踪",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_RENDER_SETTINGS": "渲染設定",
    "EDITOR_MAIN_SETTINGS_MENU_ITEM_BEHAVIOUR_SETTINGS": "行為",
    "EDITOR_MAIN_SETTINGS_BTN_SAVE": "保存",
    "EDITOR_MAIN_SETTINGS_BTN_CANCEL": "取消",
    "FILE_INPUT_BTN": "文件输入",
    "FILE_INPUT_BTN_TOOLTIP": "文件输入",
    "FORM_FIELD_LABEL_VOLUME": "卷",
    "folder_not_empty": "文件夹不是空的。文件可在项目中使用。如果继续使用，文件将无法在相应项目的时间轴上使用。",
    "HEAD_CONTROL_IMPORT_TEXT": "导入",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_MEDIA": "导入媒体",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_IMPORT_FOLDER_WITH_MEDIA": "导入带媒体的文件夹",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_AMAZON_S3_BUCKET": "Amazon S3 Bucket",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_DROPBOX": "Dropbox",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_GOOGLE_DRIVE": "Google Drive",
    "HEAD_CONTROL_IMPORT_MENU_ITEM_URL_INPUT": "URL输入",
    "HEADER_MENU_LIST_ITEM_SAVE_PROJECT_AS": "将项目另存为...",
    "HEADER_MENU_LIST_ITEM_DELETE": "删除",
    "HEADER_MENU_LIST_ITEM_RENAME": "重命名",
    "HEADER_PROFILE_MENU_ITEM_ADD_LICENSE_KEY": "添加许可证密钥",
    "HEADER_PROFILE_MENU_ITEM_LOG_OUT": "注销",
    "HEADER_TEXT_DO_YOU_WANT_TO_LEAVE_EDITOR": "您想离开编辑器吗？",
    "HEADER_BTN_BACK_TO_MY_ACC": "返回我的账户",
    "HEADER_BTN_BACK_TO_MY_ACC_TOOLTIP": "返回我的账户",
    "HEADER_BTN_HELP": "帮助",
    "HEADER_BTN_HELP_TOOLTIP": "帮助",
    "IMAGES_TEXT_NATIVE_IMAGES": "本地图像",
    "IMAGES_TEXT_PEXELS": "Pexels",
    "IMAGES_TEXT_UNSPLASH": "Unsplash",
    "IMAGES_HEADER_CAPTION": "图像",
    "IMAGES_PLACEHOLDER_SEARCH_IN": "搜索",
    "IMAGES_PLACEHOLDER_SELECT_COLLECTION": "选择集合",
    "IMAGES_TEXT_ALL_CATEGORIES": "所有系列",
    "IMAGES_TEXT_NO_RESULTS_FOUND": "未找到结果",
    "IMAGES_TEXT_CANNOT_FIND_RESULTS": "看来我们无法根据您的搜索找到任何结果",
    "IMAGES_TAB_AI_GENERATED": "Ai-生成",
    "IMAGES_TAB_SEARCH": "搜索",
    "IMPORT_FROM_URL_TEXT_IMPORT_MEDIA_FROM_URL": "从 URL 导入媒体",
    "IMPORT_FROM_URL_PLACEHOLDER_CLICK_TO_LINK_FILE": "点击此处链接到文件",
    "IMPORT_FROM_URL_BTN_IMPORT": "导入",
    "IMPORT_FROM_URL_BTN_IMPORT_TOOLTIP": "导入",
    "LAYER_CONTROL_BTN_MOVE_UP": "向上移动",
    "LAYER_CONTROL_BTN_MOVE_UP_TOOLTIP": "向上移动",
    "LAYER_CONTROL_BTN_DRAG_LAYER": "拖动层",
    "LAYER_CONTROL_BTN_DRAG_LAYER_TOOLTIP": "拖动图层",
    "LAYER_CONTROL_BTN_MOVE_DOWN": "向下移动",
    "LAYER_CONTROL_BTN_MOVE_DOWN_TOOLTIP": "向下移动",
    "LAYER_CONTROL_BTN_DELETE": "删除",
    "LAYER_CONTROL_BTN_DELETE_TOOLTIP": "删除",
    "LAYER_CONTROL_BTN_HIDE": "隐藏",
    "LAYER_CONTROL_BTN_HIDE_TOOLTIP": "隐藏",
    "LAYER_CONTROL_BTN_SHOW": "显示",
    "LAYER_CONTROL_BTN_SHOW_TOOLTIP": "显示",
    "LAYER_CONTROL_BTN_MUTE": "静音",
    "LAYER_CONTROL_BTN_MUTE_TOOLTIP": "静音",
    "LAYER_CONTROL_BTN_UNMUTE": "解除静音",
    "LAYER_CONTROL_BTN_UNMUTE_TOOLTIP": "解除静音",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA": "我的媒体",
    "LEFT_TOOLBAR_MENU_BTN_MY_MEDIA_TOOLTIP": "我的媒体",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS": "过渡",
    "LEFT_TOOLBAR_MENU_BTN_TRANSITIONS_TOOLTIP": "过渡",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES": "图像",
    "LEFT_TOOLBAR_MENU_BTN_IMAGES_TOOLTIP": "图像",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO": "音频",
    "LEFT_TOOLBAR_MENU_BTN_AUDIO_TOOLTIP": "音频",
    "LEFT_TOOLBAR_MENU_BTN_TEXT": "文本",
    "LEFT_TOOLBAR_MENU_BTN_TEXT_TOOLTIP": "文本",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN": "设计",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_TOOLTIP": "设计",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS": "过滤器",
    "LEFT_TOOLBAR_MENU_BTN_FILTERS_TOOLTIP": "过滤器",
    "LEFT_TOOLBAR_MENU_BTN_DESIGN_FILTERS": "过滤器",
    "MEDIA_FORM_TITLE_SETTINGS_IMAGE": "图像设置",
    "MEDIA_FORM_CAPTION_POSITION": "位置",
    "MEDIA_FORM_CAPTION_ROTATION": "旋转",
    "MEDIA_FORM_CAPTION_SETTINGS": "设置",
    "MEDIA_FORM_CAPTION_OUTLINE": "大纲",
    "MEDIA_FORM_CAPTION_SHADOW": "阴影",
    "MEDIA_FORM_CAPTION_SIZE": "大小",
    "MEDIA_FORM_SLIDER_LABEL_OPACITY": "不透明度",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS": "过滤器",
    "MEDIA_HEAD_CONTROL_BTN_FILTERS_TOOLTIP": "过滤器",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_ALL": "全部",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VIDEO": "视频",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_IMAGE": "图片",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_AUDIO": "音频",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_FOLDER": "文件夹",
    "MEDIA_HEAD_CONTROL_FILTERS_MENU_ITEM_VOICEOVER": "配音",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS": "视图设置",
    "MEDIA_HEAD_CONTROL_BTN_VIEW_SETTINGS_TOOLTIP": "查看设置",
    "MEDIA_UPLOADER_TEXT_OR": "或",
    "MEDIA_UPLOADER_TEXT_DND_YOUR_MEDIA": "将您的媒体拖放到此处",
    "MEDIA_UPLOADER_TEXT_IMPORT_MEDIA": "导入媒体",
    "MEDIA_PREVIEW_TEXT_IMPORTING": "导入",
    "MEDIA_PREVIEW_TEXT_RENDERING": "渲染",
    "MEDIA_INFO_LIST_ITEM_RESOLUTION": "分辨率",
    "MEDIA_INFO_LIST_ITEM_CODEC": "编解码器",
    "MEDIA_INFO_LIST_ITEM_FRAME_RATE": "帧频",
    "MEDIA_INFO_LIST_ITEM_HZ_STEREO": "赫兹 - 立体声",
    "MEDIA_INFO_LIST_ITEM_DURATION": "持续时间",
    "MEDIA_INFO_LIST_ITEM_TYPE": "类型",
    "MEDIA_INFO_LIST_ITEM_AUDIO": "音频",
    "MEDIA_INFO_LIST_ITEM_SIZE": "大小",
    "MEDIA_INFO_LIST_TASK_STATUS": "任务状态",
    "MEDIA_INFO_LIST_TASK_STARTTIME_CODE_FRAMES": "開始時間碼",
    "MEDIA_LIST_HEADER_TEXT_MEDIA": "媒体",
    "MEDIA_BTN_LOAD_MORE_MEDIA": "加载更多媒体",
    "MEDIA_BTN_LOAD_MORE_MEDIA_TOOLTIP": "加载更多媒体",
    "MEDIA_BTN_LOADING": "正在加载",
    "MEDIA_BTN_LOADING_TOOLTIP": "正在加载",
    "MEDIA_VIDEO_FORM_CAPTION_CHROMA_KEY": "色度键",
    "MEDIA_VIDEO_FORM_CAPTION_POSITION": "位置",
    "MEDIA_VIDEO_FORM_CAPTION_SOUND": "声音",
    "MEDIA_VIDEO_FORM_CAPTION_SIZE": "SIZE",
    "MEDIA_VIDEO_FORM_TITLE_SETTINGS_MEDIA": "媒体设置",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS": "重置所有设置",
    "MEDIA_VIDEO_FORM_BTN_RESET_ALL_SETTINGS_TOOLTIP": "重置所有设置",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SIMILARITY": "相似性",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SMOOTHNESS": "平滑度",
    "MEDIA_VIDEO_FORM_SLIDER_LABEL_SPILL_REDUCTION": "关键色彩溢出减少",
    "MEDIA_VIDEO_FORM_TOOLTIP_PICK_COLOR_FROM_PREVIEW": "从预览中选择颜色",
    "MEDIA_SORT_OPTION_DURATION": "持续时间",
    "MEDIA_SORT_OPTION_DATE": "日期",
    "MEDIA_SORT_OPTION_SIZE": "大小",
    "MEDIA_SORT_OPTION_NAME": "名称",
    "MEDIA_SORT_GROUP_SORT_BY_TOOLTIP": "排序方式",
    "MEDIA_SORT_GROUP_ORDER_TOOLTIP": "顺序",
    "MEDIA_SORT_GROUP_VIEW_TOOLTIP": "查看",
    "MEDIA_SORT_OPTION_ASCENDING": "升序",
    "MEDIA_SORT_OPTION_DESCENDING": "降序",
    "MEDIA_SORT_OPTION_LIST": "列表",
    "MEDIA_SORT_OPTION_LARGE_ICONS": "大图标",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS": "我的录音",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_RECORDINGS_TOOLTIP": "我的录音",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA": "我的媒体",
    "MEDIA_FILE_FILTERS_LIST_ITEM_MY_MEDIA_TOOLTIP": "我的媒体",
    "MEDIA_CONFIRM_DESC_ARE_YOU_SURE_TO_REMOVE_SELECTED": "您确定要删除选中的项目吗？",
    "MEDIA_CONFIRM_TITLE_BROWSER_FOR_FOLDER": "浏览文件夹",
    "MEDIA_CONFIRM_BTN_DELETE": "删除",
    "MEDIA_CANCEL_BTN_DELETE": "取消",
    "MEDIA_CONFIRM_BTN_DELETE_TOOLTIP": "删除",
    "MEDIA_CONFIRM_TITLE_DELETE": "删除",
    "MEDIA_TEXT_LOADING": "加载",
    "MEDIA_BTN_ADD": "添加",
    "MEDIA_BTN_ADD_TOOLTIP": "添加",
    "MEDIA_PROGRESS_TEXT_LOADING": "正在加载",
    "MEDIA_PROGRESS_TEXT_SAVING": "保存",
    "MEDIA_PROGRESS_TEXT_CONVERT": "转换",
    "MEDIA_PROGRESS_TEXT_TRANSFER": "传输",
    "MEDIA_PROGRESS_TEXT_ANALYSIS": "分析",
    "MEDIA_MENU_ITEM_SELECT_FOLDER": "选择文件夹",
    "MEDIA_MENU_ITEM_MEDIA": "媒体",
    "MEDIA_MENU_ITEM_MOVE_TO": "移动到",
    "MEDIA_MENU_ITEM_SETTINGS": "设置",
    "MEDIA_MENU_ITEM_NABLET_HLD": "檢測亮點",
    "MEDIA_MENU_ITEM_DELETE": "删除",
    "MEDIA_MENU_ITEM_BROWSE_MEDIA": "瀏覽媒體",
    "MEDIA_MENU_ITEM_COPY_MEDIA_ADDRESS": "複製媒體位址",
    "MEDIA_MENU_ITEM_INSERT_USING_TIMECODE": "使用開始時間碼加入時間線",
    "MEDIA_MENU_LABEL_MOVE_TO": "移至",
    "MEDIA_CONFIRM_BTN_OK": "确定",
    "MEDIA_CONFIRM_BTN_OK_TOOLTIP": "确定",
    "MOBILE_PLUG_TEXT_HTML_VIDEO_EDITOR": "HTML5 视频编辑器",
    "MOBILE_PLUG_TEXT_TABLETS_MOBILES_ARENT_SUPPORTED": "尚未支持平板电脑和手机 :(",
    "MOBILE_PLUG_TEXT_WORKS_WELL_ON_THE_PC": "视频编辑器在电脑上运行良好",
    "MOBILE_PLUG_LINK_TEXT_GO_BACK_TO_WEB": "返回网站",
    "MODAL_WINDOW_LOGS_TITLE": "要打开/关闭日志，您需要重新启动应用程序",
    "MODAL_WINDOW_BUTTON_RESTART": "重新啟動",
    "NAVIGATION_TAB_EDITOR": "编辑器",
    "NAVIGATION_TAB_EDITOR_TOOLTIP": "编辑器",
    "NAVIGATION_TAB_PROJECTS": "项目",
    "NAVIGATION_TAB_PROJECTS_TOOLTIP": "项目",
    "OFFLINE_MODE_PLUG_TEXT_EDITING_UNAVAILABLE_UNTIL_CONNECT": "在恢复连接前编辑不可用",
    "OFFLINE_MODE_PLUG_TEXT_LAST_SAVED": "最后保存",
    "OFFLINE_MODE_PLUG_TEXT_MIN_AGO": "分钟前",
    "OFFLINE_MODE_PLUG_TEXT_JUST_NOW": "刚才",
    "PROJECTS_BTN_NEW_PROJECTS": "新项目",
    "PROJECTS_BTN_NEW_PROJECTS_TOOLTIP": "新项目",
    "PROJECTS_BTN_NEW_PROJECT": "新项目",
    "PROJECTS_BTN_IMPORT": "导入",
    "PROJECTS_BTN_IMPORT_TOOLTIP": "导入",
    "PROJECTS_IMPORT_CONFIRM_TEXT_YES": "是",
    "PROJECTS_IMPORT_BTN_OK": "确定",
    "PROJECTS_IMPORT_BTN_OK_TOOLTIP": "确定",
    "PROJECTS_IMPORT_TEXT_CANCEL": "取消",
    "PROJECTS_TITLE_TEXT_COPY": "复制",
    "PROJECTS_BTN_SAVE": "保存",
    "PROJECTS_BTN_SAVE_TOOLTIP": "保存",
    "PROJECTS_TITLE_TEXT_RENAME": "重命名",
    "PROJECTS_TITLE_TEXT_DELETE": "删除",
    "PROJECTS_TITLE_TEXT_EXPORT": "导出",
    "PROJECTS_CONFIRM_BTN_DELETE": "删除",
    "PROJECTS_POINT_TIME_MEASUREMENT_HOURS_AGO": "已编辑 %d 小时前",
    "PROJECTS_POINT_TIME_MEASUREMENT_JUST_NOW": "已编辑 刚才",
    "PROJECTS_POINT_TIME_MEASUREMENT_MINUTES_AGO": "已编辑 %d 分钟前",
    "PROJECTS_PROGRESS_TEXT_LOADING": "正在加载",
    "PROJECTS_PROGRESS_TEXT_SEARCHING": "搜索",
    "PROJECTS_IMPORT_CONFIRM_TITLE_DO_YOU_WANNA_STOP_IMPORT": "您想停止导入吗？",
    "PROJECTS_TEXT_NOTHING_FOUND": "未找到",
    "PROJECTS_TEXT_CREATE_A_NEW_PROJECT": "创建一个漂亮的新项目",
    "PROJECTS_TEXT_CAPTION": "项目",
    "PROJECTS_SAVE_INPUT_PLACEHOLDER_PROJECT_NAME": "项目名称",
    "PROJECTS_UPLOAD_TEXT_CLICK_TO_UPLOAD_DND_FILE": "单击此处上传或拖放项目文件",
    "PROJECTS_UPLOAD_TEXT_YOU_CAN_UPLOAD_ONE_FILE": "您一次只能上传一个文件",
    "PROJECTS_PREFIX_TEXT_EDITED": "已编辑",
    "PROJECTS_CONFIRM_TITLE_ARE_YOU_SURE_TO_DELETE": "您确定要删除",
    "PROJECTS_CONFIRM_DESC_PROJECT_WILL_BE_DELETED_IMMEDIATELY": "此项目将立即删除。您无法撤销此操作。",
    "PROJECTS_CONFIRM_TITLE_PROJECT": "项目",
    "PROJECTS_IMPORT_ONLY_ONE_FILE_TO_UPLOAD": "只能上传一个文件",
    "PROJECTS_IMPORT_INVALID_FILE_EXTENSION": "文件扩展名无效。应为 *.edl.zip",
    "PROJECTS_IMPORT_PLACEHOLDER_ENTER_YOUR_VALUE": "输入您的值",
    "PROJECTS_IMPORT_FORM_LABEL_CREATE_FOLDER_IN_MEDIA": "在媒体中为该项目创建文件夹",
    "PROJECTS_IMPORT_TEXT_OPEN_EDITOR": "打开编辑器",
    "PROJECTS_IMPORT_TEXT_TRY_AGAIN": "再试一次",
    "PROJECTS_IMPORT_TITLE_SELECT_FRAME_RATE": "为项目选择帧频",
    "PROJECTS_EXPORT_TITLE": "导出项目",
    "PROJECTS_EXPORT_INPROGRESS": "导出进行中...",
    "PROJECTS_EXPORT_INCLUDE_MEDIA": "包含媒体",
    "PROJECTS_EXPORT_COMPLETE": "项目导出完成",
    "PROJECTS_EXPORT_BTN_BROWSE": "浏览",
    "PROJECTS_EXPORT_BTN_DOWNLOAD": "下载",
    "PROJECTS_EXPORT_BTN_COPY": "复制",
    "PREVIEW_PLACEHOLDER_FILES_FROM_MEDIA_PLAYED_HERE": "媒体中的文件在此播放",
    "PREVIEW_BTN_PREV_TOOLTIP": "上一页",
    "PREVIEW_BTN_PREV": "上一页",
    "PREVIEW_BTN_PLAY_TOOLTIP": "播放",
    "PREVIEW_BTN_PLAY": "播放",
    "PREVIEW_BTN_STOP_TOOLTIP": "停止",
    "PREVIEW_BTN_STOP": "停止",
    "PREVIEW_BTN_NEXT_TOOLTIP": "下一步",
    "PREVIEW_BTN_NEXT": "下一步",
    "PREVIEW_BTN_VOLUME_TOOLTIP": "体积",
    "PREVIEW_BTN_VOLUME": "音量",
    "PREVIEW_BTN_FULL_SCREEN": "全屏",
    "PREVIEW_BTN_FULL_SCREEN_TOOLTIP": "全屏",
    "PREVIEW_BTN_MARK_IN_TOOLTIP": "馬克在",
    "PREVIEW_BTN_MARK_IN": "馬克在",
    "PREVIEW_BTN_MARK_OUT_TOOLTIP": "標出",
    "PREVIEW_BTN_MARK_OUT": "標出",
    "PREVIEW_HEADER_TITLE_SOURCE": "來源",
    "PREVIEW_HEADER_TITLE_TIMELINE": "時間軸",
    "PREVIEW_MODE_AUTO": "自動",
    "PREVIEW_MODE_SOURCE": "來源",
    "PREVIEW_MODE_TIMELINE": "時間軸",
    "PUBLISH_SETTINGS_OUTPUT_NAME": "产量",
    "PUBLISH_SETTINGS_TITLE": "标题",
    "RENDER_SETTINGS_START_RENDERING_FROM_THE_FIRST_CLIP": "從第一個片段開始渲染",
    "SAVE_SETTINGS_LIST_ITEM_DURATION": "持续时间",
    "SAVE_SETTINGS_LIST_ITEM_CODEC": "编解码器",
    "SAVE_SETTINGS_LIST_ITEM_FRAME_RATE": "帧速率",
    "SAVE_SETTINGS_LIST_ITEM_HZ_STEREO": "赫兹 - 立体声",
    "SAVE_SETTINGS_LIST_ITEM_RESOLUTION": "分辨率",
    "SAVE_SETTINGS_LIST_ITEM_AUDIO": "音频",
    "SAVE_SETTINGS_LIST_ITEM_SIZE_APPROXIMATE": "大小（近似值）",
    "SAVE_SETTINGS_LINK_TO_TRANSCODING_WILL_BE_LOST": "转码文件的链接将丢失",
    "SAVE_SETTINGS_CAPTION_PUBLISH_SETTINGS": "发布设置",
    "SAVE_SETTINGS_CAPTION_VIDEO_RENDER_COMPLETE": "视频渲染完成",
    "SAVE_SETTINGS_INPUT_PLACEHOLDER_RENDER_NAME": "输入名称",
    "SAVE_SETTINGS_BTN_RENDER_START": "开始",
    "SAVE_SETTINGS_BTN_RENDER_START_TOOLTIP": "开始",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC": "返回我的帐户",
    "SAVE_SETTINGS_BTN_BACK_TO_MY_ACC_TOOLTIP": "返回我的账户",
    "SAVE_SETTINGS_BTN_OK": "确定",
    "SAVE_SETTINGS_BTN_OK_TOOLTIP": "确定",
    "SAVE_SETTINGS_BTN_CANCEL": "取消",
    "SAVE_SETTINGS_BTN_CANCEL_TOOLTIP": "取消",
    "SAVE_SETTINGS_BTN_DOWNLOAD": "下载",
    "SAVE_SETTINGS_BTN_DOWNLOAD_TOOLTIP": "下载",
    "SAVE_SETTINGS_BTN_BROWSE": "浏览",
    "SAVE_SETTINGS_BTN_BROWSE_TOOLTIP": "浏览",
    "SAVE_SETTINGS_BTN_COPY": "复制",
    "SAVE_SETTINGS_BTN_COPY_TOOLTIP": "复制",
    "SAVE_SETTINGS_TEXT_TITLE": "标题",
    "SAVE_SETTINGS_TEXT_LOADING": "正在加载",
    "SAVE_SETTINGS_TEXT_VIDEO_SUBMITTED_TO_YOUR_ACCOUNT": "此视频已提交至您的帐户，不久即可观看",
    "SEARCH_DROP_DOWN_MENU_ITEM_ALL": "全部",
    "SEARCH_INPUT_PLACEHOLDER_SEARCH": "搜索",
    "SETTINGS_HEAD_TEXT_DESCRIPTION": "描述",
    "SETTINGS_HEAD_BTN_DELETE": "删除",
    "SETTINGS_HEAD_BTN_DELETE_TOOLTIP": "删除",
    "SETTINGS_HEAD_BTN_CLOSE": "关闭",
    "SETTINGS_HEAD_BTN_CLOSE_TOOLTIP": "关闭",
    "SETTINGS_SIZE_ICON_TEXT_WIDTH": "宽度",
    "SETTINGS_SIZE_ICON_TEXT_HEIGHT": "高度",
    "SETTINGS_SIZE_ICON_TEXT_KEEP_ASPECT_RATIO": "保持宽高比",
    "SETTINGS_BTN_FIT_TO_FRAME": "适合框架",
    "SETTINGS_BTN_FIT_TO_FRAME_TOOLTIP": "适合框架",
    "SETTINGS_BTN_FILL_FRAME": "填充框",
    "SETTINGS_BTN_FILL_FRAME_TOOLTIP": "填充框架",
    "SETTINGS_TEXT_AUTO": "自动",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE": "文本无变换",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_NONE_TOOLTIP": "文本无变换",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE": "文本转换为大写",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_UPPERCASE_TOOLTIP": "文本转换大写",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE": "小写文本转换",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_LOWERCASE_TOOLTIP": "小写文本转换",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE": "文本转换大写",
    "SETTINGS_TEXT_BTN_TEXT_TRANSFORM_CAPITALIZE_TOOLTIP": "文本转换大写",
    "SETTINGS_TEXT_BTN_FONT_FAMILY": "字体家族",
    "SETTINGS_TEXT_BTN_FONT_FAMILY_TOOLTIP": "字体族",
    "SETTINGS_TEXT_BTN_FONT_SIZE": "字体大小 ",
    "SETTINGS_TEXT_BTN_FONT_SIZE_TOOLTIP": "字体大小",
    "SETTINGS_TEXT_BTN_BOLD": "粗体",
    "SETTINGS_TEXT_BTN_BOLD_TOOLTIP": "粗体",
    "SETTINGS_TEXT_BTN_ITALIC": "斜体",
    "SETTINGS_TEXT_BTN_ITALIC_TOOLTIP": "斜体",
    "SETTINGS_TEXT_BTN_UNDERLINE": "下划线",
    "SETTINGS_TEXT_BTN_UNDERLINE_TOOLTIP": "下划线",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT": "左",
    "SETTINGS_TEXT_ALIGN_BTN_LEFT_TOOLTIP": "左",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT": "右",
    "SETTINGS_TEXT_ALIGN_BTN_RIGHT_TOOLTIP": "右",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER": "居中",
    "SETTINGS_TEXT_ALIGN_BTN_CENTER_TOOLTIP": "居中",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY": "对齐",
    "SETTINGS_TEXT_ALIGN_BTN_JUSTIFY_TOOLTIP": "对齐",
    "SETTINGS_TEXT_POSITION_BTN_TOP": "顶部",
    "SETTINGS_TEXT_POSITION_BTN_TOP_TOOLTIP": "顶部",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT": "左上角",
    "SETTINGS_TEXT_POSITION_BTN_TOP_LEFT_TOOLTIP": "左上角",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT": "右上方",
    "SETTINGS_TEXT_POSITION_BTN_TOP_RIGHT_TOOLTIP": "右上角",
    "SETTINGS_TEXT_POSITION_BTN_LEFT": "左",
    "SETTINGS_TEXT_POSITION_BTN_LEFT_TOOLTIP": "左",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER": "中间居中",
    "SETTINGS_TEXT_POSITION_BTN_MIDDLE_CENTER_TOOLTIP": "中间居中",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT": "右",
    "SETTINGS_TEXT_POSITION_BTN_RIGHT_TOOLTIP": "右",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT": "左下角",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_LEFT_TOOLTIP": "左下角",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM": "底部",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_TOOLTIP": "底部",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT": "右下方",
    "SETTINGS_TEXT_POSITION_BTN_BOTTOM_RIGHT_TOOLTIP": "右下方",
    "SETTINGS_ROTATION_TEXT_HORIZONTAL": "水平",
    "SETTINGS_ROTATION_TEXT_VERTICAL": "垂直",
    "SETTINGS_ROTATION_TEXT_ROTATE": "旋转",
    "SETTINGS_PLACEHOLDER_ENTER_TEXT": "输入文本",
    "NABLET_HLD_PROJECT_POSTFIX": "亮點",
    "NABLET_HLD_CAPTION": "檢測亮點",
    "NABLET_HLD_DETECTED_BTN_START": "開始",
    "NABLET_HLD_DETECTED_BTN_CREATE_PROJECT": "創建項目",
    "NABLET_HLD_DETECTED_BTN_CANCEL": "取消",
    "NABLET_HLD_DETECTED_INTERVALS_COUNT": "检测到的间隔数量:",
    "NABLET_HLD_DETECTED_CLOSE_CONFIRMATION": "您確定要終止任務嗎？",
    "NABLET_HLD_DETECTED_BTN_NO": "否",
    "NABLET_HLD_DETECTED_BTN_YES": "是",
    "NB_HLD_STATUS_TEXT_LOADING": "加載中",
    "NB_HLD_STATUS_TEXT_DETECTING": "檢測中",
    "NB_HLD_STATUS_DOWNLOADING_MEDIA_FILES": "正在下載媒體文件",
    "NB_HLD_STATUS_TEXT_ABORTED": "已中止",
    "NABLET_HLD_TARGET_DURATION_SEC": "目標持續時間（秒）",
    "NABLET_HLD_ONNX_MODEL": "Onnx模型",
    "SECTION_PLACEHOLDER_TEXT_SECTION_IN_DEVELOPMENT": "本节正在开发中",
    "TEXT_FORM_CAPTION_SIZE": "大小",
    "TEXT_FORM_CAPTION_OUTLINE": "大纲",
    "TEXT_FORM_CAPTION_FONT": "字体",
    "TEXT_FORM_CAPTION_SHADOW": "阴影",
    "TEXT_FORM_TEXT_TITLE_SETTINGS": "文本设置",
    "TEXT_FORM_TEXT_OFFSET": "偏移",
    "TEXT_FORM_TEXT_BLUR": "模糊",
    "TEXT_HEADER_TEXT_CAPTION_TEXT": "文本",
    "TIMELINE_TEXT_DRAG_N_DROP": "拖放",
    "TIMELINE_TEXT_ADD_LAYER": "+ 添加图层",
    "TIMELINE_TOOLBAR_BTN_UNDO": "撤消",
    "TIMELINE_TOOLBAR_BTN_UNDO_TOOLTIP": "撤销",
    "TIMELINE_TOOLBAR_BTN_REDO": "重做",
    "TIMELINE_TOOLBAR_BTN_REDO_TOOLTIP": "重做",
    "TIMELINE_TOOLBAR_BTN_DELETE": "删除",
    "TIMELINE_TOOLBAR_BTN_DELETE_TOOLTIP": "删除",
    "TIMELINE_TOOLBAR_BTN_COPY": "复制剪辑",
    "TIMELINE_TOOLBAR_BTN_COPY_TOOLTIP": "复制剪辑",
    "TIMELINE_TOOLBAR_BTN_CUT": "剪切片段",
    "TIMELINE_TOOLBAR_BTN_CUT_TOOLTIP": "剪切片段",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER_TOOLTIP": "添加标记",
    "TIMELINE_TOOLBAR_BTN_ADD_MARKER": "添加标记",
    "TIMELINE_TOOLBAR_BTN_PASTE_TOOLTIP": "粘贴",
    "TIMELINE_TOOLBAR_BTN_PASTE": "粘贴",
    "TIMELINE_TOOLBAR_BTN_SETTINGS_TOOLTIP": "设置",
    "TIMELINE_TOOLBAR_BTN_SETTINGS": "设置",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER": "语音播报",
    "TIMELINE_TOOLBAR_BTN_VOICE_OVER_TOOLTIP": "语音播报",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA": "网络摄像头",
    "TIMELINE_TOOLBAR_BTN_WEBCAMERA_TOOLTIP": "网络摄像头",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL": "点击取消",
    "TIMELINE_TOOLBAR_BTN_CLICK_TO_CANCEL_TOOLTIP": "点击取消",
    "TIMELINE_LAYER_TITLE_DRAG_N_DROP": "拖放",
    "TIMELINE_FOOTER_TEXT_DURATION": "持续时间",
    "TIMELINE_FOOTER_TITLE_UNABLE_TO_PUBLISH": "无法发布项目",
    "TIMELINE_FOOTER_TEXT_VIDEO_TRACK_IS_REQUIRED": "需要视频轨迹",
    "TIMELINE_FOOTER_TITLE_TEXT_SAVE": "保存",
    "TIMELINE_FOOTER_BTN_SAVE": "保存",
    "TIMELINE_FOOTER_BTN_SAVE_TOOLTIP": "保存",
    "TIMELINE_FOOTER_BTN_PUBLISH": "发布",
    "TIMELINE_FOOTER_BTN_PUBLISH_TOOLTIP": "发布",
    "TIMELINE_SLIDER_POINTER": "滑块指针",
    "TIMELINE_SLIDER_POINTER_TOOLTIP": "滑块指针",
    "TRANSITION_FORM_TITLE_SETTINGS_TRANSITION": "过渡设置",
    "TRANSITION_FORM_SWITCH_TEXT_APPLY_TO_ALL_TRANSITIONS": "应用于时间轴上的所有转场",
    "TRANSITION_FORM_LABEL_TRANSITION_DURATION": "过渡持续时间:",
    "TRANSITION_LIST_CAPTION_TRANSITION": "过渡",
    "used_in_projects": "媒体文件未被删除，因为它正在一个或多个项目中使用。如果您继续删除，该文件将无法在相应项目的时间轴上使用，并从您的账户中删除。",
    "VOICE_OVER_TITLE_LAST_RECORDING": "最后录音",
    "VOICE_OVER_BTN_RETAKE": "重拍",
    "VOICE_OVER_BTN_RETAKE_TOOLTIP": "重拍",
    "VOICE_OVER_BTN_DELETE_FILE": "删除文件",
    "VOICE_OVER_BTN_DELETE_FILE_TOOLTIP": "删除文件",
    "VOICE_OVER_BTN_START_RECORDING": "开始录音",
    "VOICE_OVER_BTN_START_RECORDING_TOOLTIP": "开始录音",
    "VOICE_OVER_BTN_CONNECTION": "连接...",
    "VOICE_OVER_BTN_CONNECTION_TOOLTIP": "连接...",
    "VOICE_OVER_TEXT_VOICE_OVER_BTN_FILE_WILL_BE_DELETED_FROM_MEDIA_AND_TIMELINE": "文件将从时间轴和媒体中删除",
    "VOICE_OVER_TEXT_ARE_YOU_SURE": "你确定吗？",
    "VOICE_OVER_TEXT_VOICE_NARRATION": "语音旁白",
    "VOICE_OVER_TEXT_MIC": "麦克风",
    "VOICE_OVER_LABEL_SELECT_MIC": "选择麦克风",
    "VOICE_OVER_MENU_ITEM_NO_AUDIO": "无音频",
    "VOICE_OVER_TEXT_MUTE_TIMELINE": "录音时将时间线静音",
    "VOICE_OVER_TEXT_REWIND_SLIDER_AT_START_OF_RECORD": "录制后在起始位置倒带滑块",
    "VOICE_OVER_TEXT_COUNTDOWN": "倒计时",
    "VOICE_OVER_BTN_TEST_MIC": "测试麦克风",
    "VOICE_OVER_BTN_TEST_MIC_TOOLTIP": "测试麦克风",
    "VOICE_OVER_BTN_RECORDING": "录音...",
    "VOICE_OVER_BTN_RECORDING_TOOLTIP": "录音...",
    "VOICE_OVER_BTN_PLAYING": "正在播放...",
    "VOICE_OVER_BTN_PLAYING_TOOLTIP": "正在播放...",
    "VOICE_OVER_BTN_YES": "是",
    "VOICE_OVER_BTN_YES_TOOLTIP": "是",
    "VOICE_OVER_BTN_NO": "否",
    "VOICE_OVER_BTN_NO_TOOLTIP": "否",
    "VOICE_OVER_BTN_STOP": "停止",
    "VOICE_OVER_BTN_STOP_TOOLTIP": "停止",
    "VOICE_OVER_BTN_DELETE": "删除",
    "VOICE_OVER_BTN_DELETE_TOOLTIP": "删除",
    "VOICE_OVER_VOLUME_TEXT_AUTO_ADJUSTING": "自动调整",
    "VOICE_OVER_VOLUME_TEXT_OTHER_SETTINGS": "其他設定",
    "WEBCAM_TEXT_RECORD_VIDEO": "录制视频",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM_TOOLTIP": "减少缩放",
    "ZOOM_SLIDER_BTN_DECREASE_ZOOM": "减少缩放",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM_TOOLTIP": "增加缩放",
    "ZOOM_SLIDER_BTN_INCREASE_ZOOM": "增加缩放",
    "SETTINGS_HOTKEYS": "热键设置",
    "PRESET": "预设",
    "DEFAULT": "默认",
    "ADD_NEW": "添加新内容",
    "SEARCH_COMMAND": "搜索命令",
    "COMMAND": "命令",
    "SHORTCUTS": "捷径",
    "ALL": "全部",
    "TIMELINE": "时间轴",
    "TOOLS": "工具",
    "PREVIEW": "预览",
    "KEYBOARD_MAPPING_PRESET": "键盘映射预设",
    "ENTER_PRESET_NAME": "输入预设名称",
    "THIS_PRESET_WILL_BE_DELETED": "此预设将被立即删除。您无法撤销此操作。",
    "RESET_ALL_TO_DEFAULTS": "全部重置为默认值",
    "HOTKEY_SETTINGS_UNDO": "撤销",
    "HOTKEY_SETTINGS_REDO": "重做",
    "HOTKEY_SETTINGS_ZOOM_IN": "放大",
    "HOTKEY_SETTINGS_ZOOM_OUT": "缩小",
    "HOTKEY_SETTINGS_PREV_FRAME": "上一帧",
    "HOTKEY_SETTINGS_NEXT_FRAME": "下一帧",
    "HOTKEY_SETTINGS_ADD_TO_TIMELINE": "添加到时间轴",
    "HOTKEY_SETTINGS_INCREASE_CLIP_VOLUME": "增加剪辑音量",
    "HOTKEY_SETTINGS_DECREASE_CLIP_VOLUME": "降低剪辑音量",
    "HOTKEY_SETTINGS_RIPPLE_DELETE": "波纹删除",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_PREV": "波纹修剪前一个",
    "HOTKEY_SETTINGS_RIPPLE_TRIM_NEXT": "下一个波纹修剪",
    "HOTKEY_SETTINGS_SELECT_CLIPS_AT_CURRENT_POSITION": "在当前位置选择剪辑",
    "HOTKEY_SETTINGS_CUT_CLIPS": "剪切片段",
    "HOTKEY_SETTINGS_PLAY_PAUSE": "播放/暂停",
    "HOTKEY_SETTINGS_DELETE": "删除",
    "HOTKEY_SETTINGS_COPY": "复制",
    "HOTKEY_SETTINGS_PASTE": "粘贴",
    "HOTKEY_SETTINGS_PLAY": "游戏",
    "HOTKEY_SETTINGS_PAUSE": "暂停",
    "HOTKEY_SETTINGS_PLAY_TIMELINE_SEGMENT": "播放高亮顯示的間隔",
    "HOTKEY_SETTINGS_CLEAR_IN_OUT_MARK_POINTS": "清楚的進出點",
    "HOTKEY_SETTINGS_MARK_IN": "標示輸入點",
    "HOTKEY_SETTINGS_MARK_OUT": "標出點",
    "HOTKEY_SETTINGS_SEEK_TO_IN_POINT": "尋求在點",
    "HOTKEY_SETTINGS_SEEK_TO_OUT_POINT": "尋找出點",
    "CLIP_SETTINGS": "剪輯設定",
    "MESSAGE_BOX_REMOVE_EXISTING": "文件不相容。請刪除現有的",
    "MARKS_CONTEXT_MENU_MARK_IN": "標記",
    "MARKS_CONTEXT_MENU_MARK_OUT": "標記出",
    "MARKS_CONTEXT_MENU_CLEAR": "清除入點/出點",
    "MEDIA_ERROR_build_thumbnails_error": "建立縮圖錯誤",
    "MEDIA_ERROR_unsupported_media": "不支援的媒體",
    "MEDIA_ERROR_exceeded_file_size": "超出檔案大小",
    "MEDIA_ERROR_exceeded_per_user_size": "超出每個使用者大小",
    "MEDIA_ERROR_low_disk_space": "磁碟空間不足",
    "MEDIA_ERROR_internal_server_error": "內部伺服器錯誤",
    "MEDIA_ERROR_network_error": "網路錯誤",
    "MEDIA_ERROR_not_found": "找不到媒體檔案",
  },
}
