import React, { FC, useContext } from 'react'
import { TranslationContext } from 'contexts/TranslationContext'
import Button from 'components/base/Button'
import { showLogActions } from 'actions/mainView'
import { useAction } from 'hooks'
import { setLogsEnabled } from 'actions/app'
import { Box } from '@material-ui/core'
import { ReactComponent as CloseIcon } from '~/assets/settings/navigation_close.svg'
import './LogActions.scss'

interface IProps {
  visible: boolean
}

export const LogActionsComponent:FC<IProps> = ({ visible }) => {
  const { t } = useContext(TranslationContext)
  const onCloseLogActions = useAction(showLogActions, false)
  const onSetLogsEnabled = useAction(setLogsEnabled)

  const onClose = () => onCloseLogActions()
  const restart = () => onSetLogsEnabled()

  if (!visible) return null
  return (
    <Box className="log-actions" height="100%">
      <div className="log-actions__close" onClick={onClose}>
        <CloseIcon />
      </div>
      <div className="log-actions__body">
        <div className="log-actions__text h1">{t('MODAL_WINDOW_LOGS_TITLE')}</div>
      </div>
      <Box display="flex" justifyContent="center" className="log-actions__footer" gridGap="16px">
        <Button
          type="submit"
          className="log-actions__cancel btn--border"
          primary
          onClick={onClose}
          autoFocus
        >
          {t('EDITOR_MAIN_SETTINGS_BTN_CANCEL')}
        </Button>
        <Button
          type="submit"
          className="log-actions__restart"
          primary
          onClick={e => {
            restart()
            onClose()
          }}
          autoFocus
        >
          {t('MODAL_WINDOW_BUTTON_RESTART')}
        </Button>
      </Box>
    </Box>
  )
}
