/* eslint-disable no-alert */
import cs from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import { Box } from '@material-ui/core'
import { showCustomizeView, showEditorMainSettings, showLogActions } from 'actions/mainView'
import { ProjectSaveAsDialog } from 'components/Projects/components/ProjectSaveAsDialog/ProjectSaveAsDialog'
import { DropdownMenu } from 'components/base/DropdownMenu/DropdownMenu'
import MessageBox from 'components/base/MessageBox'
import Switch from 'components/base/Switch'
import { TranslationContext } from 'contexts/TranslationContext'
import { useAction } from 'hooks'
import { useEditorActions } from 'hooks/useEditorActions'
import React from 'react'
import { useSelector } from 'react-redux'
import { userProfileSelector } from 'selectors/user-profile-selector'
import { ReactComponent as BurgerIcon } from '~/assets/icons/burger.svg'
import * as AuthToken from '../../cookieServices/AuthToken'
import './EditorMenu.scss'
import { NW_JS_APP_PROFILES } from '~/enums'
import FolderIcon from '~/assets/icons/folder-open.svg'
import { selectLogPath, selectLogsEnabled } from '~/selectors/app'


export const EditorMenu = () => {
  const { onClickNewProject, saveAsDialog, activeProjectName, onPublish,
    isRenderingSupported } = useEditorActions()
  const customizeEditor = useAction(showCustomizeView, 'theme')
  const customizeHotkeys = useAction(showCustomizeView, 'hotkeys')
  const onEditorSettings = useAction(showEditorMainSettings, true)
  const onShowLogActions = useAction(showLogActions)
  const [ errorModal, setErrorModal ] = React.useState(false)
  const { apiKey } = useSelector(userProfileSelector)
  const logPath = useSelector(selectLogPath)
  const logEnabled = useSelector(selectLogsEnabled)
  const id = AuthToken.get()
  const showCustomization = id === apiKey

  const { t } = React.useContext(TranslationContext)

  const projectMenuItems = React.useMemo(() => {
    const items = [
      {
        text: t('EDITOR_MENU_LIST_ITEM_NEW_PROJECT'),
        onClick: onClickNewProject,
      },
      {
        text: t('EDITOR_MENU_LIST_ITEM_SAVE_PROJECT_AS'),
        onClick: saveAsDialog.open,
      }]
    if (!NW_JS_APP_PROFILES.includes(__APP_PROFILE__)) { // TODO: http://18.184.210.86/issues/4295
      items.push({
        text: t('EDITOR_MENU_LIST_ITEM_PUBLISH'),
        onClick: () => {
          if (isRenderingSupported) {
            onPublish()
          } else {
            setErrorModal(true)
          }
        },
      })
    }
    if (showCustomization) {
      items.push(
        {
          divider: true,
        },
        {
          text: t('SETTINGS_HOTKEYS'),
          onClick: customizeHotkeys,
        }
      )
      if (__APP_PROFILE__ !== 'itochu') {
        items.push({
          text: t('EDITOR_MENU_LIST_ITEM_CUSTOMIZE_EDITOR'),
          onClick: customizeEditor,
        })
      }
    }
    items.push({
      text: t('EDITOR_MENU_MAIN_SETTINGS'),
      onClick: onEditorSettings,
    })
    if (NW_JS_APP_PROFILES.includes(__APP_PROFILE__)) {
      items.push(
        {
          divider: true,
        },
        {
          text: (
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center" gridGap="8px">
              <Box display="flex" justifyContent="center" alignItems="center" gridGap="8px">
                <Switch
                  id={t('EDITOR_MENU_MAIN_LOGS')}
                  onChange={_ => onShowLogActions(true)}
                  checked={logEnabled}
                />
                {t('EDITOR_MENU_MAIN_LOGS')}
              </Box>
              <img src={FolderIcon} width={26} height={18} alt="folder" onClick={() => nw?.Shell?.openExternal(logPath)} />
            </Box>
          ),
          // onClick: customizeEditor,
        }
      )
    }
    return items
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ showCustomization, isRenderingSupported ])

  return (
    <>
      <DropdownMenu
        className={cs('editor_menu')}
        hideChevron
        emitFromContent
        menuItems={projectMenuItems}
      >
        <Tooltip title="Editor Menu">
          <BurgerIcon />
        </Tooltip>
      </DropdownMenu>

      <ProjectSaveAsDialog modalDialog={saveAsDialog} projectName={activeProjectName} />
      {errorModal && (
        <MessageBox
          title={t('EDITOR_MENU_TITLE_UNABLE_TO_PUBLISH')}
          message={t('EDITOR_MENU_TEXT_VIDEO_TRACK_IS_REQUIRED')}
          onClose={() => setErrorModal(false)}
        />
      )}
    </>
  )
}
